import { DateRangePresetOption } from '@/components/dateRangePicker'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { ProductDimension } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCollection } from '@/hooks/useFirestoreData'
import { useActionTracker } from '@/hooks/useMixpanel'
import { query } from 'firebase/firestore'
import { ShoppingCart, UserRoundSearch } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AffinityProductsChart } from './components/affinityProductsChart'
import { AffinityProductsTable } from './components/affinityProductsTable'
import { ProductFilter } from './components/productFilter'
import { useAffinityProducts } from './hooks/useAffinityProducs'
import { ProductAffinityType } from './types'
import { generateCustomerAnalysisPath } from './utils/customerAnalysis'
import { generateOrderAnalysisPath } from './utils/orderAnalysis'

export const ProductAnalysis = () => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()

  const { account, shop } = useAccount()
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: dimensionOptions } = useCollection(query(dimensionOptionsRef))

  const productOptions = dimensionOptions?.find((d) => d.dimension === 'product')?.options
  const productTypeOptions = dimensionOptions?.find((d) => d.dimension === 'product_type')?.options

  const dateRangePresetOption = DateRangePresetOption.last365Days

  const [dimension, setDimension] = useState<ProductDimension>(ProductDimension.product_name)
  const [value, setValue] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('') // default empty
  const currentDate = useCurrentDate(shop.timezone)
  const [endDate, setEndDate] = useState<string>(currentDate.format('YYYY-MM-DD'))

  useEffect(() => {
    if (productOptions && productOptions.length > 0) {
      setValue(productOptions[0])
    }
  }, [productOptions])

  const isInputValid = useMemo(() => {
    if (!value) return false
    if (dimension === ProductDimension.product_id) {
      return /^\d{13}$/.test(value)
    }
    return true
  }, [dimension, value])

  const { productsSameOrder, productsPreviousOrder, productsNextOrder, loading } = useAffinityProducts({
    dimension,
    value,
    startDate,
    endDate,
    limit: 100,
    isInputValid,
  })

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)
  const [selectedProductAffinityType, setSelectedProductAffinityType] = useState<ProductAffinityType>(ProductAffinityType.sameOrder)

  const tableDisplayAffinityProducts = useMemo(() => {
    switch (selectedProductAffinityType) {
      case ProductAffinityType.sameOrder:
        return productsSameOrder
      case ProductAffinityType.previousOrder:
        return productsPreviousOrder
      case ProductAffinityType.nextOrder:
        return productsNextOrder
      default:
        return []
    }
  }, [selectedProductAffinityType, productsSameOrder, productsPreviousOrder, productsNextOrder])

  return (
    <>
      <PageHeader title={t('features.productAnalysis.title')} />

      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center justify-between'>
            <ProductFilter
              dimension={dimension}
              value={value}
              dateRangePresetOption={dateRangePresetOption}
              setDimension={setDimension}
              setValue={setValue}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              productOptions={productOptions}
              productTypeOptions={productTypeOptions}
            />

            <div className='flex items-center gap-2'>
              <TooltipIconButton
                icon={UserRoundSearch}
                tooltipContent={t('features.productAnalysis.viewCustomerAnalysis')}
                variant='outline'
                onClick={() => {
                  const path = generateCustomerAnalysisPath(dimension, value, startDate, endDate)
                  window.open(path, '_blank')
                  dispatch('ClickProductViewCustomer', { name: `${dimension}-${value}` })
                }}
              />
              <TooltipIconButton
                icon={ShoppingCart}
                tooltipContent={t('features.productAnalysis.viewOrderAnalysis')}
                variant='outline'
                onClick={() => {
                  const path = generateOrderAnalysisPath(dimension, value, startDate, endDate)
                  window.open(path, '_blank')
                  dispatch('ClickProductViewOrder', { name: `${dimension}-${value}` })
                }}
              />
            </div>
          </div>

          <div className='flex flex-col lg:flex-row items-center gap-4 w-full'>
            <AffinityProductsChart
              title={t(`features.productAnalysis.title_sameOrder`)}
              values={productsSameOrder}
              loading={loading}
              type={ProductAffinityType.sameOrder}
              setType={setSelectedProductAffinityType}
            />
            <AffinityProductsChart
              title={t(`features.productAnalysis.title_previousOrder`)}
              values={productsPreviousOrder}
              loading={loading}
              type={ProductAffinityType.previousOrder}
              setType={setSelectedProductAffinityType}
            />
            <AffinityProductsChart
              title={t(`features.productAnalysis.title_nextOrder`)}
              values={productsNextOrder}
              loading={loading}
              type={ProductAffinityType.nextOrder}
              setType={setSelectedProductAffinityType}
            />
          </div>

          <AffinityProductsTable
            type={selectedProductAffinityType}
            setType={setSelectedProductAffinityType}
            dimension={dimension}
            value={value}
            startDate={startDate}
            endDate={endDate}
            values={tableDisplayAffinityProducts}
            loading={loading}
          />
        </div>
      </PageContentWrapper>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
