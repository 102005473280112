import { OrderAnalysisService } from '@/gen/proto/order_analysis/order_analysis_pb'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  querySetString: string
  fileName: string
  page: string
}

export const useOrdersDownload = ({ querySetString, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()
  const authUser = useAuthUser()
  const orderAnalysisService = useGrpcClient(OrderAnalysisService)

  const [loading, setLoading] = useState(false)

  const headers = useMemo(
    () => [
      t('features.orders.id'),
      t('features.orders.name'),
      t('features.orders.amount'),
      t('features.orders.datetime'),
      t('features.orders.referrer'),
      t('features.orders.channel'),
      t('features.orders.coupon'),
      t('features.orders.utmSource'),
      t('features.orders.utmMedium'),
      t('features.orders.utmCampaign'),
      t('features.orders.products'),
      t('features.orders.tags'),
    ],
    [t]
  )

  const fetchOrders = useCallback(async () => {
    const token = await getIdToken(authUser!)
    const resp = await orderAnalysisService.getOrders({ querySet: querySetString }, { headers: { Authorization: `Bearer ${token}` } })
    return resp.orders.map((o) => ({
      id: o.id,
      name: o.name,
      amount: Number(o.amount),
      orderAt: o.orderAt,
      referrer: o.referrer,
      channel: o.channel,
      coupon: o.coupon,
      utmSource: o.utmSource,
      utmMedium: o.utmMedium,
      utmCampaign: o.utmCampaign,
      products: o.products,
      tags: o.tags,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true)
      const orders = await fetchOrders()

      // Create CSV content
      const csvContent = [
        headers.join(','), // Add headers
        ...orders.map((order) =>
          [
            order.id,
            order.name,
            order.amount,
            order.orderAt,
            order.referrer,
            order.channel,
            order.coupon,
            order.utmSource,
            order.utmMedium,
            order.utmCampaign,
            order.products.join(' / '),
            order.tags.join(' / '),
          ].join(',')
        ),
      ].join('\n')

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch('DownloadCSV', { name: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return {
    handleDownload,
    loading,
  }
}
