import { DialogWrapper } from '@/components/dialogWrapper'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Code, ConnectError } from '@connectrpc/connect'
import Picker from '@emoji-mart/react'
import { FormControl, Popover } from '@mui/material'
import { convertFormToQuerySet } from '@/features/customerAnalysis/querySetForm/schema/converter'
import { QuerySetFormState } from '@/features/customerAnalysis/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  querySetFormMethods: UseFormReturn<QuerySetFormState>
}

type CustomerSegmentFormHeader = {
  name: string
  description: string
}

export const CreateDialog: FC<Props> = ({ open, handleClose, querySetFormMethods }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const defaultEmojiList = ['😀', '🥰', '😎', '👋', '🐶', '💐', '🔥', '🥑', '🍔', '🏋️‍♀️', '🚗', '🚀', '⏰', '🎁', '🎉']
  const defaultEmoji = defaultEmojiList[Math.floor(Math.random() * defaultEmojiList.length)]

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(defaultEmoji)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<CustomerSegmentFormHeader>({
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const createSegment = async (header: CustomerSegmentFormHeader) => {
    const ok = await querySetFormMethods.trigger()
    if (!ok) return
    const querySetFormValues = querySetFormMethods.getValues()
    const querySet = convertFormToQuerySet(querySetFormValues)
    const querySetString = JSON.stringify(querySet)

    try {
      const token = await getIdToken(authUser!)
      const payload = {
        name: `${emojiIcon} ${header.name}`, // icon + name
        description: header.description,
        querySet: querySetString,
      }
      await customerSegmentService.create(payload, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.createDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.customerSegments.createDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.customerSegments.createDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.createDialog.title')}
      mainContent={
        <div className='flex flex-col gap-3'>
          <div className='flex items-center gap-2'>
            <Button ref={emojiAnchorRef} variant='outline' onClick={() => setOpenEmojiPicker(true)}>
              {emojiIcon}
            </Button>
            <Popover
              open={openEmojiPicker}
              anchorEl={emojiAnchorRef.current}
              onClose={() => setOpenEmojiPicker(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker
                locale={i18n.language}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onEmojiSelect={(emoji: any) => {
                  setEmojiIcon(emoji.native)
                  setOpenEmojiPicker(false)
                }}
              />
            </Popover>
            <Controller
              control={control}
              name='name'
              rules={{
                validate: (v) => {
                  if (!v) return t('features.customerSegments.createDialog.messageValidationNameMin')
                  if (v.length > 64) return t('features.customerSegments.createDialog.messageValidationNameMax')
                  return
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl className='w-full'>
                    <Input {...field} placeholder={t('features.customerSegments.createDialog.fieldName')} />
                    {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                  </FormControl>
                )
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name='description'
              rules={{
                validate: (v) => {
                  if (v.length > 128) return t('features.customerSegments.createDialog.messageValidationDescriptionMax')
                  return
                },
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl className='w-full'>
                    <Textarea {...field} placeholder={t('features.customerSegments.createDialog.fieldDescription')} />
                    {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                  </FormControl>
                )
              }}
            />
          </div>
        </div>
      }
      onSubmit={handleSubmit(createSegment)}
      loading={isSubmitting}
      cancelButtonText={t('features.customerSegments.createDialog.cancel')}
      submitButtonText={t('features.customerSegments.createDialog.submit')}
    />
  )
}
