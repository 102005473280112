import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { cn } from '@/lib/utils'
import { FC, ReactNode, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getDefaultValues } from '../defaultValues'
import { QueryItemFormState, QuerySetFormState } from '../schema/querySetFormSchema'
import { ChannelQuery } from './channelQuery'
import { CountryQuery } from './countryQuery'
import { CouponQuery } from './couponQuery'
import { FrequencyQuery } from './frequencyQuery'
import { OrderDateQuery } from './orderDateQuery'
import { OrderTagQuery } from './orderTagQuery'
import { ProductNameQuery } from './productNameQuery'
import { ProductTagQuery } from './productTagQuery'
import { ProductTypeQuery } from './productTypeQuery'
import { ProductVendorQuery } from './productVendorQuery'
import { ReferrerQuery } from './referrerQuery'
import { QueryItemProps } from './type'
import { UtmCampaignQuery } from './utmCampaignQuery'
import { UtmMediumQuery } from './utmMediumQuery'
import { UtmSourceQuery } from './utmSourceQuery'

export const QueryItem: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const { defaultQueryItemByDimension } = getDefaultValues(currentDate)
  const { watch, control, setValue } = useFormContext<QuerySetFormState>()
  const dimension = watch(`${queryItemPath}.dimension`)
  const IsOrderDateQuery = queryItemPath === 'query_items.0'

  const resetOperatorAndValue = (_dimension: string) => {
    const dimension = _dimension as QueryItemFormState['dimension']
    const queryItem = defaultQueryItemByDimension[dimension]
    if (!queryItem) {
      throw new Error(`invalid dimension: ${dimension}`)
    }
    setValue(`${queryItemPath}.operator`, queryItem.operator)
    setValue(`${queryItemPath}.value`, queryItem.value)
  }

  const targetQueryItemForm = useMemo((): ReactNode => {
    switch (dimension) {
      case 'order_date':
        return <OrderDateQuery queryItemPath={queryItemPath} />
      case 'product_name':
        return <ProductNameQuery queryItemPath={queryItemPath} />
      case 'product_type':
        return <ProductTypeQuery queryItemPath={queryItemPath} />
      case 'product_tag':
        return <ProductTagQuery queryItemPath={queryItemPath} />
      case 'product_vendor':
        return <ProductVendorQuery queryItemPath={queryItemPath} />
      case 'order_tag':
        return <OrderTagQuery queryItemPath={queryItemPath} />
      case 'coupon':
        return <CouponQuery queryItemPath={queryItemPath} />
      case 'channel':
        return <ChannelQuery queryItemPath={queryItemPath} />
      case 'country':
        return <CountryQuery queryItemPath={queryItemPath} />
      case 'referrer':
        return <ReferrerQuery queryItemPath={queryItemPath} />
      case 'utm_source':
        return <UtmSourceQuery queryItemPath={queryItemPath} />
      case 'utm_medium':
        return <UtmMediumQuery queryItemPath={queryItemPath} />
      case 'utm_campaign':
        return <UtmCampaignQuery queryItemPath={queryItemPath} />
      case 'frequency':
        return <FrequencyQuery queryItemPath={queryItemPath} />
      default:
        throw new Error(`invalid dimension: ${dimension}`)
    }
  }, [dimension, queryItemPath])

  return (
    <div className='flex items-start gap-3'>
      <Controller
        control={control}
        name={`${queryItemPath}.dimension`}
        render={({ field, fieldState: { error } }) => {
          return IsOrderDateQuery ? (
            <div className='min-w-[120px]'>
              <Select key='dimension' disabled value={field.value} defaultValue={field.value}>
                <SelectTrigger className={cn('w-full', error && 'border-red-500')}>
                  <SelectValue placeholder={t('features.orderAnalysis.querySet.dimension_order_date')} />
                </SelectTrigger>
                <SelectContent className='bg-background'>
                  <SelectItem value='order_date'>{t('features.orderAnalysis.querySet.dimension_order_date')}</SelectItem>
                </SelectContent>
              </Select>
            </div>
          ) : (
            <div className='min-w-[120px]'>
              <Select
                key='dimension'
                value={field.value}
                onValueChange={(value) => {
                  resetOperatorAndValue(value)
                  field.onChange(value)
                }}
                defaultValue={field.value}
              >
                <SelectTrigger className={cn('w-full', error && 'border-red-500')}>
                  <SelectValue placeholder={t(`features.orderAnalysis.querySet.dimension_${field.value}`)} />
                </SelectTrigger>
                <SelectContent className='bg-background'>
                  {Object.entries({
                    product: ['product_name', 'product_type', 'product_tag', 'product_vendor'],
                    order: ['order_tag', 'coupon', 'channel'],
                    acquisition: ['referrer', 'utm_source', 'utm_medium', 'utm_campaign'],
                    customer: ['country', 'frequency'],
                  }).map(([category, dimensions]) => (
                    <SelectGroup key={category}>
                      <SelectLabel>{t(`features.orderAnalysis.querySet.category_${category}`)}</SelectLabel>
                      {dimensions.map((d) => (
                        <SelectItem key={d} value={d}>
                          {t('features.orderAnalysis.querySet.dimension', { context: d })}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />
      {targetQueryItemForm}
    </div>
  )
}
