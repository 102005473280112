// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/account/account.proto (package account, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/account/account.proto.
 */
export const file_proto_account_account: GenFile = /*@__PURE__*/
  fileDesc("Chtwcm90by9hY2NvdW50L2FjY291bnQucHJvdG8SB2FjY291bnQiPAoSVXBkYXRlRW1haWxSZXF1ZXN0EhQKDGN1cnJlbnRFbWFpbBgBIAEoCRIQCghuZXdFbWFpbBgCIAEoCSIVChNVcGRhdGVFbWFpbFJlc3BvbnNlIikKFVVwZGF0ZUxhbmd1YWdlUmVxdWVzdBIQCghsYW5ndWFnZRgBIAEoCSIYChZVcGRhdGVMYW5ndWFnZVJlc3BvbnNlIjMKGFVwZGF0ZURpc3BsYXlNb2RlUmVxdWVzdBIXCg9kaXNwbGF5RGFya01vZGUYASABKAgiGwoZVXBkYXRlRGlzcGxheU1vZGVSZXNwb25zZTKPAgoOQWNjb3VudFNlcnZpY2USSgoLVXBkYXRlRW1haWwSGy5hY2NvdW50LlVwZGF0ZUVtYWlsUmVxdWVzdBocLmFjY291bnQuVXBkYXRlRW1haWxSZXNwb25zZSIAElMKDlVwZGF0ZUxhbmd1YWdlEh4uYWNjb3VudC5VcGRhdGVMYW5ndWFnZVJlcXVlc3QaHy5hY2NvdW50LlVwZGF0ZUxhbmd1YWdlUmVzcG9uc2UiABJcChFVcGRhdGVEaXNwbGF5TW9kZRIhLmFjY291bnQuVXBkYXRlRGlzcGxheU1vZGVSZXF1ZXN0GiIuYWNjb3VudC5VcGRhdGVEaXNwbGF5TW9kZVJlc3BvbnNlIgBCMFouZWNwb3dlci5pby9hcGktc2VydmVyL3Byb3RvL2FjY291bnQ7YWNjb3VudF9wYmIGcHJvdG8z");

/**
 * UpdateEmail
 *
 * @generated from message account.UpdateEmailRequest
 */
export type UpdateEmailRequest = Message<"account.UpdateEmailRequest"> & {
  /**
   * @generated from field: string currentEmail = 1;
   */
  currentEmail: string;

  /**
   * @generated from field: string newEmail = 2;
   */
  newEmail: string;
};

/**
 * Describes the message account.UpdateEmailRequest.
 * Use `create(UpdateEmailRequestSchema)` to create a new message.
 */
export const UpdateEmailRequestSchema: GenMessage<UpdateEmailRequest> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 0);

/**
 * @generated from message account.UpdateEmailResponse
 */
export type UpdateEmailResponse = Message<"account.UpdateEmailResponse"> & {
};

/**
 * Describes the message account.UpdateEmailResponse.
 * Use `create(UpdateEmailResponseSchema)` to create a new message.
 */
export const UpdateEmailResponseSchema: GenMessage<UpdateEmailResponse> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 1);

/**
 * UpdateLanguage
 *
 * @generated from message account.UpdateLanguageRequest
 */
export type UpdateLanguageRequest = Message<"account.UpdateLanguageRequest"> & {
  /**
   * @generated from field: string language = 1;
   */
  language: string;
};

/**
 * Describes the message account.UpdateLanguageRequest.
 * Use `create(UpdateLanguageRequestSchema)` to create a new message.
 */
export const UpdateLanguageRequestSchema: GenMessage<UpdateLanguageRequest> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 2);

/**
 * @generated from message account.UpdateLanguageResponse
 */
export type UpdateLanguageResponse = Message<"account.UpdateLanguageResponse"> & {
};

/**
 * Describes the message account.UpdateLanguageResponse.
 * Use `create(UpdateLanguageResponseSchema)` to create a new message.
 */
export const UpdateLanguageResponseSchema: GenMessage<UpdateLanguageResponse> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 3);

/**
 * UpdateDisplayMode
 *
 * @generated from message account.UpdateDisplayModeRequest
 */
export type UpdateDisplayModeRequest = Message<"account.UpdateDisplayModeRequest"> & {
  /**
   * @generated from field: bool displayDarkMode = 1;
   */
  displayDarkMode: boolean;
};

/**
 * Describes the message account.UpdateDisplayModeRequest.
 * Use `create(UpdateDisplayModeRequestSchema)` to create a new message.
 */
export const UpdateDisplayModeRequestSchema: GenMessage<UpdateDisplayModeRequest> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 4);

/**
 * @generated from message account.UpdateDisplayModeResponse
 */
export type UpdateDisplayModeResponse = Message<"account.UpdateDisplayModeResponse"> & {
};

/**
 * Describes the message account.UpdateDisplayModeResponse.
 * Use `create(UpdateDisplayModeResponseSchema)` to create a new message.
 */
export const UpdateDisplayModeResponseSchema: GenMessage<UpdateDisplayModeResponse> = /*@__PURE__*/
  messageDesc(file_proto_account_account, 5);

/**
 * @generated from service account.AccountService
 */
export const AccountService: GenService<{
  /**
   * @generated from rpc account.AccountService.UpdateEmail
   */
  updateEmail: {
    methodKind: "unary";
    input: typeof UpdateEmailRequestSchema;
    output: typeof UpdateEmailResponseSchema;
  },
  /**
   * @generated from rpc account.AccountService.UpdateLanguage
   */
  updateLanguage: {
    methodKind: "unary";
    input: typeof UpdateLanguageRequestSchema;
    output: typeof UpdateLanguageResponseSchema;
  },
  /**
   * @generated from rpc account.AccountService.UpdateDisplayMode
   */
  updateDisplayMode: {
    methodKind: "unary";
    input: typeof UpdateDisplayModeRequestSchema;
    output: typeof UpdateDisplayModeResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_account_account, 0);

