import { FC } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { CircleX, PlusSquare } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { defaultQueryItem } from '../defalutValues'
import { FilterItems } from '../filterItem/filterItems'
import { QueryItem } from '../queryItem/queryItem'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { Separator } from '@/components/ui/separator'

type Props = {
  querySubsetPath: `query_subsets.${number}`
}

export const QuerySubset: FC<Props> = ({ querySubsetPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { fields, append, remove } = useFieldArray<QuerySetFormState>({
    control,
    name: `${querySubsetPath}.query_items`,
  })

  return (
    <div className='flex flex-col gap-6'>
      {fields.map((field, queryItemIndex) => (
        <div key={field.id} className='flex flex-col gap-3'>
          <Controller
            control={control}
            name={`${querySubsetPath}.join_operator`}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className='flex items-center' style={{ display: queryItemIndex === 0 ? 'none' : 'flex' }}>
                  <div className='my-4'>
                    <Select defaultValue='and' onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className={cn('w-24', error && 'border-red-500')}>
                        <SelectValue placeholder='and' />
                      </SelectTrigger>
                      <SelectContent className='bg-background'>
                        <SelectItem value='and'>{t('features.customerAnalysis.querySet.joinOperator', { context: 'and' })}</SelectItem>
                        <SelectItem value='or'>{t('features.customerAnalysis.querySet.joinOperator', { context: 'or' })}</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <Separator className='w-[calc(100%-120px)]' />
                </div>
              )
            }}
          />
          <div className='flex items-start gap-2'>
            <QueryItem queryItemPath={`${querySubsetPath}.query_items.${queryItemIndex}`} />
            <Button variant='ghost' className='rounded-lg' disabled={fields.length === 1} onClick={() => remove(queryItemIndex)}>
              <CircleX />
            </Button>
          </div>
          <FilterItems open={true} queryItemPath={`${querySubsetPath}.query_items.${queryItemIndex}`} />
        </div>
      ))}
      <Button
        variant='outline'
        size='sm'
        className='flex items-center gap-2 w-[110px] disabled:opacity-50'
        disabled={fields.length >= 10}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onClick={() => append(defaultQueryItem)}
      >
        <PlusSquare />
        {t('features.customerAnalysis.querySet.addQueryItem')}
      </Button>
    </div>
  )
}
