import { SkeletonTable } from '@/components/skeletonTable'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { Account, AccountRole, ShopBillingStatus } from 'gen/firestore'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { UserRoundX } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'utils/timeUtil'
import { InviteMemberDialog } from './components/inviteMemberDialog'
import { RemoveMemberDialog } from './components/removeMemberDialog'

type Props = {
  account: Account
}

type Member = {
  email: string
  role: string // AccountRole
  status: string // AccountStatus
  createdAt: string
}

type RemoveMemberDialogState = {
  open: boolean
  email: string
}

export const Members: FC<Props> = ({ account }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)
  const { dispatch } = useActionTracker()

  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)

  const [members, setMembers] = useState<Member[]>()
  const [dialogSubmitted, setDialogSubmitted] = useState<number>(0)

  const [inviteMemberDialogOpen, setInviteMemberDialogOpen] = useState<boolean>(false)
  const [removeMemberDialogState, setRemoveMemberDialogState] = useState<RemoveMemberDialogState>({ open: false, email: '' })
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await shopService.getAccounts({}, { headers: { Authorization: `Bearer ${token}` } })
        const members = resp.accounts.map((a) => {
          return {
            email: a.email,
            role: a.role,
            status: a.status,
            createdAt: formatDateTime(dayjs(a.createdAt), i18n.language),
          }
        })
        setMembers(members)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogSubmitted])

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex justify-between items-center'>
            <CardTitle>{t('features.settings.members.title')}</CardTitle>
            <Button
              size='sm'
              variant='outline'
              disabled={account.role !== AccountRole.owner}
              onClick={() => {
                if (shopBillingStatus == ShopBillingStatus.free) {
                  setUpgradeDialogOpen(true)
                  dispatch('OpenUpgradeDialog', { referrer: 'settings_members_invite' })
                  return
                }
                setInviteMemberDialogOpen(true)
              }}
            >
              {t('features.settings.members.invite')}
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {members ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t('features.settings.members.email')}</TableHead>
                  <TableHead>{t('features.settings.members.role')}</TableHead>
                  <TableHead>{t('features.settings.members.status')}</TableHead>
                  <TableHead className='text-right'></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {members &&
                  members.map((row) => (
                    <TableRow key={row.email}>
                      <TableCell className='font-medium'>{row.email}</TableCell>
                      <TableCell>
                        <Badge variant='outline'>{t('features.settings.members.role', { context: row.role })}</Badge>
                      </TableCell>
                      <TableCell>
                        <Badge variant='outline'>{t('features.settings.members.status', { context: row.status })}</Badge>
                      </TableCell>
                      <TableCell className='text-right'>
                        {account.role === AccountRole.owner && row.email !== account.email ? (
                          <TooltipIconButton
                            icon={UserRoundX}
                            tooltipContent={t('features.settings.members.remove')}
                            variant='ghost'
                            onClick={() => setRemoveMemberDialogState({ open: true, email: row.email })}
                          />
                        ) : (
                          <Button variant='ghost' size='icon' disabled={true}>
                            <UserRoundX />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            <SkeletonTable columnCount={4} rowCount={3} />
          )}
        </CardContent>
      </Card>

      {inviteMemberDialogOpen && (
        <InviteMemberDialog
          open={inviteMemberDialogOpen}
          handleClose={() => setInviteMemberDialogOpen(false)}
          onSubmit={() => setDialogSubmitted((count) => count + 1)}
        />
      )}

      {removeMemberDialogState.open && (
        <RemoveMemberDialog
          open={removeMemberDialogState.open}
          handleClose={() => setRemoveMemberDialogState({ open: false, email: '' })}
          onSubmit={() => setDialogSubmitted((count) => count + 1)}
          email={removeMemberDialogState.email}
        />
      )}
      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
