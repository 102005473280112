export enum ProductAffinityType {
  sameOrder = 'sameOrder',
  previousOrder = 'previousOrder',
  nextOrder = 'nextOrder',
}

export type AffinityProduct = {
  name: string
  orderCount: number
  avgDaysBetweenOrders: number
  visible: boolean // for free plan
}
