import { DialogWrapper } from '@/components/dialogWrapper'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  onSubmit: () => void
  email: string
}

export const RemoveMemberDialog: FC<Props> = ({ open, handleClose, onSubmit, email }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const shopService = useGrpcClient(ShopService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await shopService.removeAccount({ email: email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageSuccess'), { severity: 'success' })
      onSubmit()
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: email })
    } finally {
      setLoading(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.members.removeMemberDialog.title')}
      mainContent={<p>{t('features.settings.members.removeMemberDialog.description', { email: email })}</p>}
      onSubmit={handleSubmit}
      loading={loading}
      cancelButtonText={t('features.settings.members.removeMemberDialog.cancel')}
      submitButtonText={t('features.settings.members.removeMemberDialog.submit')}
    />
  )
}
