import { EmptyState } from '@/components/emptyState'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { LoaderCircle } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pie, PieChart } from 'recharts'
import { AffinityProduct, ProductAffinityType } from '../types'
import { useActionTracker } from '@/hooks/useMixpanel'

type Props = {
  title: string
  values: AffinityProduct[] | undefined
  loading: boolean
  type: ProductAffinityType
  setType: (type: ProductAffinityType) => void
}

export const AffinityProductsChart: FC<Props> = ({ title, values, loading, type, setType }) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()

  const topValues = useMemo(() => values?.slice(0, 5) || [], [values])

  const otherValue = useMemo(
    () => ({
      name: 'other',
      orderCount: values?.slice(5).reduce((acc, item) => acc + item.orderCount, 0) || 0,
      avgDaysBetweenOrders: 0,
      visible: true,
    }),
    [values]
  ) satisfies AffinityProduct

  const chartData = useMemo(() => {
    const data = topValues.map((item, i) => ({
      name: item.name,
      count: item.orderCount,
      fill: `var(--color-name${i})`,
    }))
    if (otherValue.orderCount > 0) {
      data.push({ name: t('features.productAnalysis.other'), count: otherValue.orderCount, fill: 'var(--color-other)' })
    }
    return data
  }, [topValues, otherValue, t])

  const chartConfig = useMemo(() => {
    const colors = ['hsl(var(--chart-1))', 'hsl(var(--chart-2))', 'hsl(var(--chart-4))', 'hsl(var(--chart-7))', 'hsl(var(--chart-5))']
    const config = topValues.map((item, index) => ({
      label: item.name || `Name ${index + 1}`,
      color: colors[index],
    }))
    return {
      ...config.reduce((acc, curr, index) => ({ ...acc, [`name${index}`]: curr }), {}),
      other: {
        label: t('features.productAnalysis.other'),
        color: 'hsl(var(--chart-3))',
      },
    }
  }, [topValues, t]) satisfies ChartConfig

  return (
    <Card
      className='flex flex-col w-full cursor-pointer'
      onClick={() => {
        setType(type)
        dispatch('SwitchProductTable', { name: type })
      }}
    >
      <CardHeader className='items-center'>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className='flex-1'>
        {loading ? (
          <div className='flex justify-center items-center h-[190px]'>
            <LoaderCircle className='animate-spin' />
          </div>
        ) : values && values.length === 0 ? (
          <div className='flex justify-center items-center h-[190px]'>
            <EmptyState title={t('features.productAnalysis.noData')} />
          </div>
        ) : (
          <ChartContainer config={chartConfig} className='mx-auto max-h-[190px]'>
            <PieChart>
              <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
              <Pie
                data={chartData}
                dataKey='count'
                nameKey='name'
                label={({ name }) => (name.length > 10 ? `${name.slice(0, 10)}...` : name)}
                innerRadius={40}
                isAnimationActive={false}
              />
            </PieChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}
