import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  useSidebar,
} from '@/components/ui/sidebar'
import { billingStatus } from '@/config/plan'
import { ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { AUTHORIZED_ROUTE } from '@/routing'
import { FileText, FileUser, MessageCircleMore, Package, Settings, ShoppingCart, UserRoundSearch } from 'lucide-react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ChannelTalkClient from 'utils/channelTalk'
import { NavMain } from './components/navMain'
import { NavSecondary } from './components/navSecondary'
import { NavUser } from './components/navUser'
import { UpgradeCard } from './components/upgradeCard'

export const AppSidebar = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const navigate = useNavigate()
  const { state } = useSidebar()

  const data = {
    customer: [
      {
        title: t('layouts.dashboardLayout.customerAnalysis'),
        path: AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS,
        icon: UserRoundSearch,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS),
      },
      {
        title: t('layouts.dashboardLayout.customerSegments'),
        path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS,
        icon: FileUser,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS),
      },
    ],
    order: [
      {
        title: t('layouts.dashboardLayout.orderAnalysis'),
        path: AUTHORIZED_ROUTE.ORDER_ANALYSIS,
        icon: ShoppingCart,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.ORDER_ANALYSIS),
      },
      {
        title: t('layouts.dashboardLayout.orderFilters'),
        path: AUTHORIZED_ROUTE.ORDER_FILTERS,
        icon: FileText,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.ORDER_FILTERS),
      },
    ],
    product: [
      {
        title: t('layouts.dashboardLayout.productAnalysis'),
        path: AUTHORIZED_ROUTE.PRODUCT_ANALYSIS,
        icon: Package,
        isActive: location.pathname.includes(AUTHORIZED_ROUTE.PRODUCT_ANALYSIS),
      },
    ],
    navSecondary: [
      {
        title: t('layouts.dashboardLayout.support'),
        onClick: () => ChannelTalkClient.openChat(),
        icon: MessageCircleMore,
      },
      {
        title: t('layouts.dashboardLayout.settings'),
        onClick: () => navigate(AUTHORIZED_ROUTE.SETTINGS_WORKSPACE),
        icon: Settings,
      },
    ],
  }

  return (
    <Sidebar collapsible='icon' {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton asChild>
              <img
                src={state === 'collapsed' ? '/logo512.png' : '/logo.png'}
                alt='ecpower-logo'
                onClick={() => navigate(AUTHORIZED_ROUTE.HOME)}
                className='cursor-pointer h-auto max-w-[128px]'
              />
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain label={t('layouts.dashboardLayout.customer')} items={data.customer} />
        <NavMain label={t('layouts.dashboardLayout.order')} items={data.order} />
        <NavMain label={t('layouts.dashboardLayout.product')} items={data.product} />
        {state === 'expanded' && shopBillingStatus !== ShopBillingStatus.paid && <UpgradeCard shopBillingStatus={shopBillingStatus} />}
        <NavSecondary items={data.navSecondary} className='mt-auto' />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
