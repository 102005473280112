import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE, OP_NONE } from '../schema/const'
import { OrderCountFilterState, QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { FilterItemProps } from './type'
import { defaultOrderCountFilterByOperator } from '../defalutValues'

export const OrderCountFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t } = useTranslation()
  const { register, control, watch, setValue } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  // When the operator changes, set the value to match the operator
  const resetDefaultOperatorAndValue = (_filterOperator: string) => {
    const orderCountOperator = _filterOperator as OrderCountFilterState['operator']
    const orderCountFilter = defaultOrderCountFilterByOperator[orderCountOperator]

    setValue(`${filterPath}.operator`, orderCountFilter.operator)
    setValue(`${filterPath}.value`, orderCountFilter.value)
  }

  return (
    <div className='flex items-start gap-3'>
      <div className='text-sm py-2'>{t('features.customerAnalysis.querySet.filterType', { context: 'order_count' })}</div>
      <input type='hidden' value='order_count' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[120px]', error && 'border-red-500')}>
              <Select
                value={field.value}
                onValueChange={(value) => {
                  resetDefaultOperatorAndValue(value)
                }}
              >
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerAnalysis.querySet.op_order_count', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_NONE}>{t('features.customerAnalysis.querySet.op_order_count', { context: OP_NONE })}</SelectItem>
                    <SelectItem value={OP_INT_RANGE}>{t('features.customerAnalysis.querySet.op', { context: OP_INT_RANGE })}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              {error && <span className='text-red-500 text-xs mt-1'>{error.message}</span>}
            </div>
          )
        }}
      />

      {operator === OP_INT_RANGE && (
        <IntRangeValue
          minValuePath={`${filterPath}.value.min`}
          maxValuePath={`${filterPath}.value.max`}
          inputAdornmentString={t('features.customerAnalysis.querySet.suffixCount')}
          inputPropsMinValue={1}
          inputPropsMaxValue={99}
        />
      )}
    </div>
  )
}
