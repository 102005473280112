import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { OrderFilter, ShopBillingStatus } from '@/gen/firestore'
import { OrderAnalysisService } from '@/gen/proto/order_analysis/order_analysis_pb'
import { useAccount } from '@/hooks/useAccount'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { useCsvDownload } from '@/hooks/useCsvDownload'
import { useCurrency } from '@/hooks/useCurrency'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useCollection } from '@/hooks/useFirestoreData'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useActionTracker } from '@/hooks/useMixpanel'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from '@/utils/iconUtil'
import { formatDate } from '@/utils/timeUtil'
import { getIdToken } from '@firebase/auth'
import { query } from 'firebase/firestore'
import { Download, LoaderCircle, Plus, ShoppingCart, X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

type OrderFilterMetrics = {
  totalOrderAmount: number
  totalOrderCount: number
  totalAvgOrderValue: number
}

export const Compare: FC = () => {
  const { t, i18n } = useTranslation()
  const { account, shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { executeRequest } = useConcurrentRequest()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const orderAnalysisService = useGrpcClient(OrderAnalysisService)
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const queryParams = new URLSearchParams(location.search)
  const ids = queryParams.get('ids')

  const { orderFiltersRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: orderFilters } = useCollection(query(orderFiltersRef))

  const [selectedOrderFilters, setSelectedOrderFilters] = useState<OrderFilter[]>([])
  const [orderFilterMetrics, setOrderFilterMetrics] = useState<Record<string, OrderFilterMetrics>>({})
  const [comboBoxOpen, setComboBoxOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  // Execute once on page load when orderFilters is available
  useEffect(() => {
    if (orderFilters) {
      setSelectedOrderFilters(orderFilters.filter((filter) => ids?.includes(filter.ref.id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFilters])

  useEffect(() => {
    if (selectedOrderFilters.length === 0) return
    // Update the URL query params without navigating
    const url = new URL(window.location.href)
    url.searchParams.set('ids', selectedOrderFilters.map((filter) => filter.ref.id).join(','))
    window.history.replaceState({}, '', url.toString())
    // Fetch the metrics for the selected segments
    selectedOrderFilters.forEach((filter) => {
      if (!orderFilterMetrics[filter.ref.id]) {
        executeRequest(() => fetchOrderFilterMetrics(filter))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderFilters])

  const addSelectedOrderFilter = (id: string) => {
    if (orderFilters) {
      const filter = orderFilters.find((filter) => filter.ref.id === id)
      if (filter && !selectedOrderFilters.some((s) => s.ref.id === id)) {
        setSelectedOrderFilters([...selectedOrderFilters, filter])
      }
    }
  }

  const removeSelectedOrderFilter = (id: string) => {
    if (selectedOrderFilters.length > 1) {
      setSelectedOrderFilters(selectedOrderFilters.filter((filter) => filter.ref.id !== id))
    }
  }

  const fetchOrderFilterMetrics = async (filter: OrderFilter) => {
    try {
      const token = await getIdToken(authUser!)
      const resp = await orderAnalysisService.getDailyMetrics({ querySet: filter.querySet }, { headers: { Authorization: `Bearer ${token}` } })
      setOrderFilterMetrics((prev) => {
        const totalOrderAmount = resp.dailyMetrics.reduce((acc, curr) => acc + Number(curr.amount), 0)
        const totalOrderCount = resp.dailyMetrics.reduce((acc, curr) => acc + Number(curr.orderCount), 0)
        const totalAvgOrderValue = totalOrderAmount / totalOrderCount
        return {
          ...prev,
          [filter.ref.id]: {
            totalOrderAmount,
            totalOrderCount,
            totalAvgOrderValue,
          },
        }
      })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  const formatCustomerMetricsValue = (key: string, value: number | undefined) => {
    switch (key) {
      case 'totalOrderAmount':
        return value && formatCurrency(value)
      case 'totalOrderCount':
        return value?.toLocaleString()
      case 'totalAvgOrderValue':
        return value && formatCurrency(value)
      default:
        return value?.toFixed(1)
    }
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: selectedOrderFilters.map((filter) => ({
      name: extractTextFromTextWithIcon(filter.name),
      totalOrderAmount: orderFilterMetrics[filter.ref.id]?.totalOrderAmount,
      totalOrderCount: orderFilterMetrics[filter.ref.id]?.totalOrderCount,
      totalAvgOrderValue: orderFilterMetrics[filter.ref.id]?.totalAvgOrderValue,
    })),
    headers: [
      t('features.orderFilters.compare.name'),
      t('features.orderAnalysis.orderAmount'),
      t('features.orderAnalysis.orderCount'),
      t('features.orderAnalysis.avgOrderValue'),
    ],
    fileName: t('features.orderFilters.compare.downloadFileName', {
      date: formatDate(currentDate, i18n.language),
    }),
    page: 'orderFilters_compare',
  })

  return (
    <>
      <PageHeader
        title={t('features.orderFilters.compare.title')}
        origin={{ title: t('features.orderFilters.compare.orderFilters'), path: AUTHORIZED_ROUTE.ORDER_FILTERS }}
      />

      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center justify-between gap-8'>
            <div className='flex flex-wrap items-center gap-2'>
              {selectedOrderFilters.map((segment) => (
                <div key={segment.ref.id} className='flex items-center gap-2 bg-muted rounded-md px-3 py-2'>
                  <span className='text-sm'>{segment.name}</span>
                  <X
                    className='h-4 w-4 cursor-pointer text-muted-foreground hover:text-black'
                    onClick={() => removeSelectedOrderFilter(segment.ref.id)}
                  />
                </div>
              ))}
              <Popover open={comboBoxOpen} onOpenChange={setComboBoxOpen}>
                <PopoverTrigger asChild>
                  <TooltipIconButton
                    icon={Plus}
                    tooltipContent={t('features.orderFilters.compare.addOrderFilter')}
                    variant='ghost'
                    disabled={selectedOrderFilters.length > 20}
                  />
                </PopoverTrigger>
                <PopoverContent className='min-w-[240px] p-0'>
                  <Command>
                    <CommandInput placeholder={t('features.productAnalysis.selectValue')} className='h-9' />
                    <CommandList>
                      <CommandEmpty>{t('features.productAnalysis.noResults')}</CommandEmpty>
                      <CommandGroup>
                        {orderFilters
                          ?.filter((filter) => !selectedOrderFilters.some((s) => s.ref.id === filter.ref.id))
                          .map((filter) => (
                            <CommandItem
                              key={filter.ref.id}
                              value={filter.ref.id}
                              onSelect={(currentValue) => {
                                addSelectedOrderFilter(currentValue)
                                setComboBoxOpen(false)
                              }}
                            >
                              {filter.name}
                            </CommandItem>
                          ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <div>
              <TooltipIconButton
                icon={downloadLoading ? LoaderCircle : Download}
                className={downloadLoading ? 'animate-spin' : ''}
                tooltipContent={t('features.orderFilters.compare.download')}
                variant='ghost'
                disabled={!selectedOrderFilters || selectedOrderFilters.length === 0 || downloadLoading}
                onClick={() => {
                  if (shopBillingStatus === ShopBillingStatus.free) {
                    setUpgradeDialogOpen(true)
                    dispatch('OpenUpgradeDialog', { referrer: 'orderFilters_compare_csv' })
                    return
                  }
                  handleDownload()
                }}
              />
            </div>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('features.orderFilters.compare.name')}</TableHead>
                <TableHead className='text-center'>{t('features.orderAnalysis.orderAmount')}</TableHead>
                <TableHead className='text-center'>{t('features.orderAnalysis.orderCount')}</TableHead>
                <TableHead className='text-center'>{t('features.orderAnalysis.avgOrderValue')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedOrderFilters.map((filter) => (
                <TableRow key={filter.ref.id}>
                  <TableCell>
                    <div className='flex items-center gap-2'>
                      <span className='text-lg'>{extractIconFromTextWithIcon(filter.name)}</span>
                      <span className='text-sm'>{extractTextFromTextWithIcon(filter.name)}</span>
                    </div>
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('totalOrderAmount', orderFilterMetrics[filter.ref.id]?.totalOrderAmount) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('totalOrderCount', orderFilterMetrics[filter.ref.id]?.totalOrderCount) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('totalAvgOrderValue', orderFilterMetrics[filter.ref.id]?.totalAvgOrderValue) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-right'>
                    <TooltipIconButton
                      icon={ShoppingCart}
                      tooltipContent={t('features.orderFilters.compare.viewMore')}
                      variant='ghost'
                      onClick={() => {
                        const to = `${AUTHORIZED_ROUTE.ORDER_ANALYSIS}?${createSearchParams({
                          querySet: encodeURIComponent(filter.querySet),
                        }).toString()}`
                        navigate(to)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PageContentWrapper>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
