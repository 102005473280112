import { Login } from '@/features/auth/login'
import { ResetPassword } from '@/features/auth/resetPassword'
import { Detail as SegmentDetail } from '@/features/customerSegments/detail/detail'
import { AppUninstalledDialog } from 'components/appUninstalledDialog'
import { UpgradeRequiredDialog } from 'components/upgradeRequiredDialog'
import { hasDeveloperCookie, isMaintenanceMode } from 'config/maintenanceMode'
import { isUpgradeRequired } from 'config/plan'
import { MemberSignup } from 'features/auth/memberSignup'
import { Signup } from 'features/auth/signup'
import { DataSyncNotStarted } from 'features/dataSync/dataSyncNotStarted'
import { DataSyncProcessing } from 'features/dataSync/dataSyncProcessing'
import { Home } from 'features/home/home'
import { Maintenance } from 'features/maintenance/maintenance'
import { Settings } from 'features/settings/settings'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier, DatasetEtlStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useInitChannelTalk } from 'hooks/useChannelTalk'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { WithPageViewTracker, trackIdentifyEvent } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { DashboardLayout } from 'layouts/dashboardLayout/dashboardLayout'
import { PublicLayout } from 'layouts/publicLayout/publicLayout'
import { AccountProvider } from 'provider/accountContext'
import { useThemeContext } from 'provider/themeContext'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AUTHORIZED_ROUTE, PUBLIC_ROUTE } from 'routing'
import { CustomerAnalysis } from './features/customerAnalysis/customerAnalysis'
import { Compare as CustomerSegmentCompare } from './features/customerSegments/compare/compare'
import { CustomerSegments } from './features/customerSegments/customerSegments'
import { OrderAnalysis } from './features/orderAnalysis/orderAnalysis'
import { Compare as OrderFilterCompare } from './features/orderFilters/compare/compare'
import { OrderFilters } from './features/orderFilters/orderFilters'
import { ThankYou } from './features/plan/thankYou'
import { ProductAnalysis } from './features/productAnalysis/productAnalysis'
import { AuthService } from './gen/proto/auth/auth_pb'
import { LimitedAccessLayout } from './layouts/limitedAccessLayout/limitedAccessLayout'

export const AppRoutes: FC = () => {
  const authUser = useAuthUser()
  const isAuthorized = !!authUser

  if (isMaintenanceMode && !hasDeveloperCookie) {
    return (
      <Routes>
        <Route path='/' element={<PublicLayout />}>
          <Route path='/' element={<Maintenance />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    )
  }

  return isAuthorized ? (
    // Roots for LOGIN user
    <AccountProvider>
      <InitChannelTalk>
        <AuthorizedRoutes />
      </InitChannelTalk>
    </AccountProvider>
  ) : (
    // Roots for LOGOUT user
    <UnAuthorizedRoutes />
  )
}

const AuthorizedRoutes: FC = () => {
  const { i18n } = useTranslation()
  const { loading, account, shop } = useAccount()
  const location = useLocation()
  const authUser = useAuthUser()
  const authService = useGrpcClient(AuthService)
  const { notifySentry } = useSentryNotifier()

  const { setThemeMode } = useThemeContext()

  const [appUninstalledDialogOpen, setAppUninstalledDialogOpen] = useState(false)

  useEffect(() => {
    if (account.language !== i18n.language) {
      i18n.changeLanguage(account.language)
    }
  }, [account, i18n])

  useEffect(() => {
    trackIdentifyEvent(shop.shopName)
  }, [shop])

  useEffect(() => {
    // MEMO: Stop dark mode while replacing the theme to shadcn.
    // if (shop.shopifyDataSyncStatus === ShopifyDataSyncStatus.completed) {
    //   setThemeMode(account.displayDarkMode ? 'dark' : 'light')
    // } else {
    //   setThemeMode('light')
    // }
    setThemeMode('light')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, shop])

  useEffect(() => {
    const verifyAppInstallation = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await authService.verifyAppInstallation({}, { headers: { Authorization: `Bearer ${token}` } })
        setAppUninstalledDialogOpen(!resp.installed) // If app is not installed, set true.
      } catch (err) {
        notifySentry(err)
      }
    }
    if (shop.isDemo) return

    verifyAppInstallation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop])

  const upgradeRequiredDialogOpen = useMemo(() => {
    if (shop.isDemo) return false
    if (shop.billingPlan.identifier === BillingPlanIdentifier.scale) return false // temporary
    if (location.pathname.includes('settings')) return false

    return isUpgradeRequired(shop)
  }, [shop, location])

  if (loading) return <></>

  if (!shop.lastDatasetEtlCompletedAt && shop.datasetEtlStatus !== DatasetEtlStatus.COMPLETED) {
    return (
      <>
        <Routes>
          <Route path='/' element={<LimitedAccessLayout />}>
            <Route path='/' element={shop.datasetEtlStatus === DatasetEtlStatus.PENDING ? <DataSyncNotStarted /> : <DataSyncProcessing />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
        {appUninstalledDialogOpen && <AppUninstalledDialog open={appUninstalledDialogOpen} />}
      </>
    )
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<DashboardLayout />}>
          {/* Customer Analysis */}
          <Route path={AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS} element={<WithPageViewTracker page={CustomerAnalysis} />} />
          <Route path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS} element={<WithPageViewTracker page={CustomerSegments} />} />
          <Route
            path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL}
            element={<WithPageViewTracker page={SegmentDetail} pageIdentifier={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL} />}
          />
          <Route path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_COMPARE} element={<WithPageViewTracker page={CustomerSegmentCompare} />} />

          {/* Order Analysis */}
          <Route path={AUTHORIZED_ROUTE.ORDER_ANALYSIS} element={<WithPageViewTracker page={OrderAnalysis} />} />
          <Route path={AUTHORIZED_ROUTE.ORDER_FILTERS} element={<WithPageViewTracker page={OrderFilters} />} />
          <Route path={AUTHORIZED_ROUTE.ORDER_FILTER_COMPARE} element={<WithPageViewTracker page={OrderFilterCompare} />} />

          {/* Product Analysis */}
          <Route path={AUTHORIZED_ROUTE.PRODUCT_ANALYSIS} element={<WithPageViewTracker page={ProductAnalysis} />} />

          {/* Setting */}
          <Route path={AUTHORIZED_ROUTE.SETTINGS_WORKSPACE} element={<WithPageViewTracker page={Settings} />} />
          <Route path={AUTHORIZED_ROUTE.SETTINGS_ACCOUNT} element={<WithPageViewTracker page={Settings} />} />
          <Route path={AUTHORIZED_ROUTE.SETTINGS_MEMBERS} element={<WithPageViewTracker page={Settings} />} />
          <Route path={AUTHORIZED_ROUTE.SETTINGS_PLAN} element={<WithPageViewTracker page={Settings} />} />

          {/* Others */}
          <Route path='/' element={<WithPageViewTracker page={Home} />} />
          <Route path={AUTHORIZED_ROUTE.THANK_YOU} element={<WithPageViewTracker page={ThankYou} />} />
        </Route>
        <Route path='*' element={<Navigate to={AUTHORIZED_ROUTE.HOME} replace />} />
      </Routes>

      {upgradeRequiredDialogOpen && <UpgradeRequiredDialog open={upgradeRequiredDialogOpen} />}
      {appUninstalledDialogOpen && <AppUninstalledDialog open={appUninstalledDialogOpen} />}
    </>
  )
}

const UnAuthorizedRoutes: FC = () => {
  const { setThemeMode } = useThemeContext()

  useEffect(() => {
    setThemeMode('light')
  }, [setThemeMode])

  return (
    <Routes>
      <Route path='/' element={<PublicLayout />}>
        <Route path={PUBLIC_ROUTE.SIGNUP} element={<Signup />} />
        <Route path={PUBLIC_ROUTE.LOGIN} element={<Login />} />
        <Route path={PUBLIC_ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={PUBLIC_ROUTE.MEMBER_SIGNUP} element={<MemberSignup />} />
        <Route path='/' element={<Navigate to={PUBLIC_ROUTE.LOGIN} replace />} />
        <Route path='*' element={<Navigate to={PUBLIC_ROUTE.LOGIN} replace />} />
      </Route>
    </Routes>
  )
}

const InitChannelTalk: FC<{ children: ReactNode }> = ({ children }) => {
  useInitChannelTalk()
  return <>{children}</>
}
