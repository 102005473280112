import { InsightDimension } from '@/gen/firestore'
import { OP_CONTAIN_ANY, OP_EQUAL } from './querySetForm/schema/const'
import { QueryItemFormState, QuerySetFormState } from './querySetForm/schema/querySetFormSchema'

export type CustomerMetrics = {
  count: number
  repeaterRate: number
  ltv: number
  avgOrderValue: number
  avgOrderCount: number
  avgDaysBetweenOrders: number
}

export type CustomerState = {
  id: string
  email: string
  name: string
  recency: number | undefined // if customer has no orders, recency field is undefined.
  frequency: number
  monetary: number
}

export type CustomerInsightValue = {
  name: string
  customerCount: number
  ratio: number
  visible: boolean // for free plan
}

export type MonthlyCohort = {
  monthlyResult: MonthlyResult[]
  overallResult: MonthlyStepAndMetrics[]
}

export type MonthlyResult = {
  firstOrderYearMonth: string
  stepAndMetricsList: MonthlyStepAndMetrics[]
}

export type MonthlyStepAndMetrics = {
  step: number
  customerCount: number
  ltv: number
  avgOrderCount: number
  repurchaseRate: number
}

export const generateQuerySetByInsight = (
  insightDimension: InsightDimension,
  value: string,
  originalQuerySet?: QuerySetFormState
): QuerySetFormState | undefined => {
  let queryItem: QueryItemFormState

  switch (insightDimension) {
    case InsightDimension.product:
      queryItem = {
        dimension: 'product',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.productType:
      queryItem = {
        dimension: 'product_type',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.productVendor:
      queryItem = {
        dimension: 'product_vendor',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.productTag:
      queryItem = {
        dimension: 'product_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.productVariant:
      queryItem = {
        dimension: 'product_variant',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.referrer:
      queryItem = {
        dimension: 'referrer',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.landingPage:
      queryItem = {
        dimension: 'landing_page',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.utmSource:
      queryItem = {
        dimension: 'utm_source',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.utmMedium:
      queryItem = {
        dimension: 'utm_medium',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.utmCampaign:
      queryItem = {
        dimension: 'utm_campaign',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.channel:
      queryItem = {
        dimension: 'channel',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.orderTag:
      queryItem = {
        dimension: 'order_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.coupon:
      queryItem = {
        dimension: 'coupon',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.country:
      queryItem = {
        dimension: 'country',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.province:
      queryItem = {
        dimension: 'prefecture',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.customerTag:
      queryItem = {
        dimension: 'customer_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.orderCount:
      queryItem = {
        dimension: 'frequency',
        operator: OP_EQUAL,
        value: Number(value),
        filters: [],
      }
      break
    default:
      return undefined
  }

  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: [queryItem],
      },
    ],
  }

  if (originalQuerySet) {
    querySet.query_subsets = [...originalQuerySet.query_subsets, ...querySet.query_subsets]
  }

  return querySet
}
