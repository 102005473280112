import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type selectItem = {
  value: string
  label: string
}

type Props = {
  queryItemValuePath: `query_items.${number}.value`
  selectItems: selectItem[]
}

export const SingleSelectValue: FC<Props> = ({ queryItemValuePath, selectItems }) => {
  const { control, watch } = useFormContext<QuerySetFormState>()
  const selectedValue = watch(queryItemValuePath) as string

  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <Select value={selectedValue} onValueChange={(value) => field.onChange(value)}>
              <SelectTrigger>
                <SelectValue placeholder='Select an option' />
              </SelectTrigger>
              <SelectContent>
                {selectItems.map((item, index) => {
                  const selectItemKey = `${queryItemValuePath}-${index}`
                  return (
                    <SelectItem key={selectItemKey} value={item.value}>
                      {item.label}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
            {error && <div className='mt-2 text-red-500 text-xs'>{error.message}</div>}
          </div>
        )
      }}
    />
  )
}
