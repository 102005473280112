import { DocumentReference, Timestamp } from 'firebase/firestore';
/* Do not change, this code is generated from Golang structs */


export enum CollectionName {
    accounts = "accounts",
    shops = "shops",
    customerSegments = "customerSegments",
    segmentGroups = "segmentGroups",
    tagOperations = "tagOperations",
    orderFilters = "orderFilters",
    dimensionOptions = "dimensionOptions",
}
export enum InsightDimension {
    product = "product",
    productType = "product_type",
    productVendor = "product_vendor",
    productTag = "product_tag",
    productVariant = "product_variant",
    referrer = "referrer",
    landingPage = "landing_page",
    utmSource = "utm_source",
    utmMedium = "utm_medium",
    utmCampaign = "utm_campaign",
    channel = "channel",
    orderTag = "order_tag",
    coupon = "coupon",
    country = "country",
    province = "province",
    customerTag = "customer_tag",
    orderCount = "order_count",
    orderGapDays = "order_gap_days",
}
export enum OrderInsightDimension {
    product = "product",
    productType = "productType",
    productTag = "productTag",
    productVendor = "productVendor",
    orderTag = "orderTag",
    channel = "channel",
    coupon = "coupon",
    country = "country",
    referrer = "referrer",
    utmSource = "utmSource",
    utmMedium = "utmMedium",
    utmCampaign = "utmCampaign",
    newRepeat = "newRepeat",
}
export enum ProductDimension {
    product_name = "product_name",
    product_id = "product_id",
    product_type = "product_type",
}
export enum TagOperationType {
    addTag = "addTag",
    removeTag = "removeTag",
}
export enum TagOperationStatus {
    processing = "processing",
    completed = "completed",
    error = "error",
}
export enum ShopBillingStatus {
    free = "free",
    paid = "paid",
    trial = "trial",
}
export enum OrderPricingRule {
    checkoutPrice = "checkoutPrice",
    checkoutPriceExclTaxesShipping = "checkoutPriceExclTaxesShipping",
    checkoutPriceInclDiscounts = "checkoutPriceInclDiscounts",
    checkoutPriceExclTaxesShippingInclDiscounts = "checkoutPriceExclTaxesShippingInclDiscounts",
}
export enum DatasetEtlStatus {
    PENDING = "PENDING",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
}
export enum Language {
    en = "en",
    ja = "ja",
}
export enum BillingPlanIdentifier {
    free = "free",
    starter = "starter",
    growth = "growth",
    scale = "scale",
    enterprise = "enterprise",
}
export enum AccountRole {
    owner = "owner",
    member = "member",
}
export enum AccountInvitationStatus {
    active = "active",
    invited = "invited",
    expired = "expired",
}
export interface AccountStats {
    initialCreateSegmentAt?: Timestamp;
    initialDeleteSegmentAt?: Timestamp;
    createSegmentCount: number;
    deleteSegmentCount: number;
    createGroupCount: number;
    updateCanvasCount: number;
    updateTagOperationCount: number;
}
export interface Account {
    ref: DocumentReference;
    shopRef?: DocumentReference;
    email: string;
    role: AccountRole;
    language: Language;
    displayDarkMode: boolean;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    stats: AccountStats;
}
export interface TempBillingPlan {
    shopifyAppSubscriptionID?: string;
    identifier: BillingPlanIdentifier;
    amount: number;
    maxActiveCustomerCount: number;
    createdAt: Timestamp;
}
export interface BillingPlanChange {
    plan: BillingPlanIdentifier;
    amount: number;
    changedAt: Timestamp;
}
export interface BillingPlan {
    shopifyAppSubscriptionID?: string;
    identifier: BillingPlanIdentifier;
    amount: number;
    maxActiveCustomerCount: number;
}
export interface Shop {
    ref: DocumentReference;
    shopifySecretRef?: DocumentReference;
    shopName: string;
    displayName: string;
    bigQueryDatasetID: string;
    currency: string;
    timezone: string;
    country: string;
    countryCode: string;
    shopifyPlanName: string;
    billingPlan: BillingPlan;
    billingPlanUpdatedAt?: Timestamp;
    billingPlanChanges: BillingPlanChange[];
    tempBillingPlan?: TempBillingPlan;
    datasetEtlStatus: DatasetEtlStatus;
    lastDatasetEtlCompletedAt?: Timestamp;
    activeCustomerCount: number;
    ownerEmail?: string;
    ownerSignedUpAt?: Timestamp;
    primaryLanguage: Language;
    orderPricingRule: OrderPricingRule;
    isDemo: boolean;
    trialEndsAt: Timestamp;
    appInstalledAt: Timestamp;
    uninstalledAt?: Timestamp;
    createdAt: Timestamp;
    closed: boolean;
}
export interface TagOperationSetting {
    isActive: boolean;
    tagName: string;
    lastSyncedAt?: Timestamp;
}
export interface CustomerSegment {
    ref: DocumentReference;
    name: string;
    description: string;
    querySet: string;
    tagOperationSetting: TagOperationSetting;
    createdAt: Timestamp;
    updatedAt: Timestamp;
}
export interface SegmentGroup {
    ref: DocumentReference;
    name: string;
    customerSegmentRefs: DocumentReference[];
    createdAt: Timestamp;
    updatedAt: Timestamp;
}
export interface TagOperation {
    ref: DocumentReference;
    customerSegmentRef?: DocumentReference;
    tagName: string;
    type: TagOperationType;
    status: TagOperationStatus;
    customersPath: string;
    customerCount: number;
    createdAt: Timestamp;
    updatedAt: Timestamp;
}
export interface OrderFilter {
    ref: DocumentReference;
    name: string;
    querySet: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
}
export interface DimensionOptions {
    ref: DocumentReference;
    dimension: string;
    options: string[];
    createdAt: Timestamp;
}
