import { SkeletonTable } from '@/components/skeletonTable'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { OrderDrawer } from '@/features/orders/orderDrawer'
import { ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCsvDownload } from '@/hooks/useCsvDownload'
import { useCurrency } from '@/hooks/useCurrency'
import { useActionTracker } from '@/hooks/useMixpanel'
import { EmptyState } from 'components/emptyState'
import { Download, FileSearch, LoaderCircle } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, formatDateTime } from 'utils/timeUtil'
import { useOrdersDownload } from '../hooks/useOrdersDownload'
import { DailyOrderSummary, OrderMetricsKey, OrderState } from '../types'
import { MetricsChartCard } from './components/metricsChartCard'
import { useCurrentDate } from '@/hooks/useCurrentDate'

type Props = {
  querySetString: string
  dailyMetrics: DailyOrderSummary[] | undefined
  orders: OrderState[] | undefined
  dailyMetricsLoading: boolean
  ordersLoading: boolean
}

type OrderDrawerState = {
  open: boolean
  order:
    | {
        id: string
        name: string
      }
    | undefined
}

const defaultOrderDrawerState: OrderDrawerState = { open: false, order: undefined }

export const Overview: FC<Props> = ({ querySetString, dailyMetrics, orders, dailyMetricsLoading, ordersLoading }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [orderDrawerState, setOrderDrawerState] = useState<OrderDrawerState>(defaultOrderDrawerState)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const { handleDownload: handleDownloadDailyMetrics, loading: downloadDailyMetricsLoading } = useCsvDownload({
    data: dailyMetrics || [],
    headers: [
      t('features.orderAnalysis.date'),
      t('features.orderAnalysis.orderAmount'),
      t('features.orderAnalysis.orderCount'),
      t('features.orderAnalysis.avgOrderValue'),
    ],
    fileName: t('features.orderAnalysis.overview.downloadFileName_dailyOrders', {
      date: formatDate(currentDate, i18n.language),
    }),
    page: 'orderAnalysis_dailyMetrics',
  })

  const { handleDownload: handleDownloadOrders, loading: downloadOrdersLoading } = useOrdersDownload({
    querySetString,
    fileName: t('features.orderAnalysis.overview.downloadFileName_orders', { date: formatDate(currentDate, i18n.language) }),
    page: 'orderAnalysis_orders',
  })

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4'>
          {Object.values(OrderMetricsKey).map((metric, index) => (
            <MetricsChartCard key={index} metricsKey={metric} dailySummaries={dailyMetrics} loading={dailyMetricsLoading} />
          ))}
        </div>

        <Card>
          <CardHeader>
            <div className='flex items-center justify-between'>
              <CardTitle>{t('features.orderAnalysis.overview.title')}</CardTitle>
              <div className='flex items-center gap-2'>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <TooltipIconButton
                      icon={downloadDailyMetricsLoading || downloadOrdersLoading ? LoaderCircle : Download}
                      tooltipContent={t('features.orderAnalysis.download')}
                      variant='ghost'
                      disabled={!orders || orders.length === 0 || downloadOrdersLoading}
                    />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeDialogOpen(true)
                          dispatch('OpenUpgradeDialog', { referrer: 'orderAnalysis_overview_dailyMetrics_csv' })
                          return
                        }
                        handleDownloadDailyMetrics()
                      }}
                    >
                      {t('features.orderAnalysis.overview.download_dailyOrders')}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        if (shopBillingStatus === ShopBillingStatus.free) {
                          setUpgradeDialogOpen(true)
                          dispatch('OpenUpgradeDialog', { referrer: 'orderAnalysis_overview_orders_csv' })
                          return
                        }
                        handleDownloadOrders()
                      }}
                    >
                      {t('features.orderAnalysis.overview.download_orders')}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {ordersLoading || !orders ? (
              <SkeletonTable columnCount={10} rowCount={10} />
            ) : orders.length === 0 ? (
              <div className='flex justify-center items-center h-[320px]'>
                <EmptyState title={t('features.orderAnalysis.overview.empty_orders')} />
              </div>
            ) : (
              <Table>
                {orders.length === 100 && <TableCaption>{t('features.orderAnalysis.overview.alert')}</TableCaption>}
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.orders.name')}</TableHead>
                    <TableHead>{t('features.orders.amount')}</TableHead>
                    <TableHead>{t('features.orders.channel')}</TableHead>
                    <TableHead className='w-[120px]'>{t('features.orders.referrer')}</TableHead>
                    <TableHead>{t('features.orders.coupon')}</TableHead>
                    <TableHead>{t('features.orders.datetime')}</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.name}</TableCell>
                      <TableCell>{formatCurrency(order.amount)}</TableCell>
                      <TableCell>
                        <Badge variant='outline'>{order.channel}</Badge>
                      </TableCell>
                      <TableCell>{order.referrer || '-'}</TableCell>
                      <TableCell>{order.coupon || '-'}</TableCell>
                      <TableCell>{formatDateTime(order.orderAt, i18n.language)}</TableCell>
                      <TableCell className='text-right'>
                        <TooltipIconButton
                          icon={FileSearch}
                          tooltipContent={t('features.orderAnalysis.overview.viewOrder')}
                          variant='ghost'
                          onClick={() => {
                            setOrderDrawerState({ open: true, order: { id: order.id, name: order.name } })
                            dispatch('OpenOrderDrawer', { name: order.id })
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </div>

      <OrderDrawer
        open={orderDrawerState.open}
        handleOpen={() => setOrderDrawerState({ open: true, order: orderDrawerState.order })}
        handleClose={() => setOrderDrawerState(defaultOrderDrawerState)}
        orderProps={orderDrawerState.order}
      />

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
