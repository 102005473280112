// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/order_analysis/order_analysis.proto (package order_analysis, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/order_analysis/order_analysis.proto.
 */
export const file_proto_order_analysis_order_analysis: GenFile = /*@__PURE__*/
  fileDesc("Cilwcm90by9vcmRlcl9hbmFseXNpcy9vcmRlcl9hbmFseXNpcy5wcm90bxIOb3JkZXJfYW5hbHlzaXMiKgoWR2V0RGFpbHlNZXRyaWNzUmVxdWVzdBIQCghxdWVyeVNldBgBIAEoCSLVAQoXR2V0RGFpbHlNZXRyaWNzUmVzcG9uc2USSgoMZGFpbHlNZXRyaWNzGAEgAygLMjQub3JkZXJfYW5hbHlzaXMuR2V0RGFpbHlNZXRyaWNzUmVzcG9uc2UuRGFpbHlNZXRyaWNzGm4KDERhaWx5TWV0cmljcxIMCgRkYXRlGAEgASgJEg4KBmFtb3VudBgCIAEoARISCgpvcmRlckNvdW50GAMgASgDEhUKDWN1c3RvbWVyQ291bnQYBCABKAMSFQoNYXZnT3JkZXJWYWx1ZRgFIAEoASJCChBHZXRPcmRlcnNSZXF1ZXN0EhAKCHF1ZXJ5U2V0GAEgASgJEhIKBWxpbWl0GAIgASgDSACIAQFCCAoGX2xpbWl0Ip8CChFHZXRPcmRlcnNSZXNwb25zZRI3CgZvcmRlcnMYASADKAsyJy5vcmRlcl9hbmFseXNpcy5HZXRPcmRlcnNSZXNwb25zZS5PcmRlchrQAQoFT3JkZXISCgoCaWQYASABKAkSDAoEbmFtZRgCIAEoCRIOCgZhbW91bnQYAyABKAESDwoHb3JkZXJBdBgEIAEoCRIQCghyZWZlcnJlchgFIAEoCRIPCgdjaGFubmVsGAYgASgJEg4KBmNvdXBvbhgHIAEoCRIRCgl1dG1Tb3VyY2UYCCABKAkSEQoJdXRtTWVkaXVtGAkgASgJEhMKC3V0bUNhbXBhaWduGAogASgJEhAKCHByb2R1Y3RzGAsgAygJEgwKBHRhZ3MYDCADKAkiVwoSR2V0SW5zaWdodHNSZXF1ZXN0EhAKCHF1ZXJ5U2V0GAEgASgJEhEKCWRpbWVuc2lvbhgCIAEoCRISCgVsaW1pdBgDIAEoA0gAiAEBQggKBl9saW1pdCLHAQoTR2V0SW5zaWdodHNSZXNwb25zZRJACgZ2YWx1ZXMYASADKAsyMC5vcmRlcl9hbmFseXNpcy5HZXRJbnNpZ2h0c1Jlc3BvbnNlLkluc2lnaHRWYWx1ZRpuCgxJbnNpZ2h0VmFsdWUSDAoEbmFtZRgBIAEoCRIOCgZhbW91bnQYAiABKAESEgoKb3JkZXJDb3VudBgDIAEoAxIVCg1jdXN0b21lckNvdW50GAQgASgDEhUKDWF2Z09yZGVyVmFsdWUYBSABKAEyqgIKFE9yZGVyQW5hbHlzaXNTZXJ2aWNlEmQKD0dldERhaWx5TWV0cmljcxImLm9yZGVyX2FuYWx5c2lzLkdldERhaWx5TWV0cmljc1JlcXVlc3QaJy5vcmRlcl9hbmFseXNpcy5HZXREYWlseU1ldHJpY3NSZXNwb25zZSIAElIKCUdldE9yZGVycxIgLm9yZGVyX2FuYWx5c2lzLkdldE9yZGVyc1JlcXVlc3QaIS5vcmRlcl9hbmFseXNpcy5HZXRPcmRlcnNSZXNwb25zZSIAElgKC0dldEluc2lnaHRzEiIub3JkZXJfYW5hbHlzaXMuR2V0SW5zaWdodHNSZXF1ZXN0GiMub3JkZXJfYW5hbHlzaXMuR2V0SW5zaWdodHNSZXNwb25zZSIAQj5aPGVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9vcmRlcl9hbmFseXNpcztvcmRlcl9hbmFseXNpc19wYmIGcHJvdG8z");

/**
 * GetDailyMetrics
 *
 * @generated from message order_analysis.GetDailyMetricsRequest
 */
export type GetDailyMetricsRequest = Message<"order_analysis.GetDailyMetricsRequest"> & {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet: string;
};

/**
 * Describes the message order_analysis.GetDailyMetricsRequest.
 * Use `create(GetDailyMetricsRequestSchema)` to create a new message.
 */
export const GetDailyMetricsRequestSchema: GenMessage<GetDailyMetricsRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 0);

/**
 * @generated from message order_analysis.GetDailyMetricsResponse
 */
export type GetDailyMetricsResponse = Message<"order_analysis.GetDailyMetricsResponse"> & {
  /**
   * @generated from field: repeated order_analysis.GetDailyMetricsResponse.DailyMetrics dailyMetrics = 1;
   */
  dailyMetrics: GetDailyMetricsResponse_DailyMetrics[];
};

/**
 * Describes the message order_analysis.GetDailyMetricsResponse.
 * Use `create(GetDailyMetricsResponseSchema)` to create a new message.
 */
export const GetDailyMetricsResponseSchema: GenMessage<GetDailyMetricsResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 1);

/**
 * @generated from message order_analysis.GetDailyMetricsResponse.DailyMetrics
 */
export type GetDailyMetricsResponse_DailyMetrics = Message<"order_analysis.GetDailyMetricsResponse.DailyMetrics"> & {
  /**
   * format: YYYY-MM-DD
   *
   * @generated from field: string date = 1;
   */
  date: string;

  /**
   * @generated from field: double amount = 2;
   */
  amount: number;

  /**
   * @generated from field: int64 orderCount = 3;
   */
  orderCount: bigint;

  /**
   * @generated from field: int64 customerCount = 4;
   */
  customerCount: bigint;

  /**
   * @generated from field: double avgOrderValue = 5;
   */
  avgOrderValue: number;
};

/**
 * Describes the message order_analysis.GetDailyMetricsResponse.DailyMetrics.
 * Use `create(GetDailyMetricsResponse_DailyMetricsSchema)` to create a new message.
 */
export const GetDailyMetricsResponse_DailyMetricsSchema: GenMessage<GetDailyMetricsResponse_DailyMetrics> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 1, 0);

/**
 * GetOrders
 *
 * @generated from message order_analysis.GetOrdersRequest
 */
export type GetOrdersRequest = Message<"order_analysis.GetOrdersRequest"> & {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet: string;

  /**
   * @generated from field: optional int64 limit = 2;
   */
  limit?: bigint;
};

/**
 * Describes the message order_analysis.GetOrdersRequest.
 * Use `create(GetOrdersRequestSchema)` to create a new message.
 */
export const GetOrdersRequestSchema: GenMessage<GetOrdersRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 2);

/**
 * @generated from message order_analysis.GetOrdersResponse
 */
export type GetOrdersResponse = Message<"order_analysis.GetOrdersResponse"> & {
  /**
   * @generated from field: repeated order_analysis.GetOrdersResponse.Order orders = 1;
   */
  orders: GetOrdersResponse_Order[];
};

/**
 * Describes the message order_analysis.GetOrdersResponse.
 * Use `create(GetOrdersResponseSchema)` to create a new message.
 */
export const GetOrdersResponseSchema: GenMessage<GetOrdersResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 3);

/**
 * @generated from message order_analysis.GetOrdersResponse.Order
 */
export type GetOrdersResponse_Order = Message<"order_analysis.GetOrdersResponse.Order"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: double amount = 3;
   */
  amount: number;

  /**
   * format: YYYY-MM-DD HH:MM:SS
   *
   * @generated from field: string orderAt = 4;
   */
  orderAt: string;

  /**
   * @generated from field: string referrer = 5;
   */
  referrer: string;

  /**
   * @generated from field: string channel = 6;
   */
  channel: string;

  /**
   * @generated from field: string coupon = 7;
   */
  coupon: string;

  /**
   * @generated from field: string utmSource = 8;
   */
  utmSource: string;

  /**
   * @generated from field: string utmMedium = 9;
   */
  utmMedium: string;

  /**
   * @generated from field: string utmCampaign = 10;
   */
  utmCampaign: string;

  /**
   * @generated from field: repeated string products = 11;
   */
  products: string[];

  /**
   * @generated from field: repeated string tags = 12;
   */
  tags: string[];
};

/**
 * Describes the message order_analysis.GetOrdersResponse.Order.
 * Use `create(GetOrdersResponse_OrderSchema)` to create a new message.
 */
export const GetOrdersResponse_OrderSchema: GenMessage<GetOrdersResponse_Order> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 3, 0);

/**
 * GetInsights
 *
 * @generated from message order_analysis.GetInsightsRequest
 */
export type GetInsightsRequest = Message<"order_analysis.GetInsightsRequest"> & {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet: string;

  /**
   * @generated from field: string dimension = 2;
   */
  dimension: string;

  /**
   * @generated from field: optional int64 limit = 3;
   */
  limit?: bigint;
};

/**
 * Describes the message order_analysis.GetInsightsRequest.
 * Use `create(GetInsightsRequestSchema)` to create a new message.
 */
export const GetInsightsRequestSchema: GenMessage<GetInsightsRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 4);

/**
 * @generated from message order_analysis.GetInsightsResponse
 */
export type GetInsightsResponse = Message<"order_analysis.GetInsightsResponse"> & {
  /**
   * @generated from field: repeated order_analysis.GetInsightsResponse.InsightValue values = 1;
   */
  values: GetInsightsResponse_InsightValue[];
};

/**
 * Describes the message order_analysis.GetInsightsResponse.
 * Use `create(GetInsightsResponseSchema)` to create a new message.
 */
export const GetInsightsResponseSchema: GenMessage<GetInsightsResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 5);

/**
 * @generated from message order_analysis.GetInsightsResponse.InsightValue
 */
export type GetInsightsResponse_InsightValue = Message<"order_analysis.GetInsightsResponse.InsightValue"> & {
  /**
   * If the name is null, an empty string will be returned
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: double amount = 2;
   */
  amount: number;

  /**
   * @generated from field: int64 orderCount = 3;
   */
  orderCount: bigint;

  /**
   * @generated from field: int64 customerCount = 4;
   */
  customerCount: bigint;

  /**
   * @generated from field: double avgOrderValue = 5;
   */
  avgOrderValue: number;
};

/**
 * Describes the message order_analysis.GetInsightsResponse.InsightValue.
 * Use `create(GetInsightsResponse_InsightValueSchema)` to create a new message.
 */
export const GetInsightsResponse_InsightValueSchema: GenMessage<GetInsightsResponse_InsightValue> = /*@__PURE__*/
  messageDesc(file_proto_order_analysis_order_analysis, 5, 0);

/**
 * @generated from service order_analysis.OrderAnalysisService
 */
export const OrderAnalysisService: GenService<{
  /**
   * @generated from rpc order_analysis.OrderAnalysisService.GetDailyMetrics
   */
  getDailyMetrics: {
    methodKind: "unary";
    input: typeof GetDailyMetricsRequestSchema;
    output: typeof GetDailyMetricsResponseSchema;
  },
  /**
   * @generated from rpc order_analysis.OrderAnalysisService.GetOrders
   */
  getOrders: {
    methodKind: "unary";
    input: typeof GetOrdersRequestSchema;
    output: typeof GetOrdersResponseSchema;
  },
  /**
   * @generated from rpc order_analysis.OrderAnalysisService.GetInsights
   */
  getInsights: {
    methodKind: "unary";
    input: typeof GetInsightsRequestSchema;
    output: typeof GetInsightsResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_order_analysis_order_analysis, 0);

