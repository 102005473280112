import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { CustomerMetrics } from '@/features/customerAnalysis/types'
import { CustomerSegment, ShopBillingStatus } from '@/gen/firestore'
import { CustomerAnalysisService } from '@/gen/proto/customer_analysis/customer_analysis_pb'
import { useAccount } from '@/hooks/useAccount'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { useCsvDownload } from '@/hooks/useCsvDownload'
import { useCurrency } from '@/hooks/useCurrency'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useCollection } from '@/hooks/useFirestoreData'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useActionTracker } from '@/hooks/useMixpanel'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from '@/utils/iconUtil'
import { formatDate } from '@/utils/timeUtil'
import { getIdToken } from '@firebase/auth'
import { query } from 'firebase/firestore'
import { Download, LoaderCircle, Plus, UserRoundSearch, X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { MAX_SELECTABLE_SEGMENTS } from '../const'

export const Compare: FC = () => {
  const { t, i18n } = useTranslation()
  const { account, shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { executeRequest } = useConcurrentRequest()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const customerAnalysisService = useGrpcClient(CustomerAnalysisService)
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const queryParams = new URLSearchParams(location.search)
  const ids = queryParams.get('ids')

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef))

  const [selectedSegments, setSelectedSegments] = useState<CustomerSegment[]>([])
  const [segmentMetrics, setSegmentMetrics] = useState<Record<string, CustomerMetrics>>({})
  const [comboBoxOpen, setComboBoxOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  // Execute once on page load when customerSegments is available
  useEffect(() => {
    if (customerSegments) {
      setSelectedSegments(customerSegments.filter((segment) => ids?.includes(segment.ref.id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegments])

  useEffect(() => {
    if (selectedSegments.length === 0) return
    // Update the URL query params without navigating
    const url = new URL(window.location.href)
    url.searchParams.set('ids', selectedSegments.map((segment) => segment.ref.id).join(','))
    window.history.replaceState({}, '', url.toString())
    // Fetch the metrics for the selected segments
    selectedSegments.forEach((segment) => {
      if (!segmentMetrics[segment.ref.id]) {
        executeRequest(() => fetchSegmentMetrics(segment))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegments])

  const addSelectedCustomerSegment = (id: string) => {
    if (customerSegments) {
      const segment = customerSegments.find((segment) => segment.ref.id === id)
      if (segment && !selectedSegments.some((s) => s.ref.id === id)) {
        setSelectedSegments([...selectedSegments, segment])
      }
    }
  }

  const removeSelectedCustomerSegment = (id: string) => {
    if (selectedSegments.length > 1) {
      setSelectedSegments(selectedSegments.filter((segment) => segment.ref.id !== id))
    }
  }

  const fetchSegmentMetrics = async (segment: CustomerSegment) => {
    try {
      const token = await getIdToken(authUser!)
      const resp = await customerAnalysisService.getMetrics({ querySet: segment.querySet }, { headers: { Authorization: `Bearer ${token}` } })

      setSegmentMetrics((prev) => ({
        ...prev,
        [segment.ref.id]: {
          count: Number(resp.count),
          repeaterRate: Number(resp.repeaterRate),
          ltv: Number(resp.ltv),
          avgOrderValue: Number(resp.avgOrderValue),
          avgOrderCount: Number(resp.avgOrderCount),
          avgDaysBetweenOrders: Number(resp.avgDaysBetweenOrders),
        },
      }))
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  const formatCustomerMetricsValue = (key: string, value: number | undefined) => {
    switch (key) {
      case 'count':
        return value?.toLocaleString()
      case 'repeaterRate':
        return value && `${(value * 100).toFixed(1)}%`
      case 'ltv':
      case 'avgOrderValue':
        return value && formatCurrency(value)
      default:
        return value?.toFixed(1)
    }
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: selectedSegments.map((segment) => ({
      name: extractTextFromTextWithIcon(segment.name),
      count: segmentMetrics[segment.ref.id]?.count,
      repeaterRate: segmentMetrics[segment.ref.id]?.repeaterRate,
      ltv: segmentMetrics[segment.ref.id]?.ltv,
      avgOrderValue: segmentMetrics[segment.ref.id]?.avgOrderValue,
      avgOrderCount: segmentMetrics[segment.ref.id]?.avgOrderCount,
      avgDaysBetweenOrders: segmentMetrics[segment.ref.id]?.avgDaysBetweenOrders,
    })),
    headers: [
      t('features.customerSegments.compare.name'),
      t('features.customerAnalysis.customerMetrics.title_count'),
      t('features.customerAnalysis.customerMetrics.title_repeaterRate'),
      t('features.customerAnalysis.customerMetrics.title_ltv'),
      t('features.customerAnalysis.customerMetrics.title_avgOrderValue'),
      t('features.customerAnalysis.customerMetrics.title_avgOrderCount'),
      t('features.customerAnalysis.customerMetrics.title_avgDaysBetweenOrders'),
    ],
    fileName: t('features.customerSegments.compare.downloadFileName', {
      date: formatDate(currentDate, i18n.language),
    }),
    page: 'customerSegments_compare',
  })

  return (
    <>
      <PageHeader
        title={t('features.customerSegments.compare.title')}
        origin={{ title: t('features.customerSegments.compare.segments'), path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS }}
      />

      <PageContentWrapper>
        <div className='flex flex-col gap-8'>
          <div className='flex items-center justify-between gap-4'>
            <div className='flex flex-wrap items-center gap-2'>
              {selectedSegments.map((segment) => (
                <div key={segment.ref.id} className='flex items-center gap-2 bg-muted rounded-md px-3 py-2'>
                  <span className='text-sm'>{segment.name}</span>
                  <X
                    className='h-4 w-4 cursor-pointer text-muted-foreground hover:text-black'
                    onClick={() => removeSelectedCustomerSegment(segment.ref.id)}
                  />
                </div>
              ))}
              <Popover open={comboBoxOpen} onOpenChange={setComboBoxOpen}>
                <PopoverTrigger asChild>
                  <TooltipIconButton
                    icon={Plus}
                    tooltipContent={t('features.customerSegments.compare.addSegment')}
                    variant='ghost'
                    disabled={selectedSegments.length >= MAX_SELECTABLE_SEGMENTS}
                  />
                </PopoverTrigger>
                <PopoverContent className='min-w-[240px] p-0'>
                  <Command>
                    <CommandInput placeholder={t('features.productAnalysis.selectValue')} className='h-9' />
                    <CommandList>
                      <CommandEmpty>{t('features.productAnalysis.noResults')}</CommandEmpty>
                      <CommandGroup>
                        {customerSegments
                          ?.filter((segment) => !selectedSegments.some((s) => s.ref.id === segment.ref.id))
                          .map((segment) => (
                            <CommandItem
                              key={segment.ref.id}
                              value={segment.ref.id}
                              onSelect={(currentValue) => {
                                addSelectedCustomerSegment(currentValue)
                                setComboBoxOpen(false)
                              }}
                            >
                              {segment.name}
                            </CommandItem>
                          ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <div>
              <TooltipIconButton
                icon={downloadLoading ? LoaderCircle : Download}
                className={downloadLoading ? 'animate-spin' : ''}
                tooltipContent={t('features.customerSegments.compare.download')}
                variant='ghost'
                disabled={!selectedSegments || selectedSegments.length === 0 || downloadLoading}
                onClick={() => {
                  if (shopBillingStatus === ShopBillingStatus.free) {
                    setUpgradeDialogOpen(true)
                    dispatch('OpenUpgradeDialog', { referrer: 'customerSegments_compare_csv' })
                    return
                  }
                  handleDownload()
                }}
              />
            </div>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('features.customerSegments.compare.name')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_count')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_repeaterRate')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_ltv')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_avgOrderValue')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_avgOrderCount')}</TableHead>
                <TableHead className='text-center'>{t('features.customerAnalysis.customerMetrics.title_avgDaysBetweenOrders')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedSegments.map((segment) => (
                <TableRow key={segment.ref.id}>
                  <TableCell>
                    <div className='flex items-center gap-2'>
                      <span className='text-lg'>{extractIconFromTextWithIcon(segment.name)}</span>
                      <span className='text-sm'>{extractTextFromTextWithIcon(segment.name)}</span>
                    </div>
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('count', segmentMetrics[segment.ref.id]?.count) ?? <Skeleton className='h-3 w-16 mx-auto' />}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('repeaterRate', segmentMetrics[segment.ref.id]?.repeaterRate) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('ltv', segmentMetrics[segment.ref.id]?.ltv) ?? <Skeleton className='h-3 w-16 mx-auto' />}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('avgOrderValue', segmentMetrics[segment.ref.id]?.avgOrderValue) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('avgOrderCount', segmentMetrics[segment.ref.id]?.avgOrderCount) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-center'>
                    {formatCustomerMetricsValue('avgDaysBetweenOrders', segmentMetrics[segment.ref.id]?.avgDaysBetweenOrders) ?? (
                      <Skeleton className='h-3 w-16 mx-auto' />
                    )}
                  </TableCell>
                  <TableCell className='text-right'>
                    <TooltipIconButton
                      icon={UserRoundSearch}
                      tooltipContent={t('features.customerSegments.compare.viewMore')}
                      variant='ghost'
                      onClick={() => {
                        const to = `${AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS}?${createSearchParams({
                          querySet: encodeURIComponent(segment.querySet),
                        }).toString()}`
                        navigate(to)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PageContentWrapper>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
