// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/customer/customer.proto (package customer, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/customer/customer.proto.
 */
export const file_proto_customer_customer: GenFile = /*@__PURE__*/
  fileDesc("Ch1wcm90by9jdXN0b21lci9jdXN0b21lci5wcm90bxIIY3VzdG9tZXIiGAoKR2V0UmVxdWVzdBIKCgJpZBgBIAEoCSLFAQoLR2V0UmVzcG9uc2USCgoCaWQYASABKAkSDQoFZW1haWwYAiABKAkSEQoJZmlyc3ROYW1lGAMgASgJEhAKCGxhc3ROYW1lGAQgASgJEg8KB2NvdW50cnkYBSABKAkSEAoIcHJvdmluY2UYBiABKAkSDAoEdGFncxgHIAMoCRIUCgdyZWNlbmN5GAggASgDSACIAQESEQoJZnJlcXVlbmN5GAkgASgDEhAKCG1vbmV0YXJ5GAogASgBQgoKCF9yZWNlbmN5Ih4KEEdldE9yZGVyc1JlcXVlc3QSCgoCaWQYASABKAkikAEKEUdldE9yZGVyc1Jlc3BvbnNlEjEKBm9yZGVycxgBIAMoCzIhLmN1c3RvbWVyLkdldE9yZGVyc1Jlc3BvbnNlLk9yZGVyGkgKBU9yZGVyEgoKAmlkGAEgASgJEgwKBG5hbWUYAiABKAkSEgoKb3JkZXJWYWx1ZRgDIAEoARIRCglvcmRlcmVkQXQYBCABKAkyjwEKD0N1c3RvbWVyU2VydmljZRI0CgNHZXQSFC5jdXN0b21lci5HZXRSZXF1ZXN0GhUuY3VzdG9tZXIuR2V0UmVzcG9uc2UiABJGCglHZXRPcmRlcnMSGi5jdXN0b21lci5HZXRPcmRlcnNSZXF1ZXN0GhsuY3VzdG9tZXIuR2V0T3JkZXJzUmVzcG9uc2UiAEIyWjBlY3Bvd2VyLmlvL2FwaS1zZXJ2ZXIvcHJvdG8vY3VzdG9tZXI7Y3VzdG9tZXJfcGJiBnByb3RvMw");

/**
 * Get
 *
 * @generated from message customer.GetRequest
 */
export type GetRequest = Message<"customer.GetRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message customer.GetRequest.
 * Use `create(GetRequestSchema)` to create a new message.
 */
export const GetRequestSchema: GenMessage<GetRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_customer, 0);

/**
 * @generated from message customer.GetResponse
 */
export type GetResponse = Message<"customer.GetResponse"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * @generated from field: string firstName = 3;
   */
  firstName: string;

  /**
   * @generated from field: string lastName = 4;
   */
  lastName: string;

  /**
   * @generated from field: string country = 5;
   */
  country: string;

  /**
   * @generated from field: string province = 6;
   */
  province: string;

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[];

  /**
   * @generated from field: optional int64 recency = 8;
   */
  recency?: bigint;

  /**
   * @generated from field: int64 frequency = 9;
   */
  frequency: bigint;

  /**
   * @generated from field: double monetary = 10;
   */
  monetary: number;
};

/**
 * Describes the message customer.GetResponse.
 * Use `create(GetResponseSchema)` to create a new message.
 */
export const GetResponseSchema: GenMessage<GetResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_customer, 1);

/**
 * GetOrders
 *
 * @generated from message customer.GetOrdersRequest
 */
export type GetOrdersRequest = Message<"customer.GetOrdersRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message customer.GetOrdersRequest.
 * Use `create(GetOrdersRequestSchema)` to create a new message.
 */
export const GetOrdersRequestSchema: GenMessage<GetOrdersRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_customer, 2);

/**
 * @generated from message customer.GetOrdersResponse
 */
export type GetOrdersResponse = Message<"customer.GetOrdersResponse"> & {
  /**
   * @generated from field: repeated customer.GetOrdersResponse.Order orders = 1;
   */
  orders: GetOrdersResponse_Order[];
};

/**
 * Describes the message customer.GetOrdersResponse.
 * Use `create(GetOrdersResponseSchema)` to create a new message.
 */
export const GetOrdersResponseSchema: GenMessage<GetOrdersResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_customer, 3);

/**
 * @generated from message customer.GetOrdersResponse.Order
 */
export type GetOrdersResponse_Order = Message<"customer.GetOrdersResponse.Order"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: double orderValue = 3;
   */
  orderValue: number;

  /**
   * format: "2006-01-02 15:04:05"
   *
   * @generated from field: string orderedAt = 4;
   */
  orderedAt: string;
};

/**
 * Describes the message customer.GetOrdersResponse.Order.
 * Use `create(GetOrdersResponse_OrderSchema)` to create a new message.
 */
export const GetOrdersResponse_OrderSchema: GenMessage<GetOrdersResponse_Order> = /*@__PURE__*/
  messageDesc(file_proto_customer_customer, 3, 0);

/**
 * @generated from service customer.CustomerService
 */
export const CustomerService: GenService<{
  /**
   * @generated from rpc customer.CustomerService.Get
   */
  get: {
    methodKind: "unary";
    input: typeof GetRequestSchema;
    output: typeof GetResponseSchema;
  },
  /**
   * @generated from rpc customer.CustomerService.GetOrders
   */
  getOrders: {
    methodKind: "unary";
    input: typeof GetOrdersRequestSchema;
    output: typeof GetOrdersResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_customer_customer, 0);

