import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_EQUAL } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { SingleSelectValue } from '../values/selectValue'
import { QueryItemProps } from './type'

export const FrequencyQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[140px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.orderAnalysis.querySet.op', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_EQUAL}>{t('features.orderAnalysis.querySet.op', { context: OP_EQUAL })}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      <SingleSelectValue
        queryItemValuePath={`${queryItemPath}.value`}
        selectItems={[
          {
            value: 'new',
            label: t('features.orderAnalysis.querySet.newCustomer'),
          },
          {
            value: 'repeat',
            label: t('features.orderAnalysis.querySet.repeatCustomer'),
          },
        ]}
      />
    </>
  )
}
