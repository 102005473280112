import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'

import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_RELATIVE_DATE_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { DateRangeValue } from '../value/dateRangeValue'
import { RelativeDateRangeValue } from '../value/relativeDateRangeValue'
import { QueryItemProps } from './type'

export const OrderDateQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${queryItemPath}.operator`)

  return (
    <div className='flex items-start gap-3'>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[120px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange} defaultValue={OP_DATE_RANGE}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerAnalysis.querySet.op', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_DATE_RANGE}>{t('features.customerAnalysis.querySet.op', { context: OP_DATE_RANGE })}</SelectItem>
                    <SelectItem value={OP_RELATIVE_DATE_RANGE}>
                      {t('features.customerAnalysis.querySet.op', { context: OP_RELATIVE_DATE_RANGE })}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      {operator === OP_DATE_RANGE && (
        <DateRangeValue minDateValuePath={`${queryItemPath}.value.min_date`} maxDateValuePath={`${queryItemPath}.value.max_date`} />
      )}

      {operator === OP_RELATIVE_DATE_RANGE && (
        <RelativeDateRangeValue
          minValuePath={`${queryItemPath}.value.min`}
          maxValuePath={`${queryItemPath}.value.max`}
          inputAdornmentString={t('features.customerAnalysis.querySet.suffixDateRange')}
          inputPropsMaxValue={999}
        />
      )}
    </div>
  )
}
