import { z } from 'zod'
import { OP_CONTAIN_ANY, OP_DATE_RANGE, OP_EQUAL, OP_NOT_CONTAIN } from './const'

export const frequencyValue = z.enum(['new', 'repeat'])

export const dateRangeValue = z
  .object({
    min_date: z.string().optional().nullable(),
    max_date: z.string(),
  })
  .refine(
    (data) => {
      if (data.min_date && data.min_date > data.max_date) {
        return false
      }
      return true
    },
    { message: 'features.orderAnalysis.querySet.messageValidationMinMustSmallerThanMax', path: ['min_date'] }
  )
  .refine(
    (data) => {
      if (data.min_date && data.max_date < data.min_date) {
        return false
      }
      return true
    },
    { message: 'features.orderAnalysis.querySet.messageValidationMaxMustBiggerThanMin', path: ['max_date'] }
  )

/* each query form */
const orderDateQuery = z.object({
  dimension: z.literal('order_date'),
  operator: z.literal(OP_DATE_RANGE),
  value: dateRangeValue,
})

const productNameQuery = z.object({
  dimension: z.literal('product_name'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const productTypeQuery = z.object({
  dimension: z.literal('product_type'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const productTagQuery = z.object({
  dimension: z.literal('product_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const productVendorQuery = z.object({
  dimension: z.literal('product_vendor'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const orderTagQuery = z.object({
  dimension: z.literal('order_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const couponQuery = z.object({
  dimension: z.literal('coupon'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const channelQuery = z.object({
  dimension: z.literal('channel'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})
const countryQuery = z.object({
  dimension: z.literal('country'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const referrerQuery = z.object({
  dimension: z.literal('referrer'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const utmSourceQuery = z.object({
  dimension: z.literal('utm_source'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const utmMediumQuery = z.object({
  dimension: z.literal('utm_medium'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const utmCampaignQuery = z.object({
  dimension: z.literal('utm_campaign'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.orderAnalysis.querySet.messageValidationAutoCompleteEmpty'),
})

const frequencyQuery = z.object({
  dimension: z.literal('frequency'),
  operator: z.literal(OP_EQUAL),
  value: frequencyValue,
})

/* query item form */
export const queryItem = z.union([
  orderDateQuery,
  productNameQuery,
  productTypeQuery,
  productTagQuery,
  productVendorQuery,
  orderTagQuery,
  couponQuery,
  channelQuery,
  countryQuery,
  referrerQuery,
  utmSourceQuery,
  utmMediumQuery,
  utmCampaignQuery,
  frequencyQuery,
])

/* query set form */
export const querySet = z.object({
  join_operator: z.literal('and'),
  query_items: queryItem.array(),
})

export type QuerySetFormState = z.infer<typeof querySet>
export type QueryItemFormState = z.infer<typeof queryItem>
