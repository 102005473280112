import { z } from 'zod'
import { OP_CONTAIN_ANY, OP_DATE_RANGE, OP_INT_RANGE, OP_NOT_CONTAIN } from './const'

export const intRangeValue = z.object({
  min: z.number().int().optional().nullable(),
  max: z.number().int().optional().nullable(),
})

export const dateRangeValue = z.object({
  min_date: z.string().optional().nullable(),
  max_date: z.string(),
})

const orderDateQuery = z.object({
  dimension: z.literal('order_date'),
  operator: z.literal(OP_DATE_RANGE),
  value: dateRangeValue,
})

const productNameQuery = z.object({
  dimension: z.literal('product_name'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const productTypeQuery = z.object({
  dimension: z.literal('product_type'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const productTagQuery = z.object({
  dimension: z.literal('product_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const productVendorQuery = z.object({
  dimension: z.literal('product_vendor'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const orderTagQuery = z.object({
  dimension: z.literal('order_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const couponQuery = z.object({
  dimension: z.literal('coupon'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const channelQuery = z.object({
  dimension: z.literal('channel'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const countryQuery = z.object({
  dimension: z.literal('country'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const referrerQuery = z.object({
  dimension: z.literal('referrer'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const utmSourceQuery = z.object({
  dimension: z.literal('utm_source'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const utmMediumQuery = z.object({
  dimension: z.literal('utm_medium'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const utmCampaignQuery = z.object({
  dimension: z.literal('utm_campaign'),
  operator: z.union([z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
})

const frequencyQuery = z.object({
  dimension: z.literal('frequency'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
})

/* query item form */
export const queryItem = z.union([
  orderDateQuery,
  productNameQuery,
  productTypeQuery,
  productTagQuery,
  productVendorQuery,
  orderTagQuery,
  couponQuery,
  channelQuery,
  countryQuery,
  referrerQuery,
  utmSourceQuery,
  utmMediumQuery,
  utmCampaignQuery,
  frequencyQuery,
])

export const querySet = z.object({
  join_operator: z.union([z.literal('and'), z.literal('or')]),
  query_items: queryItem.array(),
})

export type QuerySetApiState = z.infer<typeof querySet>
export type QueryItemApiState = z.infer<typeof queryItem>
