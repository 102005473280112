import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { zodResolver } from '@hookform/resolvers/zod'
import { LinearProgress } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { CircleCheckBig, Info, Loader2 } from 'lucide-react'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { z } from 'zod'

export const DataSyncProcessing: FC = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const [submitted, setSubmitted] = useState(false)

  const referrerOptions = ['store', 'ecpower', 'partner', 'google', 'social', 'article', 'ad'].map((context) =>
    t('features.dataSyncProcessing.referrer', { context })
  )

  const roleOptions = ['owner', 'analyst', 'marketing', 'support', 'product', 'admin', 'partner', 'others'].map((context) =>
    t('features.dataSyncProcessing.role', { context })
  )

  const purposeOptions = ['organize', 'track', 'insights', 'journeys', 'optimize', 'integrate', 'retention', 'other'].map((context) =>
    t('features.dataSyncProcessing.purpose', { context })
  )

  const surveyInput = z.object({
    referrer: z.string().min(1),
    role: z.string().min(1),
    purpose: z.string().min(1),
  })

  type InputSchema = z.infer<typeof surveyInput>

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(surveyInput),
    defaultValues: {
      referrer: '',
      role: '',
      purpose: '',
    },
  })

  const isFormComplete = watch('referrer') && watch('role') && watch('purpose')

  const handleSurvey = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.submitSurveyAnswers(
        {
          referrer: input.referrer,
          role: input.role,
          purpose: input.purpose,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.dataSyncProcessing.messageSaved'), { severity: 'success' })
      setSubmitted(true)
    } catch (err) {
      enqueueSnackbar(t('features.dataSyncProcessing.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <div className='container mx-auto flex items-center h-screen'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-12 w-full items-center'>
        <div className='flex flex-col col-span-1 gap-6 px-8'>
          <h1 className='text-xl font-bold'>{t('features.dataSyncProcessing.title')}</h1>
          <LinearProgress className='rounded' />
          <p className='text-sm'>
            <Trans i18nKey='features.dataSyncProcessing.description' />
          </p>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center gap-2 text-sm'>
              <Info className='w-4 h-4' />
              {t('features.dataSyncProcessing.chat')}
              <a
                className='underline underline-offset-4 cursor-pointer'
                onClick={() => {
                  if (window.ChannelIO) {
                    window.ChannelIO('show') // Open ChannelTalk
                  }
                }}
              >
                {t('features.dataSyncProcessing.chatLink')}
              </a>
            </div>
          </div>
        </div>
        <div className='col-span-1 px-8'>
          <Card>
            <CardHeader>
              <CardTitle>{t('features.dataSyncProcessing.surveyTitle')}</CardTitle>
              <CardDescription>{t('features.dataSyncProcessing.surveyDesc')}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className='flex flex-col gap-6'>
                <Controller
                  control={control}
                  name='referrer'
                  render={({ field, fieldState: { error } }) => (
                    <div className='flex flex-col gap-2'>
                      <Label htmlFor='email'>{t('features.dataSyncProcessing.referrer')}</Label>
                      <Select value={field.value} onValueChange={(value) => setValue(field.name, value)} disabled={submitted}>
                        <SelectTrigger className='w-full'>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {referrerOptions.map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {error && <p className='text-red-500 text-xs'>{error.message}</p>}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name='role'
                  render={({ field, fieldState: { error } }) => (
                    <div className='flex flex-col gap-2'>
                      <Label htmlFor='email'>{t('features.dataSyncProcessing.role')}</Label>
                      <Select value={field.value} onValueChange={(value) => setValue(field.name, value)} disabled={submitted}>
                        <SelectTrigger className='w-full'>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {roleOptions.map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {error && <p className='text-red-500 text-xs'>{error.message}</p>}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name='purpose'
                  render={({ field, fieldState: { error } }) => (
                    <div className='flex flex-col gap-2'>
                      <Label htmlFor='email'>{t('features.dataSyncProcessing.purpose')}</Label>
                      <Select value={field.value} onValueChange={(value) => setValue(field.name, value)} disabled={submitted}>
                        <SelectTrigger className='w-full'>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {purposeOptions.map((option) => (
                            <SelectItem key={option} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {error && <p className='text-red-500 text-xs'>{error.message}</p>}
                    </div>
                  )}
                />
                {!submitted ? (
                  <Button type='submit' className='w-full' onClick={handleSubmit(handleSurvey)} disabled={!isFormComplete || isSubmitting}>
                    {isSubmitting ? <Loader2 className='animate-spin' /> : t('features.dataSyncProcessing.submit')}
                  </Button>
                ) : (
                  <div className='flex justify-center items-center gap-2'>
                    <CircleCheckBig className='w-4 h-4 text-green-700' />
                    <p className='text-sm'>{t('features.dataSyncProcessing.messageSubmitted')}</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
