import { auth } from 'config/firebaseClient'
import { onAuthStateChanged, User } from 'firebase/auth'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { createContext, useContext, useEffect, useState } from 'react'

const AuthContext = createContext<User | undefined>(undefined)
const LoadingContext = createContext<boolean>(true)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const { notifySentry } = useSentryNotifier()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setUser(user || undefined)
        setIsLoading(false)
      },
      (err) => {
        setUser(undefined)
        setIsLoading(false)
        notifySentry(err)
      }
    )
    return unsubscribe
  }, [notifySentry])

  return (
    <AuthContext.Provider value={user}>
      <LoadingContext.Provider value={isLoading}>{children}</LoadingContext.Provider>
    </AuthContext.Provider>
  )
}

export const useSubscribeAuth = (): { loading: boolean } => {
  return { loading: useContext(LoadingContext) }
}

export const useAuthUser = (): User | undefined => {
  return useContext(AuthContext)
}
