import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Skeleton, SwipeableDrawer } from '@mui/material'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { OrderService } from 'gen/proto/order/order_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCurrency } from 'hooks/useCurrency'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'utils/timeUtil'

type Props = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  orderProps:
    | {
        id: string
        name: string
      }
    | undefined
}

export type Order = {
  id: string
  name: string
  orderValue: number
  orderedAt: string
  products: string[]
  tags: string[]
  referrer: string
  landingPage: string
  channel: string
  coupon: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
}

export const OrderDrawer: FC<Props> = ({ open, handleOpen, handleClose, orderProps }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const orderService = useGrpcClient(OrderService)

  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    if (!orderProps) {
      setOrder(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await orderService.get({ id: orderProps.id }, { headers: { Authorization: `Bearer ${token}` } })
        setOrder({
          id: resp.id,
          name: resp.name,
          orderValue: Number(resp.orderValue),
          orderedAt: formatDateTime(dayjs(resp.orderedAt), i18n.language),
          products: resp.products.filter((str) => str !== ''),
          tags: resp.tags.filter((str) => str !== ''),
          referrer: resp.referrer,
          landingPage: resp.landingPage,
          channel: resp.channel,
          coupon: resp.coupon,
          utmSource: resp.utmSource,
          utmMedium: resp.utmMedium,
          utmCampaign: resp.utmCampaign,
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProps])

  return (
    <SwipeableDrawer open={open} onOpen={handleOpen} onClose={handleClose} anchor='right'>
      <div className='w-[400px]'>
        <div className='flex justify-between items-center p-4'>
          <p className='text-sm font-bold'>{t('features.orders.orderDrawer.title')}</p>
          <Button variant='ghost' size='icon' onClick={handleClose}>
            <X />
          </Button>
        </div>

        <Separator />

        <div className='flex flex-col gap-4 p-4'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('features.orders.name')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.name || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.amount')}</TableCell>
                <TableCell className='text-right'>{order ? formatCurrency(order.orderValue) : <Skeleton className='w-[120px] ml-auto' />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.datetime')}</TableCell>
                <TableCell className='text-right'>
                  {order ? formatDateTime(dayjs(order.orderedAt), i18n.language) : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.referrer')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.referrer || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.channel')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.channel || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.coupon')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.coupon || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.utmSource')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.utmSource || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.utmMedium')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.utmMedium || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.utmCampaign')}</TableCell>
                <TableCell className='text-right'>
                  {order ? order.utmCampaign || t('features.orders.none') : <Skeleton className='w-[120px] ml-auto' />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.products')}</TableCell>
                <TableCell className='text-right'>
                  {order ? (
                    order.products.length === 0 ? (
                      t('features.orders.none')
                    ) : (
                      <div className='flex flex-col items-end gap-2'>
                        {order.products.map((product) => (
                          <Badge key={product} variant='secondary' className='w-fit'>
                            {product}
                          </Badge>
                        ))}
                      </div>
                    )
                  ) : (
                    <Skeleton className='w-[120px] ml-auto' />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('features.orders.tags')}</TableCell>
                <TableCell className='text-right'>
                  {order ? (
                    order.tags.length === 0 ? (
                      t('features.orders.none')
                    ) : (
                      <div className='flex flex-col items-end gap-2'>
                        {order.tags.map((tag) => (
                          <Badge key={tag} variant='outline' className='w-fit'>
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    )
                  ) : (
                    <Skeleton className='w-[120px] ml-auto' />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </SwipeableDrawer>
  )
}
