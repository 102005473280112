import { EmptyState } from '@/components/emptyState'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { SkeletonTable } from '@/components/skeletonTable'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { SegmentGroup } from '@/gen/firestore'
import { SegmentGroupService } from '@/gen/proto/segment_group/segment_group_pb'
import { useAccount } from '@/hooks/useAccount'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from '@/utils/iconUtil'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import {
  ChartCandlestick,
  ChevronDown,
  CopyMinus,
  Ellipsis,
  FolderOpen,
  Pencil,
  Plus,
  Settings,
  Square,
  SquareArrowOutUpRight,
  SquareCheckBig,
  Trash,
} from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import { AddSegmentDialog } from './components/addSegmentDialog'
import { CreateGroupDialog } from './components/createGroupDialog'
import { DeleteDialog } from './components/deleteDialog'
import { DeleteGroupDialog } from './components/deleteGroupDialog'
import { UpdateDialog } from './components/updateDialog'
import { UpdateGroupDialog } from './components/updateGroupDialog'
import { MAX_SELECTABLE_SEGMENTS } from './const'

type DialogState = {
  open: boolean
  id: string
  name: string
  description?: string
  tagOperationSettingIsActive?: boolean
}

type AddSegmentDialogState = {
  open: boolean
  group: SegmentGroup | undefined
}

const initialDialogState: DialogState = {
  open: false,
  id: '',
  name: '',
}

const initialAddSegmentDialogState: AddSegmentDialogState = {
  open: false,
  group: undefined,
}

export const CustomerSegments = () => {
  const { t } = useTranslation()
  const { account } = useAccount()
  const navigate = useNavigate()

  const segmentGroupService = useGrpcClient(SegmentGroupService)
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const { customerSegmentsRef, segmentGroupsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: segments, loading: segmentsLoading } = useCollectionSubscription(query(customerSegmentsRef, orderBy('createdAt', 'desc')))
  const { data: groups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const searchParams = new URLSearchParams(location.search)
  const groupId = searchParams.get('group') as string | undefined
  const [group, setGroup] = useState<SegmentGroup | undefined>(undefined)

  useEffect(() => {
    if (!groups || !groupId) return
    const selectedGroup = groups.find((g) => g.ref.id === groupId)
    setGroup(selectedGroup)
  }, [groupId, groups])

  const [searchText, setSearchText] = useState<string>('')
  const [selectedSegmentIds, setSelectedSegmentIds] = useState<string[]>([])
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
  const [updateDialogState, setUpdateDialogState] = useState<DialogState>(initialDialogState)
  const [deleteDialogState, setDeleteDialogState] = useState<DialogState>(initialDialogState)
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState<boolean>(false)
  const [updateGroupDialogState, setUpdateGroupDialogState] = useState<DialogState>(initialDialogState)
  const [deleteGroupDialogState, setDeleteGroupDialogState] = useState<DialogState>(initialDialogState)
  const [addSegmentDialogState, setAddSegmentDialogState] = useState<AddSegmentDialogState>(initialAddSegmentDialogState)

  const filteredSegments = useMemo(() => {
    if (!segments) return undefined
    return segments.filter((segment) => {
      const matchesGroup = group
        ? groups?.some((g) => g.ref.id === group.ref.id && g.customerSegmentRefs?.some((ref) => ref.id === segment.ref.id))
        : true
      const matchesSearchText = segment.name.toLowerCase().includes(searchText.toLowerCase())
      return matchesGroup && matchesSearchText
    })
  }, [segments, groups, group, searchText])

  const handleGroupSelect = (value: string | null) => {
    const selectedGroup = groups && groups.find((g) => g.name === value)
    setGroup(selectedGroup)
    if (selectedGroup) {
      navigate(`?group=${selectedGroup.ref.id}`)
    } else {
      navigate(location.pathname)
    }
  }

  const removeSegmentFromGroup = async (customerSegmentId: string) => {
    if (!group) return
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.removeSegments(
        { segmentGroupId: group.ref.id, customerSegmentIds: [customerSegmentId] },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.messageRemoveFromGroupSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.messageRemoveFromGroupError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  const handleCheckboxChange = (checked: string | boolean, segmentId: string) => {
    if (checked && selectedSegmentIds.length < MAX_SELECTABLE_SEGMENTS) {
      setSelectedSegmentIds([...selectedSegmentIds, segmentId])
    } else {
      setSelectedSegmentIds(selectedSegmentIds.filter((id) => id !== segmentId))
    }
  }

  const handleSelectAll = () => {
    const segmentIds = filteredSegments?.slice(0, MAX_SELECTABLE_SEGMENTS).map((segment) => segment.ref.id) || []
    setSelectedSegmentIds(isAllSelected ? [] : segmentIds)
    setIsAllSelected((prevState) => !prevState)
  }

  return (
    <>
      <PageHeader
        title={t('features.customerSegments.title')}
        menuComponent={
          <div className='flex items-center gap-2'>
            {selectedSegmentIds.length > 0 && (
              <Button
                size='sm'
                variant='outline'
                onClick={() => {
                  const params = new URLSearchParams({ ids: selectedSegmentIds.join(',') })
                  navigate(`${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_COMPARE}?${params.toString()}`)
                }}
              >
                <ChartCandlestick className='h-4 w-4 mr-2' />
                {t('features.customerSegments.compareMetrics')}
              </Button>
            )}
            <Button size='sm' onClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS)}>
              {t('features.customerSegments.create')}
            </Button>
          </div>
        }
      />

      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-3'>
              <TooltipIconButton
                icon={isAllSelected ? SquareCheckBig : Square}
                tooltipContent={t(isAllSelected ? 'features.customerSegments.deselectAll' : 'features.customerSegments.selectAll')}
                variant='outline'
                disabled={!filteredSegments || filteredSegments.length === 0}
                onClick={handleSelectAll}
              />
              <Input
                className='w-[240px]'
                value={searchText}
                placeholder={t('features.customerSegments.search')}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant='outline'>
                    {!group?.name && <FolderOpen className='h-4 w-4' />}
                    {group?.name || t('features.customerSegments.group')}
                    <ChevronDown className='text-muted-foreground ml-4 h-3 w-3' />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {groups?.map((group) => (
                    <DropdownMenuItem key={group.ref.id} onClick={() => handleGroupSelect(group.name)}>
                      {group.name}
                    </DropdownMenuItem>
                  ))}
                  {groups && groups.length > 0 && <DropdownMenuSeparator />}
                  <DropdownMenuItem onClick={() => setCreateGroupDialogOpen(true)}>{t('features.customerSegments.createGroup')}</DropdownMenuItem>
                  {group && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => handleGroupSelect(null)}>{t('features.customerSegments.clear')}</DropdownMenuItem>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {group && (
              <div className='flex items-center gap-1'>
                <TooltipIconButton
                  icon={Plus}
                  tooltipContent={t('features.customerSegments.addSegmentsToGroup')}
                  variant='ghost'
                  onClick={() => setAddSegmentDialogState({ open: true, group: group })}
                />
                <TooltipIconButton
                  icon={Pencil}
                  tooltipContent={t('features.customerSegments.editGroup')}
                  variant='ghost'
                  onClick={() => setUpdateGroupDialogState({ open: true, id: group.ref.id, name: group.name })}
                />
                <TooltipIconButton
                  icon={Trash}
                  tooltipContent={t('features.customerSegments.deleteGroup')}
                  variant='ghost'
                  onClick={() => setDeleteGroupDialogState({ open: true, id: group.ref.id, name: group.name })}
                />
              </div>
            )}
          </div>

          {segmentsLoading || !filteredSegments ? (
            <SkeletonTable columnCount={10} rowCount={3} />
          ) : filteredSegments.length === 0 ? (
            <div className='flex justify-center items-center h-[calc(100vh-120px)]'>
              {searchText.length > 0 ? (
                <EmptyState
                  title={t('features.customerSegments.empty_search', { input: searchText })}
                  buttonText={t('features.customerSegments.clear')}
                  buttonOnClick={() => setSearchText('')}
                />
              ) : (
                <EmptyState
                  title={t('features.customerSegments.empty_segments')}
                  buttonText={t('features.customerSegments.create')}
                  buttonOnClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS)}
                />
              )}
            </div>
          ) : (
            <Table>
              <TableBody>
                {filteredSegments.map((customerSegment) => (
                  <TableRow key={customerSegment.ref.id}>
                    <TableCell
                      className='w-10 cursor-pointer'
                      onClick={() => handleCheckboxChange(!selectedSegmentIds.includes(customerSegment.ref.id), customerSegment.ref.id)}
                    >
                      <Checkbox
                        checked={selectedSegmentIds.includes(customerSegment.ref.id)}
                        onCheckedChange={(checked) => handleCheckboxChange(checked, customerSegment.ref.id)}
                      />
                    </TableCell>
                    <TableCell
                      className='cursor-pointer'
                      onClick={() => {
                        const to = `${AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS}?${createSearchParams({
                          querySet: encodeURIComponent(customerSegment.querySet),
                        }).toString()}`
                        navigate(to)
                      }}
                    >
                      <div className='flex items-center gap-8'>
                        <div className='flex items-center gap-2'>
                          <span className='text-lg'>{extractIconFromTextWithIcon(customerSegment.name)}</span>
                          <span className='text-sm'>{extractTextFromTextWithIcon(customerSegment.name)}</span>
                        </div>
                        <p className='text-muted-foreground text-sm'>{customerSegment.description}</p>
                      </div>
                    </TableCell>
                    <TableCell className='text-right'>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <TooltipIconButton icon={Ellipsis} tooltipContent={t('features.customerSegments.menu')} variant='ghost' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            className='cursor-pointer'
                            onClick={() => {
                              const to = `${AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS}?${createSearchParams({
                                querySet: encodeURIComponent(customerSegment.querySet),
                              }).toString()}`
                              window.open(to, '_blank')
                            }}
                          >
                            <SquareArrowOutUpRight />
                            {t('features.customerSegments.view')}
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            className='cursor-pointer'
                            onClick={() => navigate(generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: customerSegment.ref.id }))}
                          >
                            <Settings />
                            {t('features.customerSegments.settings')}
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          {group ? (
                            <DropdownMenuItem className='cursor-pointer' onClick={() => removeSegmentFromGroup(customerSegment.ref.id)}>
                              <CopyMinus />
                              {t('features.customerSegments.removeFromGroup')}
                            </DropdownMenuItem>
                          ) : (
                            <>
                              <DropdownMenuItem
                                className='cursor-pointer'
                                onClick={() =>
                                  setUpdateDialogState({
                                    open: true,
                                    id: customerSegment.ref.id,
                                    name: customerSegment.name,
                                    description: customerSegment.description,
                                  })
                                }
                              >
                                <Pencil />
                                {t('features.customerSegments.edit')}
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                className='cursor-pointer'
                                onClick={() =>
                                  setDeleteDialogState({
                                    open: true,
                                    id: customerSegment.ref.id,
                                    name: customerSegment.name,
                                    tagOperationSettingIsActive: customerSegment.tagOperationSetting.isActive,
                                  })
                                }
                              >
                                <Trash />
                                {t('features.customerSegments.delete')}
                              </DropdownMenuItem>
                            </>
                          )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </PageContentWrapper>

      {updateDialogState.open && (
        <UpdateDialog
          open={updateDialogState.open}
          handleClose={() => setUpdateDialogState(initialDialogState)}
          id={updateDialogState.id}
          name={updateDialogState.name}
          description={updateDialogState.description || ''}
        />
      )}

      {deleteDialogState.open && (
        <DeleteDialog
          open={deleteDialogState.open}
          handleClose={() => setDeleteDialogState(initialDialogState)}
          id={deleteDialogState.id}
          name={deleteDialogState.name}
          tagOperationSettingIsActive={deleteDialogState.tagOperationSettingIsActive || false}
        />
      )}

      {createGroupDialogOpen && <CreateGroupDialog open={createGroupDialogOpen} handleClose={() => setCreateGroupDialogOpen(false)} />}

      {updateGroupDialogState.open && (
        <UpdateGroupDialog
          open={updateGroupDialogState.open}
          handleClose={() => setUpdateGroupDialogState(initialDialogState)}
          id={updateGroupDialogState.id}
          name={updateGroupDialogState.name}
        />
      )}

      {deleteGroupDialogState.open && (
        <DeleteGroupDialog
          open={deleteGroupDialogState.open}
          handleClose={() => setDeleteGroupDialogState(initialDialogState)}
          id={deleteGroupDialogState.id}
          name={deleteGroupDialogState.name}
        />
      )}

      {addSegmentDialogState.open && addSegmentDialogState.group && (
        <AddSegmentDialog
          open={addSegmentDialogState.open}
          handleClose={() => setAddSegmentDialogState(initialAddSegmentDialogState)}
          group={addSegmentDialogState.group}
        />
      )}
    </>
  )
}
