import { ProductDimension } from '@/gen/firestore'
import { ProductAnalysisService } from '@/gen/proto/product_analysis/product_analysis_pb'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductAffinityType } from '../types'

type Props = {
  dimension: ProductDimension
  value: string
  startDate: string
  endDate: string
  type: ProductAffinityType
}

export const useAffinityProductsDownload = ({ dimension, value, startDate, endDate, type }: Props) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const authUser = useAuthUser()
  const productAnalysisService = useGrpcClient(ProductAnalysisService)

  const [loading, setLoading] = useState(false)

  // If startDate is empty, set it to 10 years ago
  const currentDate = useCurrentDate(shop.timezone)
  if (startDate === '') {
    startDate = currentDate.subtract(10, 'year').format('YYYY-MM-DD')
  }

  const headers = useMemo(
    () => [t('features.productAnalysis.name'), t('features.productAnalysis.orderCount'), t('features.productAnalysis.avgDaysBetweenOrders')],
    [t]
  )

  const fetchAffinityProducts = useCallback(async () => {
    try {
      const token = await getIdToken(authUser!)
      let resp

      switch (type) {
        case ProductAffinityType.sameOrder:
          resp = await productAnalysisService.getAffinityProductsSameOrder(
            { dimension, value, startDate, endDate },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          break
        case ProductAffinityType.previousOrder:
          resp = await productAnalysisService.getAffinityProductsPreviousOrder(
            { dimension, value, startDate, endDate },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          break
        case ProductAffinityType.nextOrder:
          resp = await productAnalysisService.getAffinityProductsNextOrder(
            { dimension, value, startDate, endDate },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          break
        default:
          throw new Error('Invalid Product Affinity Type')
      }

      return (
        resp?.values?.map((o: { name: string; orderCount: bigint; avgDaysBetweenOrders: bigint }) => ({
          name: o.name,
          orderCount: Number(o.orderCount),
          avgDaysBetweenOrders: Number(o.avgDaysBetweenOrders),
        })) || []
      )
    } catch (error) {
      notifySentry(error)
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      return []
    }
  }, [dimension, value, startDate, endDate, type, authUser, productAnalysisService, notifySentry, enqueueSnackbar, t])

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true)
      const affinityProducts = await fetchAffinityProducts()

      // Create CSV content
      const csvContent = [
        headers.join(','), // Add headers
        ...affinityProducts.map((affinityProduct: { name: string; orderCount: number; avgDaysBetweenOrders: number }) =>
          [affinityProduct.name, affinityProduct.orderCount, affinityProduct.avgDaysBetweenOrders].join(',')
        ),
      ].join('\n')

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${value}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch('DownloadCSV', { name: 'productAnalysis' })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }, [fetchAffinityProducts, headers, value, dispatch, enqueueSnackbar, notifySentry, t])

  return {
    handleDownload,
    loading,
  }
}
