// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/data_loader/data_loader.proto (package gdpr, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/data_loader/data_loader.proto.
 */
export const file_proto_data_loader_data_loader: GenFile = /*@__PURE__*/
  fileDesc("CiNwcm90by9kYXRhX2xvYWRlci9kYXRhX2xvYWRlci5wcm90bxIEZ2RwciIdChtTdGFydExvYWRGcm9tU2hvcGlmeVJlcXVlc3QiHgocU3RhcnRMb2FkRnJvbVNob3BpZnlSZXNwb25zZTJ0ChFEYXRhTG9hZGVyU2VydmljZRJfChRTdGFydExvYWRGcm9tU2hvcGlmeRIhLmdkcHIuU3RhcnRMb2FkRnJvbVNob3BpZnlSZXF1ZXN0GiIuZ2Rwci5TdGFydExvYWRGcm9tU2hvcGlmeVJlc3BvbnNlIgBCOFo2ZWNwb3dlci5pby9hcGktc2VydmVyL3Byb3RvL2RhdGFfbG9hZGVyO2RhdGFfbG9hZGVyX3BiYgZwcm90bzM");

/**
 * RequestCustomerData
 *
 * @generated from message gdpr.StartLoadFromShopifyRequest
 */
export type StartLoadFromShopifyRequest = Message<"gdpr.StartLoadFromShopifyRequest"> & {
};

/**
 * Describes the message gdpr.StartLoadFromShopifyRequest.
 * Use `create(StartLoadFromShopifyRequestSchema)` to create a new message.
 */
export const StartLoadFromShopifyRequestSchema: GenMessage<StartLoadFromShopifyRequest> = /*@__PURE__*/
  messageDesc(file_proto_data_loader_data_loader, 0);

/**
 * @generated from message gdpr.StartLoadFromShopifyResponse
 */
export type StartLoadFromShopifyResponse = Message<"gdpr.StartLoadFromShopifyResponse"> & {
};

/**
 * Describes the message gdpr.StartLoadFromShopifyResponse.
 * Use `create(StartLoadFromShopifyResponseSchema)` to create a new message.
 */
export const StartLoadFromShopifyResponseSchema: GenMessage<StartLoadFromShopifyResponse> = /*@__PURE__*/
  messageDesc(file_proto_data_loader_data_loader, 1);

/**
 * @generated from service gdpr.DataLoaderService
 */
export const DataLoaderService: GenService<{
  /**
   * @generated from rpc gdpr.DataLoaderService.StartLoadFromShopify
   */
  startLoadFromShopify: {
    methodKind: "unary";
    input: typeof StartLoadFromShopifyRequestSchema;
    output: typeof StartLoadFromShopifyResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_data_loader_data_loader, 0);

