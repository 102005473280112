import { billingStatus, ORDER_INSIGHT_LIMIT_FREE } from '@/config/plan'
import { OrderInsightDimension, ShopBillingStatus } from '@/gen/firestore'
import { OrderAnalysisService } from '@/gen/proto/order_analysis/order_analysis_pb'
import { useAccount } from '@/hooks/useAccount'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderInsightValue } from '../types'

export const useInsights = (querySetString: string, dimension: OrderInsightDimension, limit: number) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)

  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const orderAnalysisService = useGrpcClient(OrderAnalysisService)
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  const [insights, setInsights] = useState<OrderInsightValue[]>()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await orderAnalysisService.getInsights(
          { querySet: querySetString, dimension: dimension, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const insights = resp.values
          .filter((insight) => insight.name.length > 0)
          .map((insight, index) => {
            return {
              name: insight.name,
              orderAmount: Number(insight.amount),
              orderCount: Number(insight.orderCount),
              customerCount: Number(insight.customerCount),
              avgOrderValue: Number(insight.avgOrderValue),
              visible: shopBillingStatus !== ShopBillingStatus.free || index + 1 <= ORDER_INSIGHT_LIMIT_FREE,
            }
          })
        setInsights(insights)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString, dimension])

  return { insights, loading }
}
