import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Switch } from '@/components/ui/switch'
import { useTranslation } from 'react-i18next'
import { defaultFiltersByDimension, filterTypesBoolMapByDimension } from '../defalutValues'
import { OP_NONE } from '../schema/const'
import { FilterItemFormState, QuerySetFormState } from '../schema/querySetFormSchema'
import { OrderCountFilter } from './orderCountFilter'
import { OrderDateFilter } from './orderDateFilter'
import { OrderSequenceFilter } from './orderSequenceFilter'

type Props = {
  queryItemPath: `query_subsets.${number}.query_items.${number}`
  open: boolean
}

export const FilterItems: FC<Props> = ({ queryItemPath, open: _open }) => {
  const { t } = useTranslation()
  const { getValues, watch, setValue } = useFormContext<QuerySetFormState>()
  const dimension = watch(`${queryItemPath}.dimension`)
  const filters = getValues(`${queryItemPath}.filters`)
  const filterTypesBoolMap = filterTypesBoolMapByDimension[dimension]

  const filterOrderDate = filterTypesBoolMap['order_date']
  const filterOrderSequence = filterTypesBoolMap['order_sequence']
  const filterOrderCount = filterTypesBoolMap['order_count']

  const isFilterDefaultOpen = filters.some((f) => f.operator !== OP_NONE)
  const [open, setOpen] = useState(isFilterDefaultOpen)

  const pathIndexByFilterType = filters.reduce<{ [key in FilterItemFormState['filter_type']]: number }>(
    (acc, _v, i) => {
      return { ...acc, [_v.filter_type]: i }
    },
    {} as { [key in FilterItemFormState['filter_type']]: number }
  )

  const generateFilterPath = (count: number): `query_subsets.${number}.query_items.${number}.filters.${number}` => {
    return `${queryItemPath}.filters.${count}`
  }

  const toggleFilters = () => {
    if (open === true) {
      // Reset form values to default when closing the filter
      setValue(`${queryItemPath}.filters`, defaultFiltersByDimension[dimension])
    }
    setOpen(!open)
  }

  return (
    <>
      {(filterOrderDate || filterOrderSequence || filterOrderCount) && (
        <div className='flex items-center gap-3'>
          <span className='text-xs'>{t('features.customerAnalysis.querySet.filters')}</span>
          <Switch checked={open} onCheckedChange={toggleFilters} />
        </div>
      )}

      {open && (
        <div className='ml-8 pl-6 border-l-[3px] border-gray-300 flex flex-col gap-2'>
          {filterOrderDate && <OrderDateFilter filterPath={generateFilterPath(pathIndexByFilterType['order_date'])} />}
          {filterOrderSequence && <OrderSequenceFilter filterPath={generateFilterPath(pathIndexByFilterType['order_sequence'])} />}
          {filterOrderCount && <OrderCountFilter filterPath={generateFilterPath(pathIndexByFilterType['order_count'])} />}
        </div>
      )}
    </>
  )
}
