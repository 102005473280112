// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/billing/billing.proto (package billing, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/billing/billing.proto.
 */
export const file_proto_billing_billing: GenFile = /*@__PURE__*/
  fileDesc("Chtwcm90by9iaWxsaW5nL2JpbGxpbmcucHJvdG8SB2JpbGxpbmciPQoaU3RhcnRDaGFuZ2VQbGFuRmxvd1JlcXVlc3QSDAoEcGxhbhgBIAEoCRIRCglyZXR1cm5VUkwYAiABKAkiMQobU3RhcnRDaGFuZ2VQbGFuRmxvd1Jlc3BvbnNlEhIKCmNvbmZpcm1VUkwYASABKAkiIgogU3luY0FjdGl2ZVBsYW5Gcm9tU2hvcGlmeVJlcXVlc3QiIwohU3luY0FjdGl2ZVBsYW5Gcm9tU2hvcGlmeVJlc3BvbnNlMuoBCg5CaWxsaW5nU2VydmljZRJiChNTdGFydENoYW5nZVBsYW5GbG93EiMuYmlsbGluZy5TdGFydENoYW5nZVBsYW5GbG93UmVxdWVzdBokLmJpbGxpbmcuU3RhcnRDaGFuZ2VQbGFuRmxvd1Jlc3BvbnNlIgASdAoZU3luY0FjdGl2ZVBsYW5Gcm9tU2hvcGlmeRIpLmJpbGxpbmcuU3luY0FjdGl2ZVBsYW5Gcm9tU2hvcGlmeVJlcXVlc3QaKi5iaWxsaW5nLlN5bmNBY3RpdmVQbGFuRnJvbVNob3BpZnlSZXNwb25zZSIAQjBaLmVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9iaWxsaW5nO2JpbGxpbmdfcGJiBnByb3RvMw");

/**
 * StartChangePlanFlow
 *
 * @generated from message billing.StartChangePlanFlowRequest
 */
export type StartChangePlanFlowRequest = Message<"billing.StartChangePlanFlowRequest"> & {
  /**
   * free, starter, growth, scale
   *
   * @generated from field: string plan = 1;
   */
  plan: string;

  /**
   * where to redirect after the flow is done
   *
   * @generated from field: string returnURL = 2;
   */
  returnURL: string;
};

/**
 * Describes the message billing.StartChangePlanFlowRequest.
 * Use `create(StartChangePlanFlowRequestSchema)` to create a new message.
 */
export const StartChangePlanFlowRequestSchema: GenMessage<StartChangePlanFlowRequest> = /*@__PURE__*/
  messageDesc(file_proto_billing_billing, 0);

/**
 * @generated from message billing.StartChangePlanFlowResponse
 */
export type StartChangePlanFlowResponse = Message<"billing.StartChangePlanFlowResponse"> & {
  /**
   * confirmURL is the URL issued by Shopify, required to complete the payment.
   * If changing to the free plan, authentication is not needed, therefore an empty string is returned.
   *
   * @generated from field: string confirmURL = 1;
   */
  confirmURL: string;
};

/**
 * Describes the message billing.StartChangePlanFlowResponse.
 * Use `create(StartChangePlanFlowResponseSchema)` to create a new message.
 */
export const StartChangePlanFlowResponseSchema: GenMessage<StartChangePlanFlowResponse> = /*@__PURE__*/
  messageDesc(file_proto_billing_billing, 1);

/**
 * SyncActivePlanFromShopify
 *
 * @generated from message billing.SyncActivePlanFromShopifyRequest
 */
export type SyncActivePlanFromShopifyRequest = Message<"billing.SyncActivePlanFromShopifyRequest"> & {
};

/**
 * Describes the message billing.SyncActivePlanFromShopifyRequest.
 * Use `create(SyncActivePlanFromShopifyRequestSchema)` to create a new message.
 */
export const SyncActivePlanFromShopifyRequestSchema: GenMessage<SyncActivePlanFromShopifyRequest> = /*@__PURE__*/
  messageDesc(file_proto_billing_billing, 2);

/**
 * @generated from message billing.SyncActivePlanFromShopifyResponse
 */
export type SyncActivePlanFromShopifyResponse = Message<"billing.SyncActivePlanFromShopifyResponse"> & {
};

/**
 * Describes the message billing.SyncActivePlanFromShopifyResponse.
 * Use `create(SyncActivePlanFromShopifyResponseSchema)` to create a new message.
 */
export const SyncActivePlanFromShopifyResponseSchema: GenMessage<SyncActivePlanFromShopifyResponse> = /*@__PURE__*/
  messageDesc(file_proto_billing_billing, 3);

/**
 * @generated from service billing.BillingService
 */
export const BillingService: GenService<{
  /**
   * @generated from rpc billing.BillingService.StartChangePlanFlow
   */
  startChangePlanFlow: {
    methodKind: "unary";
    input: typeof StartChangePlanFlowRequestSchema;
    output: typeof StartChangePlanFlowResponseSchema;
  },
  /**
   * @generated from rpc billing.BillingService.SyncActivePlanFromShopify
   */
  syncActivePlanFromShopify: {
    methodKind: "unary";
    input: typeof SyncActivePlanFromShopifyRequestSchema;
    output: typeof SyncActivePlanFromShopifyResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_billing_billing, 0);

