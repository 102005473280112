import { enUS, ja } from 'date-fns/locale'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { formatDate } from '@/utils/timeUtil'
import dayjs from 'dayjs'
import { CalendarIcon } from 'lucide-react'
import * as React from 'react'
import { useState } from 'react'
import { DateRange as LibraryDateRange } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Separator } from './ui/separator'

type DateRange = {
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
}

type SelectedDateRange = {
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs | undefined
}

type Props = {
  dateRangePresetOption: DateRangePresetOption
  maxEndDate: dayjs.Dayjs
  onApplyDateRangeChange?: (date: DateRange) => void
}

export enum DateRangePresetOption {
  last7Days = 'last7Days',
  last14Days = 'last14Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
  last180Days = 'last180Days',
  last365Days = 'last365Days',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

const convertDateRangeToLibraryDateRange = (dateRange: SelectedDateRange): LibraryDateRange => {
  return {
    from: toDate(dateRange.startDate),
    to: dateRange.endDate && toDate(dateRange.endDate),
  }
}

const convertLibraryDateRangeToDateRange = (dateRange: LibraryDateRange): SelectedDateRange => {
  return {
    startDate: dayjs(dateRange.from),
    endDate: dateRange.to ? dayjs(dateRange.to) : undefined,
  }
}

export const convertPresetOptionToDateRange = (option: DateRangePresetOption, maxEndDate: dayjs.Dayjs): DateRange => {
  switch (option) {
    case DateRangePresetOption.last7Days:
      return { startDate: maxEndDate.subtract(6, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.last14Days:
      return { startDate: maxEndDate.subtract(13, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.last30Days:
      return { startDate: maxEndDate.subtract(29, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.last90Days:
      return { startDate: maxEndDate.subtract(89, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.last180Days:
      return { startDate: maxEndDate.subtract(179, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.last365Days:
      return { startDate: maxEndDate.subtract(364, 'day'), endDate: maxEndDate }
    case DateRangePresetOption.thisWeek:
      return { startDate: maxEndDate.startOf('week').add(1, 'day'), endDate: maxEndDate } // Do not exceed the maxEndDate
    case DateRangePresetOption.lastWeek:
      return {
        startDate: maxEndDate.subtract(1, 'week').startOf('week').add(1, 'day'),
        endDate: maxEndDate.subtract(1, 'week').endOf('week').add(1, 'day'),
      }
    case DateRangePresetOption.thisMonth:
      return { startDate: maxEndDate.startOf('month'), endDate: maxEndDate } // Do not exceed the maxEndDate
    case DateRangePresetOption.lastMonth:
      return { startDate: maxEndDate.subtract(1, 'month').startOf('month'), endDate: maxEndDate.subtract(1, 'month').endOf('month') }
    case DateRangePresetOption.thisYear:
      return { startDate: maxEndDate.startOf('year'), endDate: maxEndDate } // Do not exceed the maxEndDate
    case DateRangePresetOption.lastYear:
      return { startDate: maxEndDate.subtract(1, 'year').startOf('year'), endDate: maxEndDate.subtract(1, 'year').endOf('year') }
    default:
      return { startDate: maxEndDate.subtract(6, 'day'), endDate: maxEndDate }
  }
}

export const DateRangePicker: React.FC<Props> = ({ maxEndDate, dateRangePresetOption, onApplyDateRangeChange }) => {
  const { t, i18n } = useTranslation()
  const defaultDateRange = convertPresetOptionToDateRange(dateRangePresetOption, maxEndDate)
  const [presetOption, setPresetOption] = useState<DateRangePresetOption>(dateRangePresetOption)
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>(defaultDateRange)
  const [appliedDateRange, setAppliedDateRange] = useState<DateRange>(defaultDateRange)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const selectDateRange = (dateRangeFromLibrary: LibraryDateRange | undefined) => {
    if (!dateRangeFromLibrary) return
    const dateRange = convertLibraryDateRangeToDateRange(dateRangeFromLibrary)
    if (dateRange.endDate && dayjs(dateRange.endDate).isAfter(dayjs(defaultDateRange.endDate))) {
      dateRange.endDate = dayjs(defaultDateRange.endDate)
    }
    setSelectedDateRange(dateRange)
  }

  const handlePresetOptionChange = (presetOption: DateRangePresetOption) => {
    setPresetOption(presetOption)
    const { startDate, endDate } = convertPresetOptionToDateRange(presetOption, dayjs(defaultDateRange.endDate))
    setSelectedDateRange({ startDate: startDate, endDate: endDate })
  }

  const applySelectedDateRange = () => {
    if (!(selectedDateRange.startDate && selectedDateRange.endDate)) {
      throw new Error('') // todo:fix
    }
    const newDateRange = {
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate,
    }
    setAppliedDateRange(newDateRange)
    if (onApplyDateRangeChange) onApplyDateRangeChange(newDateRange)
    setIsPopoverOpen(false)
  }

  const handleCalenderClose = () => {
    setSelectedDateRange(appliedDateRange) // discard selected date-range before apply
    setIsPopoverOpen(false)
  }

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          setIsPopoverOpen(true)
        } else {
          handleCalenderClose()
        }
      }}
    >
      <PopoverTrigger asChild>
        <Button id='date' variant='outline' className={cn('justify-start text-left font-normal', !appliedDateRange && 'text-muted-foreground')}>
          <CalendarIcon />
          <>
            {formatDate(dayjs(selectedDateRange.startDate), i18n.language)}
            {selectedDateRange.endDate && <>- {formatDate(dayjs(selectedDateRange.endDate), i18n.language)}</>}
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-1 max-h-[300px] overflow-y-auto m-2'>
            {Object.values(DateRangePresetOption).map((option) => (
              <Button
                key={option}
                variant='ghost'
                onClick={() => {
                  handlePresetOptionChange(option)
                }}
                className={cn('cursor-pointer', option === presetOption && 'bg-muted')}
              >
                {t(`common.dateRangePicker.customDateRange_${option}`)}
              </Button>
            ))}
          </div>
          <Calendar
            initialFocus
            mode='range'
            locale={i18n.language === 'ja' ? ja : enUS}
            defaultMonth={selectedDateRange.endDate && toDate(selectedDateRange.endDate.add(-1, 'month'))}
            selected={convertDateRangeToLibraryDateRange(selectedDateRange)}
            onSelect={selectDateRange}
            disabled={
              defaultDateRange.endDate && {
                after: toDate(defaultDateRange.endDate),
              }
            }
            numberOfMonths={2}
          />
        </div>
        <Separator />
        <div className='flex justify-end gap-2 p-2'>
          <Button variant='outline' onClick={handleCalenderClose}>
            {t('common.dateRangePicker.close')}
          </Button>
          <Button onClick={applySelectedDateRange} disabled={!(selectedDateRange.startDate && selectedDateRange.endDate)}>
            {t('common.dateRangePicker.apply')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const toDate = (dayjsDate: dayjs.Dayjs): Date => {
  return new Date(dayjsDate.format('YYYY-MM-DD'))
}
