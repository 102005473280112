import { EmptyState } from '@/components/emptyState'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { SkeletonTable } from '@/components/skeletonTable'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { AUTHORIZED_ROUTE } from '@/routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from '@/utils/iconUtil'
import { orderBy, query } from 'firebase/firestore'
import { ChartCandlestick, Ellipsis, Pencil, Square, SquareArrowOutUpRight, SquareCheckBig, Trash } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { DeleteDialog } from './components/deleteDialog'
import { UpdateDialog } from './components/updateDialog'
import { MAX_SELECTABLE_FILTERS } from './const'

type DialogState = {
  open: boolean
  id: string | undefined
  name: string | undefined
}

const initialDialogState: DialogState = {
  open: false,
  id: undefined,
  name: undefined,
}

export const OrderFilters = () => {
  const { t } = useTranslation()
  const { account } = useAccount()
  const navigate = useNavigate()

  const { orderFiltersRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: orderFilters, loading } = useCollectionSubscription(query(orderFiltersRef, orderBy('createdAt', 'desc')))

  const [searchText, setSearchText] = useState<string>('')
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
  const [selectedOrderFilterIds, setSelectedOrderFilterIds] = useState<string[]>([])
  const [updateDialogState, setUpdateDialogState] = useState<DialogState>(initialDialogState)
  const [deleteDialogState, setDeleteDialogState] = useState<DialogState>(initialDialogState)

  const filteredOrderFilters = useMemo(
    () => orderFilters?.filter((orderFilter) => orderFilter.name.toLowerCase().includes(searchText.toLowerCase())),
    [orderFilters, searchText]
  )

  const handleCheckboxChange = (checked: string | boolean, orderFilterId: string) => {
    if (checked && selectedOrderFilterIds.length < MAX_SELECTABLE_FILTERS) {
      setSelectedOrderFilterIds([...selectedOrderFilterIds, orderFilterId])
    } else {
      setSelectedOrderFilterIds(selectedOrderFilterIds.filter((id) => id !== orderFilterId))
    }
  }

  const handleSelectAll = () => {
    if (filteredOrderFilters) {
      const orderFilterIds = filteredOrderFilters?.slice(0, MAX_SELECTABLE_FILTERS).map((orderFilter) => orderFilter.ref.id) || []
      setSelectedOrderFilterIds(isAllSelected ? [] : orderFilterIds)
      setIsAllSelected((prevState) => !prevState)
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.orderFilters.title')}
        menuComponent={
          <div className='flex items-center gap-2'>
            {selectedOrderFilterIds.length > 0 && (
              <Button
                size='sm'
                variant='outline'
                onClick={() => {
                  const params = new URLSearchParams({ ids: selectedOrderFilterIds.join(',') })
                  navigate(`${AUTHORIZED_ROUTE.ORDER_FILTER_COMPARE}?${params.toString()}`)
                }}
              >
                <ChartCandlestick className='h-4 w-4 mr-2' />
                {t('features.orderFilters.compareMetrics')}
              </Button>
            )}
            <Button size='sm' onClick={() => navigate(AUTHORIZED_ROUTE.ORDER_ANALYSIS)}>
              {t('features.orderFilters.create')}
            </Button>
          </div>
        }
      />

      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <div className='flex items-center gap-3'>
            <TooltipIconButton
              icon={isAllSelected ? SquareCheckBig : Square}
              tooltipContent={t(isAllSelected ? 'features.orderFilters.deselectAll' : 'features.orderFilters.selectAll')}
              variant='outline'
              disabled={!filteredOrderFilters || filteredOrderFilters.length === 0}
              onClick={handleSelectAll}
            />
            <Input
              className='w-[240px]'
              value={searchText}
              placeholder={t('features.orderFilters.search')}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {loading || !filteredOrderFilters ? (
            <SkeletonTable columnCount={10} rowCount={3} />
          ) : filteredOrderFilters.length === 0 ? (
            <div className='flex justify-center items-center h-[calc(100vh-120px)]'>
              {searchText.length > 0 ? (
                <EmptyState
                  title={t('features.orderFilters.empty_search', { input: searchText })}
                  buttonText={t('features.orderFilters.clear')}
                  buttonOnClick={() => setSearchText('')}
                />
              ) : (
                <EmptyState
                  title={t('features.orderFilters.empty')}
                  buttonText={t('features.orderFilters.create')}
                  buttonOnClick={() => navigate(AUTHORIZED_ROUTE.ORDER_ANALYSIS)}
                />
              )}
            </div>
          ) : (
            <Table>
              <TableBody>
                {filteredOrderFilters.map((orderFilter) => (
                  <TableRow key={orderFilter.ref.id}>
                    <TableCell
                      className='w-10 cursor-pointer'
                      onClick={() => handleCheckboxChange(!selectedOrderFilterIds.includes(orderFilter.ref.id), orderFilter.ref.id)}
                    >
                      <Checkbox
                        checked={selectedOrderFilterIds.includes(orderFilter.ref.id)}
                        onCheckedChange={(checked) => handleCheckboxChange(checked, orderFilter.ref.id)}
                      />
                    </TableCell>
                    <TableCell
                      className='cursor-pointer'
                      onClick={() => {
                        const to = `${AUTHORIZED_ROUTE.ORDER_ANALYSIS}?${createSearchParams({
                          querySet: encodeURIComponent(orderFilter.querySet),
                        }).toString()}`
                        navigate(to)
                      }}
                    >
                      <div className='flex items-center gap-2'>
                        <span className='text-lg'>{extractIconFromTextWithIcon(orderFilter.name)}</span>
                        <span className='text-sm'>{extractTextFromTextWithIcon(orderFilter.name)}</span>
                      </div>
                    </TableCell>
                    <TableCell className='text-right'>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <TooltipIconButton icon={Ellipsis} tooltipContent={t('features.orderFilters.menu')} variant='ghost' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            className='cursor-pointer'
                            onClick={() => {
                              const to = `${AUTHORIZED_ROUTE.ORDER_ANALYSIS}?${createSearchParams({
                                querySet: encodeURIComponent(orderFilter.querySet),
                              }).toString()}`
                              window.open(to, '_blank')
                            }}
                          >
                            <SquareArrowOutUpRight />
                            {t('features.orderFilters.view')}
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            className='cursor-pointer'
                            onClick={() => setUpdateDialogState({ open: true, id: orderFilter.ref.id, name: orderFilter.name })}
                          >
                            <Pencil />
                            {t('features.orderFilters.edit')}
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className='cursor-pointer'
                            onClick={() => setDeleteDialogState({ open: true, id: orderFilter.ref.id, name: orderFilter.name })}
                          >
                            <Trash />
                            {t('features.orderFilters.delete')}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </PageContentWrapper>

      {updateDialogState.id && updateDialogState.name && (
        <UpdateDialog
          open={updateDialogState.open}
          handleClose={() => setUpdateDialogState(initialDialogState)}
          id={updateDialogState.id}
          name={updateDialogState.name}
        />
      )}

      {deleteDialogState.id && deleteDialogState.name && (
        <DeleteDialog
          open={deleteDialogState.open}
          handleClose={() => setDeleteDialogState(initialDialogState)}
          id={deleteDialogState.id}
          name={deleteDialogState.name}
        />
      )}
    </>
  )
}
