import { OrderAnalysisService } from '@/gen/proto/order_analysis/order_analysis_pb'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DailyOrderSummary } from '../types'

export const useDailyMetrics = (querySetString: string) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const orderAnalysisService = useGrpcClient(OrderAnalysisService)

  const [dailyMetrics, setDailyMetrics] = useState<DailyOrderSummary[]>()
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await orderAnalysisService.getDailyMetrics({ querySet: querySetString }, { headers: { Authorization: `Bearer ${token}` } })
        const dailyMetrics = resp.dailyMetrics.map((dailyMetric) => {
          return {
            date: dailyMetric.date,
            orderAmount: Number(dailyMetric.amount),
            orderCount: Number(dailyMetric.orderCount),
            avgOrderValue: Number(dailyMetric.avgOrderValue),
          }
        })
        setDailyMetrics(dailyMetrics)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return { dailyMetrics, loading }
}
