import { useRef, useState } from 'react'

/*
this hooks to manage the status of loading
when the same asynchronous process is executed consecutively.
It maintains the state of loading until all consecutive asynchronous operations have completed.

Error handling (e.g., notification to Sentry) should be performed by the caller.
For this reason, the catch clause is not used internally.

anyLoading is the state of loading of the target function.
executeRequest wraps the target asynchronous process.
*/

type LoadingExecutorResult = {
  anyLoading: boolean
  executeRequest: <T>(asyncTask: () => Promise<T>) => Promise<T>
}

export const useConcurrentRequest = (): LoadingExecutorResult => {
  const [anyLoading, setAnyLoading] = useState(false)
  const pendingCount = useRef(0) // state to manage loading status

  const executeRequest = async <T>(asyncFunc: () => Promise<T>): Promise<T> => {
    pendingCount.current += 1
    setAnyLoading(true)
    try {
      return await asyncFunc()
    } finally {
      pendingCount.current -= 1
      // When asynchronous processing occurs in succession, loading is not released until the last asynchronous processing is completed
      if (pendingCount.current === 0) {
        setAnyLoading(false)
      }
    }
  }

  return { anyLoading, executeRequest }
}
