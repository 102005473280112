import { Card } from '@/components/ui/card'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { AUTHORIZED_ROUTE } from '@/routing'
import { SearchDialog } from 'components/searchDialog'
import { useAccount } from 'hooks/useAccount'
import { Package, Search, ShoppingCart, UserRoundSearch } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const Home: FC = () => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const navigate = useNavigate()

  const timezone = shop.timezone || 'UTC'
  const currentTime = useCurrentDate(timezone)
  const hour = currentTime.hour()

  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const chipClass = 'flex items-center gap-2 text-sm bg-gray-100 cursor-pointer px-3 py-1 rounded-md hover:shadow-sm'

  return (
    <>
      <div className='flex flex-col justify-center items-center gap-8 h-screen'>
        <p className='text-2xl'>
          {hour >= 5 && hour < 12
            ? t('features.home.title_morning')
            : hour >= 12 && hour < 18
              ? t('features.home.title_afternoon')
              : t('features.home.title_evening')}
        </p>

        <Card className='w-1/2 rounded-2xl px-6 py-4 cursor-pointer hover:shadow-md' onClick={() => setOpenSearchDialog(true)}>
          <div className='flex justify-between items-center'>
            <div className='flex items-center gap-4'>
              <Search />
              <p>{t('features.home.search')}</p>
            </div>
            <p className='text-muted-foreground'>{t('features.home.shortcut')}</p>
          </div>
        </Card>

        <div className='flex items-stretch gap-4'>
          <div className={chipClass} onClick={() => navigate(AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS)}>
            <UserRoundSearch className='w-4 h-4' />
            {t('layouts.dashboardLayout.customerAnalysis')}
          </div>
          <div className={chipClass} onClick={() => navigate(AUTHORIZED_ROUTE.ORDER_ANALYSIS)}>
            <ShoppingCart className='w-4 h-4' />
            {t('layouts.dashboardLayout.orderAnalysis')}
          </div>
          <div className={chipClass} onClick={() => navigate(AUTHORIZED_ROUTE.PRODUCT_ANALYSIS)}>
            <Package className='w-4 h-4' />
            {t('layouts.dashboardLayout.productAnalysis')}
          </div>
        </div>
      </div>

      {openSearchDialog && <SearchDialog open={openSearchDialog} handleClose={() => setOpenSearchDialog(false)} />}
    </>
  )
}
