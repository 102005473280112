import { Dayjs } from 'dayjs'
import { OP_CONTAIN_ANY, OP_DATE_RANGE, OP_EQUAL } from './schema/const'
import { QueryItemFormState, QuerySetFormState } from './schema/querySetFormSchema'

export const getDefaultValues = (currentDate: Dayjs) => {
  const today = currentDate.format('YYYY-MM-DD')

  const defaultQueryItemByDimension: { [key in QueryItemFormState['dimension']]: QueryItemFormState } = {
    order_date: { dimension: 'order_date', operator: OP_DATE_RANGE, value: { min_date: null, max_date: today } },
    product_name: { dimension: 'product_name', operator: OP_CONTAIN_ANY, value: [] },
    product_type: { dimension: 'product_type', operator: OP_CONTAIN_ANY, value: [] },
    product_tag: { dimension: 'product_tag', operator: OP_CONTAIN_ANY, value: [] },
    product_vendor: { dimension: 'product_vendor', operator: OP_CONTAIN_ANY, value: [] },
    order_tag: { dimension: 'order_tag', operator: OP_CONTAIN_ANY, value: [] },
    coupon: { dimension: 'coupon', operator: OP_CONTAIN_ANY, value: [] },
    channel: { dimension: 'channel', operator: OP_CONTAIN_ANY, value: [] },
    country: { dimension: 'country', operator: OP_CONTAIN_ANY, value: [] },
    referrer: { dimension: 'referrer', operator: OP_CONTAIN_ANY, value: [] },
    utm_source: { dimension: 'utm_source', operator: OP_CONTAIN_ANY, value: [] },
    utm_medium: { dimension: 'utm_medium', operator: OP_CONTAIN_ANY, value: [] },
    utm_campaign: { dimension: 'utm_campaign', operator: OP_CONTAIN_ANY, value: [] },
    frequency: { dimension: 'frequency', operator: OP_EQUAL, value: 'new' },
  }

  const defaultQueryItem: QueryItemFormState = defaultQueryItemByDimension.order_date

  const defaultAddQueryItem: QueryItemFormState = defaultQueryItemByDimension.product_name

  const defaultQuerySet: QuerySetFormState = {
    join_operator: 'and',
    query_items: [defaultQueryItem],
  }

  return { defaultQuerySet, defaultAddQueryItem, defaultQueryItemByDimension }
}
