import { useMemo } from 'react'

import { DimensionOptions } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'

function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index
}

export type OptionsByDimension = {
  product: string[]
  productType: string[]
  productTag: string[]
  productVendor: string[]
  orderTag: string[]
  coupon: string[]
  channel: string[]
  country: string[]
  referrer: string[]
  utmSource: string[]
  utmMedium: string[]
  utmCampaign: string[]
}

export const initialOptionsByDimension: OptionsByDimension = {
  product: [],
  productType: [],
  productTag: [],
  productVendor: [],
  orderTag: [],
  coupon: [],
  channel: [],
  country: [],
  referrer: [],
  utmSource: [],
  utmMedium: [],
  utmCampaign: [],
}

export type UseDimensionOptionsReturn = {
  data: OptionsByDimension
  loading: boolean
}

export const useDimensionOptions = (): UseDimensionOptionsReturn => {
  const { account } = useAccount()
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: dimensionOptions, loading } = useCollection(dimensionOptionsRef)

  const dynamicOptionValues = useMemo(() => {
    return (
      dimensionOptions &&
      dimensionOptions.reduce(
        (acc, config) => {
          return {
            ...acc,
            [config.ref.id as string]: config,
          }
        },
        {} as { [key in string]: DimensionOptions }
      )
    )
  }, [dimensionOptions])

  const optionByDimension: OptionsByDimension = useMemo(() => {
    if (!dynamicOptionValues) return initialOptionsByDimension
    return {
      product: dynamicOptionValues['product'].options.filter(onlyUnique),
      productType: dynamicOptionValues['product_type'].options.filter(onlyUnique),
      productTag: dynamicOptionValues['product_tag'].options.filter(onlyUnique),
      productVendor: dynamicOptionValues['product_vendor'].options.filter(onlyUnique),
      orderTag: dynamicOptionValues['order_tag'].options.filter(onlyUnique),
      coupon: dynamicOptionValues['coupon'].options.filter(onlyUnique),
      channel: dynamicOptionValues['channel'].options.filter(onlyUnique),
      country: dynamicOptionValues['country'].options.filter(onlyUnique),
      referrer: dynamicOptionValues['referrer'].options.filter(onlyUnique),
      utmSource: dynamicOptionValues['utm_source'].options.filter(onlyUnique),
      utmMedium: dynamicOptionValues['utm_medium'].options.filter(onlyUnique),
      utmCampaign: dynamicOptionValues['utm_campaign'].options.filter(onlyUnique),
    }
  }, [dynamicOptionValues])

  return { data: optionByDimension, loading }
}
