// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/order_filter/order_filter.proto (package order_filter, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/order_filter/order_filter.proto.
 */
export const file_proto_order_filter_order_filter: GenFile = /*@__PURE__*/
  fileDesc("CiVwcm90by9vcmRlcl9maWx0ZXIvb3JkZXJfZmlsdGVyLnByb3RvEgxvcmRlcl9maWx0ZXIiLwoNQ3JlYXRlUmVxdWVzdBIMCgRuYW1lGAEgASgJEhAKCHF1ZXJ5U2V0GAIgASgJIicKDkNyZWF0ZVJlc3BvbnNlEhUKDW9yZGVyRmlsdGVySWQYASABKAkiNAoNVXBkYXRlUmVxdWVzdBIVCg1vcmRlckZpbHRlcklkGAEgASgJEgwKBG5hbWUYAiABKAkiEAoOVXBkYXRlUmVzcG9uc2UiJgoNRGVsZXRlUmVxdWVzdBIVCg1vcmRlckZpbHRlcklkGAEgASgJIhAKDkRlbGV0ZVJlc3BvbnNlMukBChJPcmRlckZpbHRlclNlcnZpY2USRQoGQ3JlYXRlEhsub3JkZXJfZmlsdGVyLkNyZWF0ZVJlcXVlc3QaHC5vcmRlcl9maWx0ZXIuQ3JlYXRlUmVzcG9uc2UiABJFCgZVcGRhdGUSGy5vcmRlcl9maWx0ZXIuVXBkYXRlUmVxdWVzdBocLm9yZGVyX2ZpbHRlci5VcGRhdGVSZXNwb25zZSIAEkUKBkRlbGV0ZRIbLm9yZGVyX2ZpbHRlci5EZWxldGVSZXF1ZXN0Ghwub3JkZXJfZmlsdGVyLkRlbGV0ZVJlc3BvbnNlIgBCOlo4ZWNwb3dlci5pby9hcGktc2VydmVyL3Byb3RvL29yZGVyX2ZpbHRlcjtvcmRlcl9maWx0ZXJfcGJiBnByb3RvMw");

/**
 * Create
 *
 * @generated from message order_filter.CreateRequest
 */
export type CreateRequest = Message<"order_filter.CreateRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string querySet = 2;
   */
  querySet: string;
};

/**
 * Describes the message order_filter.CreateRequest.
 * Use `create(CreateRequestSchema)` to create a new message.
 */
export const CreateRequestSchema: GenMessage<CreateRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 0);

/**
 * @generated from message order_filter.CreateResponse
 */
export type CreateResponse = Message<"order_filter.CreateResponse"> & {
  /**
   * @generated from field: string orderFilterId = 1;
   */
  orderFilterId: string;
};

/**
 * Describes the message order_filter.CreateResponse.
 * Use `create(CreateResponseSchema)` to create a new message.
 */
export const CreateResponseSchema: GenMessage<CreateResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 1);

/**
 * Update
 *
 * @generated from message order_filter.UpdateRequest
 */
export type UpdateRequest = Message<"order_filter.UpdateRequest"> & {
  /**
   * @generated from field: string orderFilterId = 1;
   */
  orderFilterId: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;
};

/**
 * Describes the message order_filter.UpdateRequest.
 * Use `create(UpdateRequestSchema)` to create a new message.
 */
export const UpdateRequestSchema: GenMessage<UpdateRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 2);

/**
 * @generated from message order_filter.UpdateResponse
 */
export type UpdateResponse = Message<"order_filter.UpdateResponse"> & {
};

/**
 * Describes the message order_filter.UpdateResponse.
 * Use `create(UpdateResponseSchema)` to create a new message.
 */
export const UpdateResponseSchema: GenMessage<UpdateResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 3);

/**
 * Delete
 *
 * @generated from message order_filter.DeleteRequest
 */
export type DeleteRequest = Message<"order_filter.DeleteRequest"> & {
  /**
   * @generated from field: string orderFilterId = 1;
   */
  orderFilterId: string;
};

/**
 * Describes the message order_filter.DeleteRequest.
 * Use `create(DeleteRequestSchema)` to create a new message.
 */
export const DeleteRequestSchema: GenMessage<DeleteRequest> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 4);

/**
 * @generated from message order_filter.DeleteResponse
 */
export type DeleteResponse = Message<"order_filter.DeleteResponse"> & {
};

/**
 * Describes the message order_filter.DeleteResponse.
 * Use `create(DeleteResponseSchema)` to create a new message.
 */
export const DeleteResponseSchema: GenMessage<DeleteResponse> = /*@__PURE__*/
  messageDesc(file_proto_order_filter_order_filter, 5);

/**
 * @generated from service order_filter.OrderFilterService
 */
export const OrderFilterService: GenService<{
  /**
   * @generated from rpc order_filter.OrderFilterService.Create
   */
  create: {
    methodKind: "unary";
    input: typeof CreateRequestSchema;
    output: typeof CreateResponseSchema;
  },
  /**
   * @generated from rpc order_filter.OrderFilterService.Update
   */
  update: {
    methodKind: "unary";
    input: typeof UpdateRequestSchema;
    output: typeof UpdateResponseSchema;
  },
  /**
   * @generated from rpc order_filter.OrderFilterService.Delete
   */
  delete: {
    methodKind: "unary";
    input: typeof DeleteRequestSchema;
    output: typeof DeleteResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_order_filter_order_filter, 0);

