// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/shop/shop.proto (package shop, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/shop/shop.proto.
 */
export const file_proto_shop_shop: GenFile = /*@__PURE__*/
  fileDesc("ChVwcm90by9zaG9wL3Nob3AucHJvdG8SBHNob3AiFAoSR2V0QWNjb3VudHNSZXF1ZXN0IqEBChNHZXRBY2NvdW50c1Jlc3BvbnNlEjMKCGFjY291bnRzGAEgAygLMiEuc2hvcC5HZXRBY2NvdW50c1Jlc3BvbnNlLkFjY291bnQaVQoHQWNjb3VudBIKCgJpZBgBIAEoCRINCgVlbWFpbBgCIAEoCRIMCgRyb2xlGAMgASgJEg4KBnN0YXR1cxgEIAEoCRIRCgljcmVhdGVkQXQYBSABKAkiJQoUSW52aXRlQWNjb3VudFJlcXVlc3QSDQoFZW1haWwYASABKAkiFwoVSW52aXRlQWNjb3VudFJlc3BvbnNlIiUKFFJlbW92ZUFjY291bnRSZXF1ZXN0Eg0KBWVtYWlsGAEgASgJIhcKFVJlbW92ZUFjY291bnRSZXNwb25zZSJNChpTdWJtaXRTdXJ2ZXlBbnN3ZXJzUmVxdWVzdBIQCghyZWZlcnJlchgBIAEoCRIMCgRyb2xlGAIgASgJEg8KB3B1cnBvc2UYAyABKAkiHQobU3VibWl0U3VydmV5QW5zd2Vyc1Jlc3BvbnNlIi4KHFN1Ym1pdERvd25ncmFkZVJlYXNvblJlcXVlc3QSDgoGcmVhc29uGAEgASgJIh8KHVN1Ym1pdERvd25ncmFkZVJlYXNvblJlc3BvbnNlIjkKHVVwZGF0ZU9yZGVyUHJpY2luZ1J1bGVSZXF1ZXN0EhgKEG9yZGVyUHJpY2luZ1J1bGUYASABKAkiIAoeVXBkYXRlT3JkZXJQcmljaW5nUnVsZVJlc3BvbnNlMpQECgtTaG9wU2VydmljZRJECgtHZXRBY2NvdW50cxIYLnNob3AuR2V0QWNjb3VudHNSZXF1ZXN0Ghkuc2hvcC5HZXRBY2NvdW50c1Jlc3BvbnNlIgASSgoNSW52aXRlQWNjb3VudBIaLnNob3AuSW52aXRlQWNjb3VudFJlcXVlc3QaGy5zaG9wLkludml0ZUFjY291bnRSZXNwb25zZSIAEkoKDVJlbW92ZUFjY291bnQSGi5zaG9wLlJlbW92ZUFjY291bnRSZXF1ZXN0Ghsuc2hvcC5SZW1vdmVBY2NvdW50UmVzcG9uc2UiABJcChNTdWJtaXRTdXJ2ZXlBbnN3ZXJzEiAuc2hvcC5TdWJtaXRTdXJ2ZXlBbnN3ZXJzUmVxdWVzdBohLnNob3AuU3VibWl0U3VydmV5QW5zd2Vyc1Jlc3BvbnNlIgASYgoVU3VibWl0RG93bmdyYWRlUmVhc29uEiIuc2hvcC5TdWJtaXREb3duZ3JhZGVSZWFzb25SZXF1ZXN0GiMuc2hvcC5TdWJtaXREb3duZ3JhZGVSZWFzb25SZXNwb25zZSIAEmUKFlVwZGF0ZU9yZGVyUHJpY2luZ1J1bGUSIy5zaG9wLlVwZGF0ZU9yZGVyUHJpY2luZ1J1bGVSZXF1ZXN0GiQuc2hvcC5VcGRhdGVPcmRlclByaWNpbmdSdWxlUmVzcG9uc2UiAEIqWihlY3Bvd2VyLmlvL2FwaS1zZXJ2ZXIvcHJvdG8vc2hvcDtzaG9wX3BiYgZwcm90bzM");

/**
 * GetAccounts
 *
 * @generated from message shop.GetAccountsRequest
 */
export type GetAccountsRequest = Message<"shop.GetAccountsRequest"> & {
};

/**
 * Describes the message shop.GetAccountsRequest.
 * Use `create(GetAccountsRequestSchema)` to create a new message.
 */
export const GetAccountsRequestSchema: GenMessage<GetAccountsRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 0);

/**
 * @generated from message shop.GetAccountsResponse
 */
export type GetAccountsResponse = Message<"shop.GetAccountsResponse"> & {
  /**
   * @generated from field: repeated shop.GetAccountsResponse.Account accounts = 1;
   */
  accounts: GetAccountsResponse_Account[];
};

/**
 * Describes the message shop.GetAccountsResponse.
 * Use `create(GetAccountsResponseSchema)` to create a new message.
 */
export const GetAccountsResponseSchema: GenMessage<GetAccountsResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 1);

/**
 * @generated from message shop.GetAccountsResponse.Account
 */
export type GetAccountsResponse_Account = Message<"shop.GetAccountsResponse.Account"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * AccountRole
   *
   * @generated from field: string role = 3;
   */
  role: string;

  /**
   * AccountStatus
   *
   * @generated from field: string status = 4;
   */
  status: string;

  /**
   * @generated from field: string createdAt = 5;
   */
  createdAt: string;
};

/**
 * Describes the message shop.GetAccountsResponse.Account.
 * Use `create(GetAccountsResponse_AccountSchema)` to create a new message.
 */
export const GetAccountsResponse_AccountSchema: GenMessage<GetAccountsResponse_Account> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 1, 0);

/**
 * InviteAccount
 *
 * @generated from message shop.InviteAccountRequest
 */
export type InviteAccountRequest = Message<"shop.InviteAccountRequest"> & {
  /**
   * @generated from field: string email = 1;
   */
  email: string;
};

/**
 * Describes the message shop.InviteAccountRequest.
 * Use `create(InviteAccountRequestSchema)` to create a new message.
 */
export const InviteAccountRequestSchema: GenMessage<InviteAccountRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 2);

/**
 * @generated from message shop.InviteAccountResponse
 */
export type InviteAccountResponse = Message<"shop.InviteAccountResponse"> & {
};

/**
 * Describes the message shop.InviteAccountResponse.
 * Use `create(InviteAccountResponseSchema)` to create a new message.
 */
export const InviteAccountResponseSchema: GenMessage<InviteAccountResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 3);

/**
 * RemoveAccount
 *
 * @generated from message shop.RemoveAccountRequest
 */
export type RemoveAccountRequest = Message<"shop.RemoveAccountRequest"> & {
  /**
   * @generated from field: string email = 1;
   */
  email: string;
};

/**
 * Describes the message shop.RemoveAccountRequest.
 * Use `create(RemoveAccountRequestSchema)` to create a new message.
 */
export const RemoveAccountRequestSchema: GenMessage<RemoveAccountRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 4);

/**
 * @generated from message shop.RemoveAccountResponse
 */
export type RemoveAccountResponse = Message<"shop.RemoveAccountResponse"> & {
};

/**
 * Describes the message shop.RemoveAccountResponse.
 * Use `create(RemoveAccountResponseSchema)` to create a new message.
 */
export const RemoveAccountResponseSchema: GenMessage<RemoveAccountResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 5);

/**
 * SubmitSurveyAnswers
 *
 * @generated from message shop.SubmitSurveyAnswersRequest
 */
export type SubmitSurveyAnswersRequest = Message<"shop.SubmitSurveyAnswersRequest"> & {
  /**
   * @generated from field: string referrer = 1;
   */
  referrer: string;

  /**
   * @generated from field: string role = 2;
   */
  role: string;

  /**
   * @generated from field: string purpose = 3;
   */
  purpose: string;
};

/**
 * Describes the message shop.SubmitSurveyAnswersRequest.
 * Use `create(SubmitSurveyAnswersRequestSchema)` to create a new message.
 */
export const SubmitSurveyAnswersRequestSchema: GenMessage<SubmitSurveyAnswersRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 6);

/**
 * @generated from message shop.SubmitSurveyAnswersResponse
 */
export type SubmitSurveyAnswersResponse = Message<"shop.SubmitSurveyAnswersResponse"> & {
};

/**
 * Describes the message shop.SubmitSurveyAnswersResponse.
 * Use `create(SubmitSurveyAnswersResponseSchema)` to create a new message.
 */
export const SubmitSurveyAnswersResponseSchema: GenMessage<SubmitSurveyAnswersResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 7);

/**
 * SubmitDowngradeReason
 *
 * @generated from message shop.SubmitDowngradeReasonRequest
 */
export type SubmitDowngradeReasonRequest = Message<"shop.SubmitDowngradeReasonRequest"> & {
  /**
   * @generated from field: string reason = 1;
   */
  reason: string;
};

/**
 * Describes the message shop.SubmitDowngradeReasonRequest.
 * Use `create(SubmitDowngradeReasonRequestSchema)` to create a new message.
 */
export const SubmitDowngradeReasonRequestSchema: GenMessage<SubmitDowngradeReasonRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 8);

/**
 * @generated from message shop.SubmitDowngradeReasonResponse
 */
export type SubmitDowngradeReasonResponse = Message<"shop.SubmitDowngradeReasonResponse"> & {
};

/**
 * Describes the message shop.SubmitDowngradeReasonResponse.
 * Use `create(SubmitDowngradeReasonResponseSchema)` to create a new message.
 */
export const SubmitDowngradeReasonResponseSchema: GenMessage<SubmitDowngradeReasonResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 9);

/**
 * UpdateOrderPricingRule
 *
 * @generated from message shop.UpdateOrderPricingRuleRequest
 */
export type UpdateOrderPricingRuleRequest = Message<"shop.UpdateOrderPricingRuleRequest"> & {
  /**
   * @generated from field: string orderPricingRule = 1;
   */
  orderPricingRule: string;
};

/**
 * Describes the message shop.UpdateOrderPricingRuleRequest.
 * Use `create(UpdateOrderPricingRuleRequestSchema)` to create a new message.
 */
export const UpdateOrderPricingRuleRequestSchema: GenMessage<UpdateOrderPricingRuleRequest> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 10);

/**
 * @generated from message shop.UpdateOrderPricingRuleResponse
 */
export type UpdateOrderPricingRuleResponse = Message<"shop.UpdateOrderPricingRuleResponse"> & {
};

/**
 * Describes the message shop.UpdateOrderPricingRuleResponse.
 * Use `create(UpdateOrderPricingRuleResponseSchema)` to create a new message.
 */
export const UpdateOrderPricingRuleResponseSchema: GenMessage<UpdateOrderPricingRuleResponse> = /*@__PURE__*/
  messageDesc(file_proto_shop_shop, 11);

/**
 * @generated from service shop.ShopService
 */
export const ShopService: GenService<{
  /**
   * @generated from rpc shop.ShopService.GetAccounts
   */
  getAccounts: {
    methodKind: "unary";
    input: typeof GetAccountsRequestSchema;
    output: typeof GetAccountsResponseSchema;
  },
  /**
   * @generated from rpc shop.ShopService.InviteAccount
   */
  inviteAccount: {
    methodKind: "unary";
    input: typeof InviteAccountRequestSchema;
    output: typeof InviteAccountResponseSchema;
  },
  /**
   * @generated from rpc shop.ShopService.RemoveAccount
   */
  removeAccount: {
    methodKind: "unary";
    input: typeof RemoveAccountRequestSchema;
    output: typeof RemoveAccountResponseSchema;
  },
  /**
   * @generated from rpc shop.ShopService.SubmitSurveyAnswers
   */
  submitSurveyAnswers: {
    methodKind: "unary";
    input: typeof SubmitSurveyAnswersRequestSchema;
    output: typeof SubmitSurveyAnswersResponseSchema;
  },
  /**
   * @generated from rpc shop.ShopService.SubmitDowngradeReason
   */
  submitDowngradeReason: {
    methodKind: "unary";
    input: typeof SubmitDowngradeReasonRequestSchema;
    output: typeof SubmitDowngradeReasonResponseSchema;
  },
  /**
   * @generated from rpc shop.ShopService.UpdateOrderPricingRule
   */
  updateOrderPricingRule: {
    methodKind: "unary";
    input: typeof UpdateOrderPricingRuleRequestSchema;
    output: typeof UpdateOrderPricingRuleResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_shop_shop, 0);

