import {
  OP_CONTAIN_ANY,
  OP_DATE_RANGE,
  OP_INT_RANGE,
  OP_NONE,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_LAST,
  OP_ORDER_SEQUENCE_SECOND,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from './schema/const'
import {
  FilterItemFormState,
  OrderCountFilterState,
  OrderDateFilterState,
  OrderSequenceFilterState,
  QueryItemFormState,
  QuerySetFormState,
  QuerySubsetFormState,
} from './schema/querySetFormSchema'

export const defaultOrderDateFilter: FilterItemFormState = { filter_type: 'order_date', operator: OP_NONE, value: undefined }
export const defaultOrderCountFilter: FilterItemFormState = { filter_type: 'order_count', operator: OP_NONE, value: undefined }
export const defaultOrderSequenceFilter: FilterItemFormState = { filter_type: 'order_sequence', operator: OP_NONE, value: undefined }

// Default values of dimensions
export const defaultFiltersByDimension: { [K in QueryItemFormState['dimension']]: FilterItemFormState[] } = {
  product: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  product_variant: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  product_type: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  product_tag: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  product_vendor: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  order_tag: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  coupon: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  order_date: [defaultOrderSequenceFilter],
  channel: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  order_value: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  referrer: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  landing_page: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  utm_source: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  utm_medium: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  utm_campaign: [defaultOrderDateFilter, defaultOrderCountFilter, defaultOrderSequenceFilter],
  country: [],
  prefecture: [],
  customer_tag: [],
  recency: [],
  frequency: [defaultOrderDateFilter],
  monetary: [defaultOrderDateFilter],
  aov: [defaultOrderDateFilter],
  purchase_interval: [defaultOrderSequenceFilter],
}

type filterTypesBoolMap = {
  order_date: boolean
  order_count: boolean
  order_sequence: boolean
}
// Accepted filters of each dimension, extracted from defaultFiltersByDimension
export const filterTypesBoolMapByDimension: { [key in QueryItemFormState['dimension']]: filterTypesBoolMap } = (
  Object.keys(defaultFiltersByDimension) as QueryItemFormState['dimension'][]
).reduce(
  (acc, dimension) => {
    acc[dimension] = defaultFiltersByDimension[dimension].reduce(
      (acc, filter) => {
        const key = filter.filter_type
        if (key) {
          acc[key] = true
        }
        return acc
      },
      { order_date: false, order_count: false, order_sequence: false } as filterTypesBoolMap
    )
    return acc
  },
  {} as { [key in QueryItemFormState['dimension']]: { order_date: boolean; order_count: boolean; order_sequence: boolean } }
)

export const defaultOrderDateFilterByOperator: { [key in OrderDateFilterState['operator']]: OrderDateFilterState } = {
  none: { filter_type: 'order_date', operator: OP_NONE, value: undefined },
  relative_date_range: { filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { max: null, min: null } },
  date_range: { filter_type: 'order_date', operator: OP_DATE_RANGE, value: { min_date: null, max_date: null } },
}

export const defaultOrderSequenceFilterByOperator: { [key in OrderSequenceFilterState['operator']]: OrderSequenceFilterState } = {
  none: { filter_type: 'order_sequence', operator: OP_NONE, value: undefined },
  first_order: { filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST, value: undefined },
  second_order: { filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SECOND, value: undefined },
  last_order: { filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_LAST, value: undefined },
  specify_sequence: { filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: null, max_sequence: null } },
}
export const defaultOrderCountFilterByOperator: { [key in OrderCountFilterState['operator']]: OrderCountFilterState } = {
  none: { filter_type: 'order_count', operator: OP_NONE, value: undefined },
  int_range: { filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: null, max: null } },
}

// Default values of QueryItem for each dimension
export const defaultQueryItemByDimension: { [key in QueryItemFormState['dimension']]: QueryItemFormState } = {
  product: { dimension: 'product', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.product },
  product_variant: { dimension: 'product_variant', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.product_variant },
  product_type: { dimension: 'product_type', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.product_type },
  product_tag: { dimension: 'product_tag', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.product_tag },
  product_vendor: { dimension: 'product_vendor', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.product_vendor },
  order_date: { dimension: 'order_date', operator: OP_DATE_RANGE, value: {}, filters: defaultFiltersByDimension.order_date },
  order_tag: { dimension: 'order_tag', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.order_tag },
  coupon: { dimension: 'coupon', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.coupon },
  channel: { dimension: 'channel', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.channel },
  order_value: { dimension: 'order_value', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.order_value },
  referrer: { dimension: 'referrer', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.referrer },
  landing_page: { dimension: 'landing_page', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.landing_page },
  utm_source: { dimension: 'utm_source', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.utm_source },
  utm_medium: { dimension: 'utm_medium', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.utm_medium },
  utm_campaign: { dimension: 'utm_campaign', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.utm_campaign },
  country: { dimension: 'country', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.country },
  prefecture: { dimension: 'prefecture', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.prefecture },
  customer_tag: { dimension: 'customer_tag', operator: OP_CONTAIN_ANY, value: [], filters: defaultFiltersByDimension.customer_tag },
  recency: { dimension: 'recency', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.recency },
  frequency: { dimension: 'frequency', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.frequency },
  monetary: { dimension: 'monetary', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.monetary },
  aov: { dimension: 'aov', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.aov },
  purchase_interval: { dimension: 'purchase_interval', operator: OP_INT_RANGE, value: {}, filters: defaultFiltersByDimension.purchase_interval },
}

// Default QueryItem value for adding new
export const defaultQueryItem: QueryItemFormState = defaultQueryItemByDimension.product

// Default QuerySubset value for adding new
export const defaultQuerySubset: QuerySubsetFormState = {
  join_operator: 'and',
  query_items: [defaultQueryItem],
}

// Default QuerySet value
export const defaultQuerySet: QuerySetFormState = {
  join_operator: 'and',
  query_subsets: [defaultQuerySubset],
}
