import {
  OP_CONTAIN_ANY,
  OP_DATE_RANGE,
  OP_EQUAL,
  OP_INT_RANGE,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from '@/features/customerAnalysis/querySetForm/schema/const'
import { QueryItemFormState, QuerySetFormState } from '@/features/customerAnalysis/querySetForm/schema/querySetFormSchema'
import { query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import { useTranslation } from 'react-i18next'

export type SegmentTemplate = {
  name: string
  description: string
  querySet: QuerySetFormState
}

export const useSegmentTemplates = (): { data: SegmentTemplate[] } => {
  const { t } = useTranslation()

  const { account } = useAccount()
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useCollection(query(dimensionOptionsRef))

  const topProduct = data?.find((d) => d.dimension === 'product')?.options?.[0] ?? 'sample'
  const topProductType = data?.find((d) => d.dimension === 'product_type')?.options?.[0] ?? 'sample'
  const topProductTag = data?.find((d) => d.dimension === 'product_tag')?.options?.[0] ?? 'sample'
  const topProductVendor = data?.find((d) => d.dimension === 'product_vendor')?.options?.[0] ?? 'sample'

  const templates: SegmentTemplate[] = [
    {
      name: t('features.customerAnalysis.templates.product_name'),
      description: t('features.customerAnalysis.templates.product_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.productType_name'),
      description: t('features.customerAnalysis.templates.productType_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [topProductType],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.productTag_name'),
      description: t('features.customerAnalysis.templates.productTag_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [topProductTag],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.productVendor_name'),
      description: t('features.customerAnalysis.templates.productVendor_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [topProductVendor],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.onlineStore_name'),
      description: t('features.customerAnalysis.templates.onlineStore_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: ['Online Store'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.pos_name'),
      description: t('features.customerAnalysis.templates.pos_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: ['Point of Sale'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.google_name'),
      description: t('features.customerAnalysis.templates.google_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'referrer',
          operator: OP_CONTAIN_ANY,
          value: ['Google'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.newsletter_name'),
      description: t('features.customerAnalysis.templates.newsletter_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: ['newsletter'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.adCampaign_name'),
      description: t('features.customerAnalysis.templates.adCampaign_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'utm_campaign',
          operator: OP_CONTAIN_ANY,
          value: ['Ad Campaign'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.gold_name'),
      description: t('features.customerAnalysis.templates.gold_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'customer_tag',
          operator: OP_CONTAIN_ANY,
          value: ['gold'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.subscription_name'),
      description: t('features.customerAnalysis.templates.subscription_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_tag',
          operator: OP_CONTAIN_ANY,
          value: ['subscription'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.us_name'),
      description: t('features.customerAnalysis.templates.us_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: ['United States'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.ny_name'),
      description: t('features.customerAnalysis.templates.ny_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'prefecture',
          operator: OP_CONTAIN_ANY,
          value: ['New York'],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.yearly_name'),
      description: t('features.customerAnalysis.templates.yearly_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2024-01-01', max_date: '2024-12-31' },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.recent_name'),
      description: t('features.customerAnalysis.templates.recent_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_RELATIVE_DATE_RANGE,
          value: { min: 30 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.oneTimeProduct_name'),
      description: t('features.customerAnalysis.templates.oneTimeProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 1, max: 1 } }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.repeatProduct_name'),
      description: t('features.customerAnalysis.templates.repeatProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.firstTimeProduct_name'),
      description: t('features.customerAnalysis.templates.firstTimeProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.returningProduct_name'),
      description: t('features.customerAnalysis.templates.returningProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.yearlyProduct_name'),
      description: t('features.customerAnalysis.templates.yearlyProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_date', operator: OP_DATE_RANGE, value: { min_date: '2024-01-01', max_date: '2024-12-31' } }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.recentProduct_name'),
      description: t('features.customerAnalysis.templates.recentProduct_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [topProduct],
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { min: 30 } }],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.active_name'),
      description: t('features.customerAnalysis.templates.active_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'recency',
          operator: OP_INT_RANGE,
          value: { max: 60 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.atRisk_name'),
      description: t('features.customerAnalysis.templates.atRisk_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'recency',
          operator: OP_INT_RANGE,
          value: { min: 61, max: 120 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.churned_name'),
      description: t('features.customerAnalysis.templates.churned_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'order_date',
          operator: OP_RELATIVE_DATE_RANGE,
          value: { min: 120 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.oneTime_name'),
      description: t('features.customerAnalysis.templates.oneTime_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_EQUAL,
          value: 1,
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.repeat_name'),
      description: t('features.customerAnalysis.templates.repeat_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 2, max: 4 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.loyal_name'),
      description: t('features.customerAnalysis.templates.loyal_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 5 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.prospect_name'),
      description: t('features.customerAnalysis.templates.prospect_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_EQUAL,
          value: 0,
          filters: [],
        },
      ]),
    },
    {
      name: t('features.customerAnalysis.templates.allBuyers_name'),
      description: t('features.customerAnalysis.templates.allBuyers_desc'),
      querySet: generateQuerySet([
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 1 },
          filters: [],
        },
      ]),
    },
  ]

  return { data: templates }
}

export const generateQuerySet = (queryItems: QueryItemFormState[]): QuerySetFormState => {
  return {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: queryItems,
      },
    ],
  }
}
