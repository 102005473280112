// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/customer_segment/customer_segment.proto (package customer_segment, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/customer_segment/customer_segment.proto.
 */
export const file_proto_customer_segment_customer_segment: GenFile = /*@__PURE__*/
  fileDesc("Ci1wcm90by9jdXN0b21lcl9zZWdtZW50L2N1c3RvbWVyX3NlZ21lbnQucHJvdG8SEGN1c3RvbWVyX3NlZ21lbnQiRAoNQ3JlYXRlUmVxdWVzdBIMCgRuYW1lGAEgASgJEhMKC2Rlc2NyaXB0aW9uGAIgASgJEhAKCHF1ZXJ5U2V0GAMgASgJIisKDkNyZWF0ZVJlc3BvbnNlEhkKEWN1c3RvbWVyU2VnbWVudElkGAEgASgJImAKDVVwZGF0ZVJlcXVlc3QSGQoRY3VzdG9tZXJTZWdtZW50SWQYASABKAkSDAoEbmFtZRgCIAEoCRITCgtkZXNjcmlwdGlvbhgDIAEoCRIRCglmYXZvcml0ZWQYBCABKAgiEAoOVXBkYXRlUmVzcG9uc2UiKgoNRGVsZXRlUmVxdWVzdBIZChFjdXN0b21lclNlZ21lbnRJZBgBIAEoCSIQCg5EZWxldGVSZXNwb25zZSKGAQogVXBkYXRlVGFnT3BlcmF0aW9uU2V0dGluZ1JlcXVlc3QSGQoRY3VzdG9tZXJTZWdtZW50SWQYASABKAkSEAoIaXNBY3RpdmUYAiABKAgSDwoHdGFnTmFtZRgDIAEoCRIkChxlbmFibGVTaG9waWZ5U2VnbWVudENyZWF0aW9uGAQgASgIIiMKIVVwZGF0ZVRhZ09wZXJhdGlvblNldHRpbmdSZXNwb25zZTKOAwoWQ3VzdG9tZXJTZWdtZW50U2VydmljZRJNCgZDcmVhdGUSHy5jdXN0b21lcl9zZWdtZW50LkNyZWF0ZVJlcXVlc3QaIC5jdXN0b21lcl9zZWdtZW50LkNyZWF0ZVJlc3BvbnNlIgASTQoGVXBkYXRlEh8uY3VzdG9tZXJfc2VnbWVudC5VcGRhdGVSZXF1ZXN0GiAuY3VzdG9tZXJfc2VnbWVudC5VcGRhdGVSZXNwb25zZSIAEk0KBkRlbGV0ZRIfLmN1c3RvbWVyX3NlZ21lbnQuRGVsZXRlUmVxdWVzdBogLmN1c3RvbWVyX3NlZ21lbnQuRGVsZXRlUmVzcG9uc2UiABKGAQoZVXBkYXRlVGFnT3BlcmF0aW9uU2V0dGluZxIyLmN1c3RvbWVyX3NlZ21lbnQuVXBkYXRlVGFnT3BlcmF0aW9uU2V0dGluZ1JlcXVlc3QaMy5jdXN0b21lcl9zZWdtZW50LlVwZGF0ZVRhZ09wZXJhdGlvblNldHRpbmdSZXNwb25zZSIAQkJaQGVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9jdXN0b21lcl9zZWdtZW50O2N1c3RvbWVyX3NlZ21lbnRfcGJiBnByb3RvMw");

/**
 * Create
 *
 * @generated from message customer_segment.CreateRequest
 */
export type CreateRequest = Message<"customer_segment.CreateRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string description = 2;
   */
  description: string;

  /**
   * @generated from field: string querySet = 3;
   */
  querySet: string;
};

/**
 * Describes the message customer_segment.CreateRequest.
 * Use `create(CreateRequestSchema)` to create a new message.
 */
export const CreateRequestSchema: GenMessage<CreateRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 0);

/**
 * @generated from message customer_segment.CreateResponse
 */
export type CreateResponse = Message<"customer_segment.CreateResponse"> & {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId: string;
};

/**
 * Describes the message customer_segment.CreateResponse.
 * Use `create(CreateResponseSchema)` to create a new message.
 */
export const CreateResponseSchema: GenMessage<CreateResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 1);

/**
 * Update
 *
 * @generated from message customer_segment.UpdateRequest
 */
export type UpdateRequest = Message<"customer_segment.UpdateRequest"> & {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * @generated from field: string description = 3;
   */
  description: string;

  /**
   * @generated from field: bool favorited = 4;
   */
  favorited: boolean;
};

/**
 * Describes the message customer_segment.UpdateRequest.
 * Use `create(UpdateRequestSchema)` to create a new message.
 */
export const UpdateRequestSchema: GenMessage<UpdateRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 2);

/**
 * @generated from message customer_segment.UpdateResponse
 */
export type UpdateResponse = Message<"customer_segment.UpdateResponse"> & {
};

/**
 * Describes the message customer_segment.UpdateResponse.
 * Use `create(UpdateResponseSchema)` to create a new message.
 */
export const UpdateResponseSchema: GenMessage<UpdateResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 3);

/**
 * Delete
 *
 * @generated from message customer_segment.DeleteRequest
 */
export type DeleteRequest = Message<"customer_segment.DeleteRequest"> & {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId: string;
};

/**
 * Describes the message customer_segment.DeleteRequest.
 * Use `create(DeleteRequestSchema)` to create a new message.
 */
export const DeleteRequestSchema: GenMessage<DeleteRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 4);

/**
 * @generated from message customer_segment.DeleteResponse
 */
export type DeleteResponse = Message<"customer_segment.DeleteResponse"> & {
};

/**
 * Describes the message customer_segment.DeleteResponse.
 * Use `create(DeleteResponseSchema)` to create a new message.
 */
export const DeleteResponseSchema: GenMessage<DeleteResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 5);

/**
 * UpdateTagOperationSetting
 *
 * @generated from message customer_segment.UpdateTagOperationSettingRequest
 */
export type UpdateTagOperationSettingRequest = Message<"customer_segment.UpdateTagOperationSettingRequest"> & {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId: string;

  /**
   * @generated from field: bool isActive = 2;
   */
  isActive: boolean;

  /**
   * @generated from field: string tagName = 3;
   */
  tagName: string;

  /**
   * @generated from field: bool enableShopifySegmentCreation = 4;
   */
  enableShopifySegmentCreation: boolean;
};

/**
 * Describes the message customer_segment.UpdateTagOperationSettingRequest.
 * Use `create(UpdateTagOperationSettingRequestSchema)` to create a new message.
 */
export const UpdateTagOperationSettingRequestSchema: GenMessage<UpdateTagOperationSettingRequest> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 6);

/**
 * @generated from message customer_segment.UpdateTagOperationSettingResponse
 */
export type UpdateTagOperationSettingResponse = Message<"customer_segment.UpdateTagOperationSettingResponse"> & {
};

/**
 * Describes the message customer_segment.UpdateTagOperationSettingResponse.
 * Use `create(UpdateTagOperationSettingResponseSchema)` to create a new message.
 */
export const UpdateTagOperationSettingResponseSchema: GenMessage<UpdateTagOperationSettingResponse> = /*@__PURE__*/
  messageDesc(file_proto_customer_segment_customer_segment, 7);

/**
 * @generated from service customer_segment.CustomerSegmentService
 */
export const CustomerSegmentService: GenService<{
  /**
   * @generated from rpc customer_segment.CustomerSegmentService.Create
   */
  create: {
    methodKind: "unary";
    input: typeof CreateRequestSchema;
    output: typeof CreateResponseSchema;
  },
  /**
   * @generated from rpc customer_segment.CustomerSegmentService.Update
   */
  update: {
    methodKind: "unary";
    input: typeof UpdateRequestSchema;
    output: typeof UpdateResponseSchema;
  },
  /**
   * @generated from rpc customer_segment.CustomerSegmentService.Delete
   */
  delete: {
    methodKind: "unary";
    input: typeof DeleteRequestSchema;
    output: typeof DeleteResponseSchema;
  },
  /**
   * @generated from rpc customer_segment.CustomerSegmentService.UpdateTagOperationSetting
   */
  updateTagOperationSetting: {
    methodKind: "unary";
    input: typeof UpdateTagOperationSettingRequestSchema;
    output: typeof UpdateTagOperationSettingResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_customer_segment_customer_segment, 0);

