import dayjs from 'dayjs'

/*
  arg: timezone -> shop.timezone
*/

export const useCurrentDate = (timezone: string): dayjs.Dayjs => {
  const currentDate = dayjs().tz(timezone)
  return currentDate
}
