import { DialogWrapper } from '@/components/dialogWrapper'
import { Alert } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
  tagOperationSettingIsActive: boolean
}

export const DeleteDialog: FC<Props> = ({ open, handleClose, id, name, tagOperationSettingIsActive }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.delete({ customerSegmentId: id }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.deleteDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.deleteDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.deleteDialog.title')}
      mainContent={
        <div className='flex flex-col gap-4'>
          {tagOperationSettingIsActive && <Alert severity='warning'>{t('features.customerSegments.deleteDialog.alert')}</Alert>}
          <p className='text-sm'>{t('features.customerSegments.deleteDialog.description', { name: name })}</p>
        </div>
      }
      onSubmit={handleSubmit}
      loading={isLoading}
      cancelButtonText={t('features.customerSegments.deleteDialog.cancel')}
      submitButtonText={t('features.customerSegments.deleteDialog.submit')}
      submitButtonDisabled={tagOperationSettingIsActive}
    />
  )
}
