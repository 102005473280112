import { z } from 'zod'
import {
  OP_CONTAIN_ALL,
  OP_CONTAIN_ANY,
  OP_DATE_RANGE,
  OP_EQUAL,
  OP_INT_RANGE,
  OP_NONE,
  OP_NOT_CONTAIN,
  OP_NOT_EQUAL,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_LAST,
  OP_ORDER_SEQUENCE_SECOND,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from './const'
import { TFunction } from 'i18next'

/*
  about message
  If the message contains dynamic content, define the message as follows Also,
  you should use buildErrorMessage() to process error.message

  ex.
  message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": ${minLimit}, "max": ${maxLimit} }`,
*/

const generateIntRangeValueSchema = (minLimit: number, maxLimit: number) =>
  z
    .object({
      min: z
        .number()
        .int()
        .gte(minLimit, {
          message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": ${minLimit}, "max": ${maxLimit} }`,
        })
        .lte(maxLimit, {
          message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": ${minLimit}, "max": ${maxLimit} }`,
        })
        .optional()
        .nullable(),
      max: z
        .number()
        .int()
        .gte(minLimit, {
          message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": ${minLimit}, "max": ${maxLimit} }`,
        })
        .lte(maxLimit, {
          message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": ${minLimit}, "max": ${maxLimit} }`,
        })
        .optional()
        .nullable(),
    })
    .refine(
      (data) => {
        // Using loose equality (== null) to allow 0 as a valid value; this check only catches null and undefined.
        if (data.min == null && data.max == null) return false // validation error
        return true
      },
      {
        message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
        path: ['min'],
      }
    )
    .refine(
      (data) => {
        if (data.min == null && data.max == null) return false // validation error
        return true
      },
      {
        message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
        path: ['max'],
      }
    )
    .refine(
      (data) => {
        if (data.min != null && data.max != null && data.max < data.min) return false // validation error
        return true
      },
      {
        message: 'features.customerAnalysis.querySet.messageValidationMinMustSmallerThanMax',
        path: ['min'],
      }
    )
    .refine(
      (data) => {
        if (data.min != null && data.max != null && data.max < data.min) return false // validation error
        return true
      },
      {
        message: 'features.customerAnalysis.querySet.messageValidationMaxMustBiggerThanMin',
        path: ['max'],
      }
    )

export const buildErrorMessage = (t: TFunction<'translation', undefined>, errorMessage: string) => {
  const [errorText, valJson] = errorMessage.split(/,(.+)/)
  if (valJson) {
    const val = JSON.parse(valJson)
    return t(errorText, val)
  } else {
    return t(errorText)
  }
}

const dateRangeValue = z
  .object({
    min_date: z.string().optional().nullable(),
    max_date: z.string().optional().nullable(),
  })
  .refine(
    (data) => {
      if (!data.min_date && !data.max_date) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
      path: ['min_date'],
    }
  )
  .refine(
    (data) => {
      if (!data.min_date && !data.max_date) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
      path: ['max_date'],
    }
  )
  .refine(
    (data) => {
      if (data.min_date && data.max_date && data.min_date > data.max_date) return false // validation error
      return true
    },
    { message: 'features.customerAnalysis.querySet.messageValidationMinMustSmallerThanMax', path: ['min_date'] }
  )
  .refine(
    (data) => {
      if (data.min_date && data.max_date && data.min_date > data.max_date) return false // validation error
      return true
    },
    { message: 'features.customerAnalysis.querySet.messageValidationMaxMustBiggerThanMin', path: ['max_date'] }
  )

const orderSequenceValue = z
  .object({
    min_sequence: z
      .number()
      .int()
      .gte(1, {
        message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": 1, "max": 99 }`,
      })
      .lte(99, {
        message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": 1, "max": 99 }`,
      })
      .optional()
      .nullable(),
    max_sequence: z
      .number()
      .int()
      .gte(1, {
        message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": 1, "max": 99 }`,
      })
      .lte(99, {
        message: `features.customerAnalysis.querySet.messageValidationNumberRange,{ "min": 1, "max": 99 }`,
      })
      .optional()
      .nullable(),
    ascending: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (!data.min_sequence && !data.max_sequence) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
      path: ['min_sequence'],
    }
  )
  .refine(
    (data) => {
      if (!data.min_sequence && !data.max_sequence) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationEitherMinOrMax',
      path: ['max_sequence'],
    }
  )
  .refine(
    (data) => {
      if (data.min_sequence && data.max_sequence && data.max_sequence < data.min_sequence) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationMinMustSmallerThanMax',
      path: ['min_sequence'],
    }
  )
  .refine(
    (data) => {
      if (data.min_sequence && data.max_sequence && data.max_sequence < data.min_sequence) return false // validation error
      return true
    },
    {
      message: 'features.customerAnalysis.querySet.messageValidationMaxMustBiggerThanMin',
      path: ['max_sequence'],
    }
  )

/* filter form */
const orderDateFilter = z.union([
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_NONE),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_RELATIVE_DATE_RANGE),
    value: generateIntRangeValueSchema(1, 999),
  }),
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_DATE_RANGE),
    value: dateRangeValue,
  }),
])

const orderCountFilter = z.union([
  z.object({
    filter_type: z.literal('order_count'),
    operator: z.literal(OP_NONE),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_count'),
    operator: z.literal(OP_INT_RANGE),
    value: generateIntRangeValueSchema(1, 99),
  }),
])

const orderSequenceFilter = z.union([
  z.object({
    filter_type: z.literal('order_sequence'),
    operator: z.union([
      z.literal(OP_NONE),
      z.literal(OP_ORDER_SEQUENCE_FIRST),
      z.literal(OP_ORDER_SEQUENCE_SECOND),
      z.literal(OP_ORDER_SEQUENCE_LAST),
    ]),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_sequence'),
    operator: z.literal(OP_ORDER_SEQUENCE_SPECIFY),
    value: orderSequenceValue,
  }),
])

const filterItem = z.union([orderDateFilter, orderCountFilter, orderSequenceFilter])

/* each query form */
const productQuery = z.object({
  dimension: z.literal('product'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const productVariantQuery = z.object({
  dimension: z.literal('product_variant'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const productTypeQuery = z.object({
  dimension: z.literal('product_type'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const productTagQuery = z.object({
  dimension: z.literal('product_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const productVendorQuery = z.object({
  dimension: z.literal('product_vendor'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const orderTagQuery = z.object({
  dimension: z.literal('order_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const couponQuery = z.object({
  dimension: z.literal('coupon'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const orderDateQuery = z.union([
  z.object({
    dimension: z.literal('order_date'),
    operator: z.literal(OP_DATE_RANGE),
    value: dateRangeValue,
    filters: filterItem.array(),
  }),
  z.object({
    dimension: z.literal('order_date'),
    operator: z.literal(OP_RELATIVE_DATE_RANGE),
    value: generateIntRangeValueSchema(1, 999),
    filters: filterItem.array(),
  }),
])

const channelQuery = z.object({
  dimension: z.literal('channel'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const orderValueQuery = z.object({
  dimension: z.literal('order_value'),
  operator: z.literal(OP_INT_RANGE),
  value: generateIntRangeValueSchema(1, 9999999),
  filters: filterItem.array(),
})

const referrerQuery = z.object({
  dimension: z.literal('referrer'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const landingPageQuery = z.object({
  dimension: z.literal('landing_page'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const utmSourceQuery = z.object({
  dimension: z.literal('utm_source'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const utmMediumQuery = z.object({
  dimension: z.literal('utm_medium'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const utmCampaignQuery = z.object({
  dimension: z.literal('utm_campaign'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const countryQuery = z.object({
  dimension: z.literal('country'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const prefectureQuery = z.object({
  dimension: z.literal('prefecture'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const customerTagQuery = z.object({
  dimension: z.literal('customer_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array().min(1, 'features.customerAnalysis.querySet.messageValidationAutoCompleteEmpty'),
  filters: filterItem.array(),
})

const recencyQuery = z.object({
  dimension: z.literal('recency'),
  operator: z.literal(OP_INT_RANGE),
  value: generateIntRangeValueSchema(0, 999),
  filters: filterItem.array(),
})

const frequencyQuery = z.union([
  z.object({
    dimension: z.literal('frequency'),
    operator: z.literal(OP_INT_RANGE),
    value: generateIntRangeValueSchema(0, 99),
    filters: filterItem.array(),
  }),
  z.object({
    dimension: z.literal('frequency'),
    operator: z.union([z.literal(OP_EQUAL), z.literal(OP_NOT_EQUAL)]),
    value: z
      .number()
      .min(0, 'features.customerAnalysis.querySet.messageValidationNumberRange, { "min": 1, "max": 99 }')
      .max(99, 'features.customerAnalysis.querySet.messageValidationNumberRange, { "min": 1, "max": 99 }')
      .nullable() // Validate with refine since null is not allowed
      .refine((value) => value != null, {
        message: 'features.customerAnalysis.querySet.messageValidationMustNumber',
      }),
    filters: filterItem.array(),
  }),
])

const monetaryQuery = z.object({
  dimension: z.literal('monetary'),
  operator: z.literal(OP_INT_RANGE),
  value: generateIntRangeValueSchema(1, 9999999),
  filters: filterItem.array(),
})

const aovQuery = z.object({
  dimension: z.literal('aov'),
  operator: z.literal(OP_INT_RANGE),
  value: generateIntRangeValueSchema(1, 9999999),
  filters: filterItem.array(),
})

const purchaseIntervalQuery = z.object({
  dimension: z.literal('purchase_interval'),
  operator: z.literal(OP_INT_RANGE),
  value: generateIntRangeValueSchema(1, 999),
  filters: filterItem.array(),
})

/* query item form */
const queryItem = z.union([
  productQuery,
  productVariantQuery,
  productTypeQuery,
  productTagQuery,
  productVendorQuery,
  orderTagQuery,
  couponQuery,
  orderDateQuery,
  channelQuery,
  orderValueQuery,
  referrerQuery,
  landingPageQuery,
  utmSourceQuery,
  utmMediumQuery,
  utmCampaignQuery,
  countryQuery,
  prefectureQuery,
  customerTagQuery,
  recencyQuery,
  frequencyQuery,
  monetaryQuery,
  aovQuery,
  purchaseIntervalQuery,
])

/* query subset form */
export const querySubset = z.object({
  join_operator: z.union([z.literal('and'), z.literal('or')]),
  query_items: queryItem.array(),
})

/* query set form */
export const querySet = z.object({
  join_operator: z.union([z.literal('and'), z.literal('or')]),
  query_subsets: querySubset.array(),
})

export type FilterItemFormState = z.infer<typeof filterItem>
export type QuerySetFormState = z.infer<typeof querySet>
export type QuerySubsetFormState = z.infer<typeof querySubset>
export type QueryItemFormState = z.infer<typeof queryItem>
export type OrderDateFilterState = z.infer<typeof orderDateFilter>
export type OrderSequenceFilterState = z.infer<typeof orderSequenceFilter>
export type OrderCountFilterState = z.infer<typeof orderCountFilter>
