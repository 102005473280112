import { DialogWrapper } from '@/components/dialogWrapper'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { convertFormToQuerySet } from '@/features/orderAnalysis/querySetForm/schema/converter'
import { QuerySetFormState } from '@/features/orderAnalysis/querySetForm/schema/querySetFormSchema'
import { OrderFilterService } from '@/gen/proto/order_filter/order_filter_pb'
import { Code, ConnectError } from '@connectrpc/connect'
import Picker from '@emoji-mart/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, Popover } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  querySetFormMethods: UseFormReturn<QuerySetFormState>
}

export const CreateDialog: FC<Props> = ({ open, handleClose, querySetFormMethods }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const orderFilterService = useGrpcClient(OrderFilterService)

  const defaultEmojiList = ['😀', '🥰', '😎', '👋', '🐶', '💐', '🔥', '🥑', '🍔', '🏋️‍♀️', '🚗', '🚀', '⏰', '🎁', '🎉']
  const defaultEmoji = defaultEmojiList[Math.floor(Math.random() * defaultEmojiList.length)]

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(defaultEmoji)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const input = z.object({
    name: z
      .string()
      .min(1, { message: t('features.orderFilters.createDialog.messageValidationNameMin') })
      .max(64, { message: t('features.orderFilters.createDialog.messageValidationNameMax') }),
  })

  type InputSchema = z.infer<typeof input>

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(input),
    defaultValues: {
      name: '',
    },
  })

  const createSegmentGroup = async (input: InputSchema) => {
    const ok = await querySetFormMethods.trigger()
    if (!ok) return
    const querySetFormValues = querySetFormMethods.getValues()
    const querySet = convertFormToQuerySet(querySetFormValues)
    const querySetString = JSON.stringify(querySet)

    try {
      const token = await getIdToken(authUser!)
      await orderFilterService.create(
        { name: `${emojiIcon} ${input.name}`, querySet: querySetString },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.orderFilters.createDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.orderFilters.createDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.orderFilters.createDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.orderFilters.createDialog.title')}
      mainContent={
        <div className='flex items-center gap-2'>
          <Button ref={emojiAnchorRef} variant='outline' onClick={() => setOpenEmojiPicker(true)}>
            {emojiIcon}
          </Button>
          <Popover
            open={openEmojiPicker}
            anchorEl={emojiAnchorRef.current}
            onClose={() => setOpenEmojiPicker(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Picker
              locale={i18n.language}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onEmojiSelect={(emoji: any) => {
                setEmojiIcon(emoji.native)
                setOpenEmojiPicker(false)
              }}
            />
          </Popover>
          <Controller
            control={control}
            name='name'
            rules={{
              validate: (v) => {
                if (!v) return t('features.orderFilters.createDialog.messageValidationNameMin')
                if (v.length > 64) return t('features.orderFilters.createDialog.messageValidationNameMax')
                return
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl className='w-full'>
                  <Input {...field} placeholder={t('features.orderFilters.createDialog.fieldName')} />
                  {error && <p className='text-red-500 text-xs mt-1 ml-2'>{error.message}</p>}
                </FormControl>
              )
            }}
          />
        </div>
      }
      onSubmit={handleSubmit(createSegmentGroup)}
      loading={isSubmitting}
      cancelButtonText={t('features.orderFilters.createDialog.cancel')}
      submitButtonText={t('features.orderFilters.createDialog.submit')}
    />
  )
}
