import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { ShopBillingStatus, TagOperationStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCollectionSubscription, useDocumentSubscription } from '@/hooks/useFirestoreData'
import { useActionTracker } from '@/hooks/useMixpanel'
import { AUTHORIZED_ROUTE } from '@/routing'
import { getImagePath } from '@/utils/imageUtil'
import { formatDateTime, timestampToDayjs } from '@/utils/timeUtil'
import { doc, orderBy, query } from 'firebase/firestore'
import { Ellipsis, Pencil, Trash } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DeleteDialog } from '../components/deleteDialog'
import { UpdateDialog } from '../components/updateDialog'
import { ActivateAutoSyncDialog } from './components/activateAutoSyncDialog'
import { DeactivateAutoSyncDialog } from './components/deactivateAutoSyncDialog'

export const Detail = () => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { dispatch } = useActionTracker()

  const params = useParams()
  const customerSegmentId = params.id

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const { account } = useAccount()
  const { customerSegmentsRef, tagOperationsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegment } = useDocumentSubscription(doc(customerSegmentsRef, customerSegmentId))
  const { data: tagOperations } = useCollectionSubscription(query(tagOperationsRef, orderBy('createdAt', 'desc')))

  const currentTagRunning: boolean = useMemo(() => {
    if (!tagOperations || tagOperations.length === 0) return false
    return tagOperations.some((op) => op.customerSegmentRef?.id === customerSegmentId && op.status !== TagOperationStatus.completed)
  }, [tagOperations, customerSegmentId])

  const otherTagsRunning: boolean = useMemo(() => {
    if (!tagOperations || tagOperations.length === 0) return false
    return tagOperations.some((op) => op.customerSegmentRef?.id !== customerSegmentId && op.status === TagOperationStatus.processing)
  }, [tagOperations, customerSegmentId])

  const [activateDialogOpen, setActivateDialogOpen] = useState(false)
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false)

  const handleSyncSettingButtonClick = () => {
    if (customerSegment?.tagOperationSetting?.isActive) {
      setDeactivateDialogOpen(true)
      return
    }
    if (shopBillingStatus === ShopBillingStatus.free) {
      setUpgradeDialogOpen(true)
      dispatch('OpenUpgradeDialog', { referrer: 'segment_activateAutoSync' })
      return
    }
    setActivateDialogOpen(true)
  }

  return (
    <>
      <PageHeader
        title={customerSegment?.name || ''}
        origin={{ title: t('features.customerSegments.detail.segments'), path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS }}
        menuComponent={
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <TooltipIconButton icon={Ellipsis} tooltipContent={t('features.customerSegments.menu')} variant='ghost' />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className='cursor-pointer' onClick={() => setUpdateDialogOpen(true)}>
                <Pencil />
                {t('features.customerSegments.edit')}
              </DropdownMenuItem>
              <DropdownMenuItem className='cursor-pointer' onClick={() => setDeleteDialogOpen(true)}>
                <Trash />
                {t('features.customerSegments.delete')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        }
      />

      <PageContentWrapper>
        <div className='flex flex-col gap-4'>
          <Card>
            <CardHeader>
              <CardTitle>{t('features.customerSegments.detail.title_profile')}</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow className='border-none'>
                    <TableCell className='w-[180px]'>{t('features.customerSegments.detail.name')}</TableCell>
                    <TableCell>{customerSegment?.name}</TableCell>
                  </TableRow>
                  <TableRow className='border-none'>
                    <TableCell className='w-[180px]'>{t('features.customerSegments.detail.description')}</TableCell>
                    <TableCell>{customerSegment?.description ? customerSegment.description : '-'}</TableCell>
                  </TableRow>
                  <TableRow className='border-none'>
                    <TableCell className='w-[180px]'>{t('features.customerSegments.detail.createdAt')}</TableCell>
                    <TableCell>
                      {customerSegment?.createdAt ? formatDateTime(timestampToDayjs(customerSegment.createdAt), i18n.language) : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <img src={getImagePath('shopify_logo.svg')} alt='shopify-logo' width='20px' />
                  <CardTitle>{t('features.customerSegments.detail.title_autoSync')}</CardTitle>
                </div>
                <Button
                  variant={customerSegment?.tagOperationSetting?.isActive ? 'outline' : 'default'}
                  size='sm'
                  disabled={currentTagRunning || otherTagsRunning}
                  onClick={handleSyncSettingButtonClick}
                >
                  {customerSegment?.tagOperationSetting?.isActive
                    ? t('features.customerSegments.detail.deactivate')
                    : t('features.customerSegments.detail.activate')}
                </Button>
              </div>
            </CardHeader>
            <CardContent>
              {customerSegment && customerSegment.tagOperationSetting && (
                <Table>
                  <TableBody>
                    <TableRow className='border-none'>
                      <TableCell className='w-[180px]'>{t('features.customerSegments.detail.status')}</TableCell>
                      <TableCell>
                        {currentTagRunning
                          ? t('features.customerSegments.detail.running')
                          : customerSegment.tagOperationSetting.isActive
                            ? t('features.customerSegments.detail.active')
                            : t('features.customerSegments.detail.inactive')}
                      </TableCell>
                    </TableRow>
                    <TableRow className='border-none'>
                      <TableCell>{t('features.customerSegments.detail.tag')}</TableCell>
                      <TableCell>{customerSegment.tagOperationSetting.tagName || '-'}</TableCell>
                    </TableRow>
                    <TableRow className='border-none'>
                      <TableCell>{t('features.customerSegments.detail.lastSyncedAt')}</TableCell>
                      <TableCell>
                        {customerSegment.tagOperationSetting.lastSyncedAt
                          ? formatDateTime(timestampToDayjs(customerSegment.tagOperationSetting.lastSyncedAt), i18n.language)
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </CardContent>
          </Card>
        </div>
      </PageContentWrapper>

      {updateDialogOpen && customerSegment && (
        <UpdateDialog
          open={updateDialogOpen}
          handleClose={() => setUpdateDialogOpen(false)}
          id={customerSegment.ref.id}
          name={customerSegment?.name}
          description={customerSegment?.description}
        />
      )}

      {deleteDialogOpen && customerSegment && (
        <DeleteDialog
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          id={customerSegment.ref.id}
          name={customerSegment?.name}
          tagOperationSettingIsActive={customerSegment?.tagOperationSetting.isActive}
        />
      )}

      {activateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <ActivateAutoSyncDialog
          open={activateDialogOpen}
          handleClose={() => setActivateDialogOpen(false)}
          customerSegmentId={customerSegment.ref.id}
          customerSegmentName={customerSegment.name}
        />
      )}

      {deactivateDialogOpen && customerSegment && customerSegment.tagOperationSetting && (
        <DeactivateAutoSyncDialog
          open={deactivateDialogOpen}
          handleClose={() => setDeactivateDialogOpen(false)}
          customerSegmentId={customerSegment.ref.id}
          tagName={customerSegment.tagOperationSetting.tagName}
        />
      )}

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
