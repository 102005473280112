import { SkeletonTable } from '@/components/skeletonTable'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Progress } from '@/components/ui/progress'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { OrderInsightDimension, ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrency } from '@/hooks/useCurrency'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import { AUTHORIZED_ROUTE } from '@/routing'
import { EmptyState } from 'components/emptyState'
import { Download, LoaderCircle, ScanSearch } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/timeUtil'
import { useInsightsDownload } from '../hooks/useInsightsDownload'
import { convertFormToQuerySet, convertQuerySetToForm, convertStringToApiQuerySetState } from '../querySetForm/schema/converter'
import { generateQuerySetByInsight, OrderInsightValue } from '../types'

type Props = {
  querySetString: string
  insights: OrderInsightValue[] | undefined
  insightsLoading: boolean
  insightDimension: OrderInsightDimension
  setInsightDimension: (dimension: OrderInsightDimension) => void
}

export const Insights: FC<Props> = ({ querySetString, insights, insightsLoading, insightDimension, setInsightDimension }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { formatCurrency } = useCurrency()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const [searchText, setSearchText] = useState<string>('')
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const filteredInsights = useMemo(() => {
    if (!insights) return undefined
    return insights.filter((insight) => insight.name.toLowerCase().includes(searchText.toLowerCase()))
  }, [insights, searchText])

  const formatValueName = (name: string) => {
    if (insightDimension === OrderInsightDimension.newRepeat) {
      return t(`features.orderAnalysis.insightDimension.newRepeat_${name.toLowerCase()}`)
    }
    return name
  }

  const { handleDownload: handleDownloadInsights, loading: downloadInsightsLoading } = useInsightsDownload({
    querySetString,
    dimension: insightDimension,
    fileName: t('features.orderAnalysis.insights.downloadFileName_insights', { date: formatDate(currentDate, i18n.language) }),
    page: 'orderAnalysis_insights',
  })

  const handleInsightViewMore = (dimension: OrderInsightDimension, value: string) => {
    const qs = convertQuerySetToForm(convertStringToApiQuerySetState(querySetString))
    const newQuerySet = generateQuerySetByInsight(qs, dimension, value)
    if (!newQuerySet) {
      navigate(AUTHORIZED_ROUTE.ORDER_ANALYSIS)
      return
    }
    const to = `${AUTHORIZED_ROUTE.ORDER_ANALYSIS}?${createSearchParams({
      querySet: encodeURIComponent(JSON.stringify(convertFormToQuerySet(newQuerySet))),
    }).toString()}`
    window.open(to, '_blank')
    dispatch('ClickOrderInsightViewMore', { name: `${dimension}-${value}` })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <CardTitle>{t('features.orderAnalysis.insights.title')}</CardTitle>
            <Select
              defaultValue={insightDimension}
              onValueChange={(value) => {
                setInsightDimension(value as OrderInsightDimension)
                dispatch('SelectOrderInsightDimension', { name: value })
              }}
            >
              <SelectTrigger className='w-[180px]'>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {Object.entries({
                  product: [
                    OrderInsightDimension.product,
                    OrderInsightDimension.productType,
                    OrderInsightDimension.productTag,
                    OrderInsightDimension.productVendor,
                  ],
                  order: [OrderInsightDimension.orderTag, OrderInsightDimension.coupon, OrderInsightDimension.channel],
                  acquisition: [
                    OrderInsightDimension.referrer,
                    OrderInsightDimension.utmSource,
                    OrderInsightDimension.utmMedium,
                    OrderInsightDimension.utmCampaign,
                  ],
                  customer: [OrderInsightDimension.country, OrderInsightDimension.newRepeat],
                }).map(([category, dimensions]) => (
                  <SelectGroup key={category}>
                    <SelectLabel>{t(`features.orderAnalysis.insightDimension.category_${category}`)}</SelectLabel>
                    {dimensions.map((d) => (
                      <SelectItem key={d} value={d}>
                        {t(`features.orderAnalysis.insightDimension.${d}`)}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                ))}
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        <CardContent>
          {insightsLoading || !insights ? (
            <div className='flex justify-center items-center h-[280px]'>
              <LoaderCircle className='animate-spin' />
            </div>
          ) : (
            <Table>
              <TableBody>
                {insights.slice(0, 8).map((insight) => (
                  <TableRow key={insight.name} className='border-none'>
                    <TableCell className='border-none'>
                      {insight.visible ? (
                        formatValueName(insight.name)
                      ) : (
                        <Tooltip>
                          <TooltipTrigger>
                            <Skeleton className='w-[120px] h-3' />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t('features.orderAnalysis.insights.invisible')}</p>
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className='w-2/3 border-none'>
                      <Progress
                        value={(insight.orderAmount / insights[0].orderAmount) * 100}
                        className='h-4 rounded bg-gray-200 [&>div]:bg-sky-800'
                      />
                    </TableCell>
                    <TableCell className='border-none'>
                      <p className='text-sm text-muted-foreground'>{formatCurrency(insight.orderAmount)}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          {insights && insights.length > 0 && <Separator className='my-8' />}

          {insightsLoading || !filteredInsights ? (
            <SkeletonTable columnCount={10} rowCount={10} />
          ) : filteredInsights.length === 0 ? (
            <div className='flex justify-center items-center h-[320px]'>
              {searchText.length > 0 ? (
                <EmptyState
                  title={t('features.orderAnalysis.insights.empty_search', { searchText })}
                  buttonText={t('features.orderAnalysis.insights.clear')}
                  buttonOnClick={() => setSearchText('')}
                />
              ) : (
                <EmptyState title={t('features.orderAnalysis.insights.empty_insights')} />
              )}
            </div>
          ) : (
            <div className='flex flex-col gap-4'>
              <div className='flex items-center justify-between'>
                <Input
                  className='w-[240px]'
                  placeholder={t('features.orderAnalysis.insights.search')}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <TooltipIconButton
                  icon={downloadInsightsLoading ? LoaderCircle : Download}
                  tooltipContent={t('features.orderAnalysis.download')}
                  variant='ghost'
                  disabled={!insights || downloadInsightsLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeDialogOpen(true)
                      dispatch('OpenUpgradeDialog', { referrer: 'orderAnalysis_insights_csv' })
                      return
                    }
                    handleDownloadInsights()
                  }}
                />
              </div>
              <Table>
                {insights?.length === 100 && <TableCaption>{t('features.orderAnalysis.insights.alert_insights')}</TableCaption>}
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.orderAnalysis.insights.name')}</TableHead>
                    <TableHead className='text-center'>{t('features.orderAnalysis.orderAmount')}</TableHead>
                    <TableHead className='text-center'>{t('features.orderAnalysis.orderCount')}</TableHead>
                    <TableHead className='text-center'>{t('features.orderAnalysis.customerCount')}</TableHead>
                    <TableHead className='text-center'>{t('features.orderAnalysis.avgOrderValue')}</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredInsights.map((insight) => (
                    <TableRow key={insight.name}>
                      <TableCell>
                        {insight.visible ? (
                          formatValueName(insight.name)
                        ) : (
                          <Tooltip>
                            <TooltipTrigger>
                              <Skeleton className='w-[120px] h-3' />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{t('features.orderAnalysis.insights.invisible')}</p>
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className='text-center'>{formatCurrency(insight.orderAmount)}</TableCell>
                      <TableCell className='text-center'>{insight.orderCount.toLocaleString()}</TableCell>
                      <TableCell className='text-center'>{insight.customerCount.toLocaleString()}</TableCell>
                      <TableCell className='text-center'>{formatCurrency(insight.avgOrderValue)}</TableCell>
                      <TableCell className='text-right'>
                        <TooltipIconButton
                          icon={ScanSearch}
                          tooltipContent={t('features.orderAnalysis.insights.viewMore')}
                          variant='ghost'
                          disabled={!insight.visible}
                          onClick={() => handleInsightViewMore(insightDimension, insight.name)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
