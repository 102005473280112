import { UpgradeDialog } from '@/components/upgradeDialog'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  text: string
}

export const TrialBanner: FC<Props> = ({ text }) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  return (
    <>
      <div className='flex items-center justify-between gap-2 w-full px-6 py-2 bg-primary text-sm text-white'>
        <div>{text}</div>
        <div
          className='cursor-pointer hover:underline'
          onClick={() => {
            setUpgradeDialogOpen(true)
            dispatch('OpenUpgradeDialog', { referrer: 'trialBanner' })
          }}
        >
          {t('layouts.dashboardLayout.upgradeCTA')}
        </div>
      </div>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
