import { DatasetEtlStatus } from '@/gen/firestore'
import { DataLoaderService } from '@/gen/proto/data_loader/data_loader_pb'
import { useAccount } from '@/hooks/useAccount'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useActionTracker } from '@/hooks/useMixpanel'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { getIdToken } from 'firebase/auth'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogWrapper } from './dialogWrapper'

type Props = {
  open: boolean
  handleClose: () => void
}

export const DataSyncDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { shop } = useAccount()

  const authUser = useAuthUser()
  const dataLoaderService = useGrpcClient(DataLoaderService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const [loading, setLoading] = useState(false)

  const isRunning = shop?.datasetEtlStatus === DatasetEtlStatus.RUNNING

  const currentDate = useCurrentDate(shop.timezone)
  const isCompletedRecently =
    shop?.datasetEtlStatus === DatasetEtlStatus.COMPLETED &&
    !!shop?.lastDatasetEtlCompletedAt &&
    currentDate.diff(shop.lastDatasetEtlCompletedAt.toDate(), 'hour') <= 3 // 3 hours

  const mainContentText = isRunning
    ? t('common.dataSyncDialog.description_running')
    : isCompletedRecently
      ? t('common.dataSyncDialog.description_completed')
      : t('common.dataSyncDialog.description')

  const isSubmitButtonDisabled = isRunning || isCompletedRecently

  const handleStartLoadFromShopify = async () => {
    try {
      setLoading(true)
      const token = await getIdToken(authUser!)
      await dataLoaderService.startLoadFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('common.dataSyncDialog.messageSaved'), { severity: 'success' })
      dispatch('ExecuteDataSync')
      handleClose()
    } catch (e) {
      enqueueSnackbar(t('common.dataSyncDialog.messageError'), { severity: 'error' })
      notifySentry(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('common.dataSyncDialog.title')}
      mainContent={<p className='text-sm'>{mainContentText}</p>}
      onSubmit={handleStartLoadFromShopify}
      loading={loading}
      cancelButtonText={t('common.dataSyncDialog.cancel')}
      submitButtonText={t('common.dataSyncDialog.submit')}
      submitButtonDisabled={isSubmitButtonDisabled}
    />
  )
}
