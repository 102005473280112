import { DateRangePicker, DateRangePresetOption } from '@/components/dateRangePicker'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { ProductDimension } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import { cn } from '@/lib/utils'
import { CalendarPlus, Check, ChevronsUpDown } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  dimension: ProductDimension
  value: string
  productOptions: string[] | undefined
  productTypeOptions: string[] | undefined
  dateRangePresetOption: DateRangePresetOption
  setDimension: (dimension: ProductDimension) => void
  setValue: (value: string) => void
  setStartDate: (startDate: string) => void
  setEndDate: (endDate: string) => void
}

export const ProductFilter: FC<Props> = ({
  dimension,
  value,
  productOptions,
  productTypeOptions,
  dateRangePresetOption,
  setDimension,
  setValue,
  setStartDate,
  setEndDate,
}) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const { dispatch } = useActionTracker()

  const [openCombobox, setOpenCombobox] = useState(false)
  const [isDateRangeFilterOpen, setIsDateRangeFilterOpen] = useState<boolean>(false)

  return (
    <div className='flex items-center gap-3'>
      <Select
        value={dimension}
        onValueChange={(value) => {
          setDimension(value as ProductDimension)
          setValue('')
        }}
      >
        <SelectTrigger className='w-[160px]'>
          <SelectValue placeholder={t('features.productAnalysis.selectValue')} />
        </SelectTrigger>
        <SelectContent>
          {Object.values(ProductDimension).map((dimension) => (
            <SelectItem key={dimension} value={dimension}>
              {t(`features.productAnalysis.${dimension}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {dimension === ProductDimension.product_name && (
        <Popover open={openCombobox} onOpenChange={setOpenCombobox}>
          <PopoverTrigger asChild>
            <Button variant='outline' role='combobox' aria-expanded={openCombobox} className='min-w-[240px] justify-between'>
              {value ? productOptions?.find((option) => option === value) : t('features.productAnalysis.selectValue')}
              <ChevronsUpDown className='opacity-50' />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='min-w-[240px] p-0'>
            <Command>
              <CommandInput placeholder={t('features.productAnalysis.selectValue')} className='h-9' />
              <CommandList>
                <CommandEmpty>{t('features.productAnalysis.noResults')}</CommandEmpty>
                <CommandGroup>
                  {productOptions?.map((option) => (
                    <CommandItem
                      key={option}
                      value={option}
                      onSelect={(currentValue) => {
                        setValue(currentValue === value ? '' : currentValue)
                        dispatch('FilterProductQuery', { name: `${dimension}-${value}` }) // Send mixpanel event
                        setOpenCombobox(false)
                      }}
                    >
                      {option}
                      <Check className={cn('ml-auto', value === option ? 'opacity-100' : 'opacity-0')} />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      )}

      {dimension === ProductDimension.product_type && (
        <Popover open={openCombobox} onOpenChange={setOpenCombobox}>
          <PopoverTrigger asChild>
            <Button variant='outline' role='combobox' aria-expanded={openCombobox} className='min-w-[240px] justify-between'>
              {value ? productTypeOptions?.find((option) => option === value) : t('features.productAnalysis.selectValue')}
              <ChevronsUpDown className='opacity-50' />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='min-w-[240px] p-0'>
            <Command>
              <CommandInput placeholder={t('features.productAnalysis.selectValue')} className='h-9' />
              <CommandList>
                <CommandEmpty>{t('features.productAnalysis.noResults')}</CommandEmpty>
                <CommandGroup>
                  {productTypeOptions?.map((option) => (
                    <CommandItem
                      key={option}
                      value={option}
                      onSelect={(currentValue) => {
                        setValue(currentValue === value ? '' : currentValue)
                        dispatch('FilterProductQuery', { name: `${dimension}-${value}` }) // Send mixpanel event
                        setOpenCombobox(false)
                      }}
                    >
                      {option}
                      <Check className={cn('ml-auto', value === option ? 'opacity-100' : 'opacity-0')} />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      )}

      {dimension === ProductDimension.product_id && (
        <Input
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            dispatch('FilterProductQuery', { name: `${dimension}-${e.target.value}` }) // Send mixpanel event
          }}
          placeholder={t('features.productAnalysis.inputProductId')}
          className='w-[240px]'
        />
      )}

      {isDateRangeFilterOpen ? (
        <DateRangePicker
          maxEndDate={currentDate}
          dateRangePresetOption={dateRangePresetOption}
          onApplyDateRangeChange={(dateRange) => {
            const startDate = dateRange.startDate.format('YYYY-MM-DD')
            const endDate = dateRange.endDate.format('YYYY-MM-DD')
            setStartDate(startDate)
            setEndDate(endDate)
            dispatch('ChangeProductPeriod', { name: `${startDate}/${endDate}` }) // Send mixpanel event
          }}
        />
      ) : (
        <TooltipIconButton
          icon={CalendarPlus}
          tooltipContent={t('features.productAnalysis.addDateFilter')}
          variant='ghost'
          onClick={() => setIsDateRangeFilterOpen(true)}
        />
      )}
    </div>
  )
}
