import { SkeletonTable } from '@/components/skeletonTable'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { ProductDimension, ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import { EmptyState } from 'components/emptyState'
import { ChevronDown, Download, LoaderCircle, ShoppingCart, UserRoundSearch } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAffinityProductsDownload } from '../hooks/useAffinityProductsDownload'
import { AffinityProduct, ProductAffinityType } from '../types'
import { generateCustomerAnalysisPath } from '../utils/customerAnalysis'
import { generateOrderAnalysisPath } from '../utils/orderAnalysis'

type Props = {
  type: ProductAffinityType
  setType: (type: ProductAffinityType) => void
  dimension: ProductDimension
  value: string
  startDate: string
  endDate: string
  values: AffinityProduct[] | undefined
  loading: boolean
}

export const AffinityProductsTable: FC<Props> = ({ type, setType, dimension, value, startDate, endDate, values, loading }) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { dispatch } = useActionTracker()

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const { handleDownload, loading: downloadLoading } = useAffinityProductsDownload({ dimension, value, startDate, endDate, type })

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <CardTitle>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className='flex items-center gap-2 cursor-pointer hover:opacity-70'>
                    {t(`features.productAnalysis.title_${type}`)}
                    <Button variant='ghost' size='icon'>
                      <ChevronDown />
                    </Button>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {Object.values(ProductAffinityType).map((type) => (
                    <DropdownMenuItem
                      key={type}
                      onClick={() => {
                        setType(type)
                        dispatch('SwitchProductTable', { name: type })
                      }}
                    >
                      {t(`features.productAnalysis.title_${type}`)}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </CardTitle>
            <div className='flex items-center gap-2'>
              <TooltipIconButton
                icon={downloadLoading ? LoaderCircle : Download}
                className={downloadLoading ? 'animate-spin' : ''}
                tooltipContent={t('features.customerAnalysis.download')}
                variant='ghost'
                disabled={!values || values.length === 0 || loading || downloadLoading}
                onClick={() => {
                  if (shopBillingStatus === ShopBillingStatus.free) {
                    setUpgradeDialogOpen(true)
                    dispatch('OpenUpgradeDialog', { referrer: `productAnalysis_affinityProducts_${type}_csv` })
                    return
                  }
                  handleDownload()
                }}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {loading ? (
            <SkeletonTable columnCount={10} rowCount={10} />
          ) : values && values.length === 0 ? (
            <div className='flex justify-center items-center h-[320px]'>
              <EmptyState title={t('features.productAnalysis.noData')} />
            </div>
          ) : (
            <Table>
              {values && values.length === 100 && <TableCaption>{t('features.productAnalysis.alert_products')}</TableCaption>}
              <TableHeader>
                <TableRow>
                  <TableHead>{t('features.productAnalysis.name')}</TableHead>
                  <TableHead className='text-center'>{t('features.productAnalysis.orderCount')}</TableHead>
                  <TableHead className='text-center'>{t('features.productAnalysis.avgDaysBetweenOrders')}</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {values &&
                  values.map((value) => (
                    <TableRow key={value.name}>
                      <TableCell>
                        {value.visible ? (
                          value.name
                        ) : (
                          <Tooltip>
                            <TooltipTrigger>
                              <Skeleton className='w-[120px] h-4' />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{t('features.productAnalysis.invisible')}</p>
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className='text-center'>{value.orderCount}</TableCell>
                      <TableCell className='text-center'>{value.avgDaysBetweenOrders}</TableCell>
                      <TableCell className='text-right'>
                        <TooltipIconButton
                          icon={UserRoundSearch}
                          tooltipContent={t('features.productAnalysis.viewCustomerAnalysis')}
                          variant='ghost'
                          disabled={!value.visible}
                          onClick={() => {
                            const path = generateCustomerAnalysisPath(dimension, value.name, startDate, endDate)
                            window.open(path, '_blank')
                            dispatch('ClickProductViewCustomer', { name: `${type}-${value.name}` })
                          }}
                        />
                        <TooltipIconButton
                          icon={ShoppingCart}
                          tooltipContent={t('features.productAnalysis.viewOrderAnalysis')}
                          variant='ghost'
                          disabled={!value.visible}
                          onClick={() => {
                            const path = generateOrderAnalysisPath(dimension, value.name, startDate, endDate)
                            window.open(path, '_blank')
                            dispatch('ClickProductViewOrder', { name: `${type}-${value.name}` })
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
