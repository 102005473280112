import { CustomerAnalysisService } from '@/gen/proto/customer_analysis/customer_analysis_pb'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerMetrics } from '../types'

export const useMetrics = (querySetString: string) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerAnalysisService = useGrpcClient(CustomerAnalysisService)
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  const [metrics, setMetrics] = useState<CustomerMetrics>()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerAnalysisService.getMetrics({ querySet: querySetString }, { headers: { Authorization: `Bearer ${token}` } })
        setMetrics({
          count: Number(resp.count),
          repeaterRate: Number(resp.repeaterRate),
          ltv: Number(resp.ltv),
          avgOrderValue: Number(resp.avgOrderValue),
          avgOrderCount: Number(resp.avgOrderCount),
          avgDaysBetweenOrders: Number(resp.avgDaysBetweenOrders),
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return { metrics, loading }
}
