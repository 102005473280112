import { z } from 'zod'
import { OP_EQUAL, OP_INT_RANGE } from './const'
import { QuerySetApiState, querySet as apiQuerySetSchema, queryItem as apiQueryItem, intRangeValue } from './querySetApiSchema'
import { QuerySetFormState, querySet as formQuerySetSchema, queryItem as formQueryItem, frequencyValue } from './querySetFormSchema'

type apiQueryItem = z.infer<typeof apiQueryItem>
type formQueryItem = z.infer<typeof formQueryItem>

// QuerySetFormState -> QuerySetApiState
export const convertFormToQuerySet = (querySetForm: QuerySetFormState): QuerySetApiState => {
  const queryItems: apiQueryItem[] = querySetForm.query_items.map((queryItem) => {
    if (queryItem.dimension === 'frequency') {
      const queryItemForFrequency = convertFormToQuerySetForFrequency(queryItem)
      return queryItemForFrequency
    } else {
      return queryItem
    }
  })

  const result = {
    join_operator: querySetForm.join_operator,
    query_items: queryItems,
  }

  return apiQuerySetSchema.parse(result)
}

const convertFormToQuerySetForFrequency = (queryItem: formQueryItem): apiQueryItem => {
  let transformedValue: z.infer<typeof intRangeValue>
  if (queryItem.value === 'new') {
    transformedValue = { min: null, max: 1 }
  } else if (queryItem.value === 'repeat') {
    transformedValue = { min: 2, max: null }
  } else {
    throw new Error(`Unexpected frequency value: ${queryItem.value}`)
  }
  return {
    dimension: 'frequency',
    operator: OP_INT_RANGE,
    value: transformedValue,
  }
}

// string -> QuerySetApiState
export const convertStringToApiQuerySetState = (jsonString: string): QuerySetApiState => {
  try {
    const querySetState: QuerySetApiState = apiQuerySetSchema.parse(JSON.parse(jsonString))
    return querySetState
  } catch (error) {
    console.error('json:', jsonString)
    throw error
  }
}

// QuerySetApiState -> QuerySetFormState
export const convertQuerySetToForm = (querySet: QuerySetApiState): QuerySetFormState => {
  const queryItems = querySet.query_items.map((queryItem) => {
    if (queryItem.dimension === 'frequency') {
      const queryItemForFrequency = convertQuerySetToFormForFrequency(queryItem)
      return queryItemForFrequency
    }
    return queryItem
  })

  const result = { join_operator: querySet.join_operator, query_items: queryItems }
  return formQuerySetSchema.parse(result)
}

const convertQuerySetToFormForFrequency = (queryItem: apiQueryItem): formQueryItem => {
  if (queryItem.dimension !== 'frequency') {
    throw new Error('Accepts only Frequency dimension conversions')
  }

  let transformedValue: z.infer<typeof frequencyValue>

  if (!queryItem.value.min && queryItem.value.max === 1) {
    transformedValue = 'new'
  } else if (queryItem.value.min === 2 && !queryItem.value.max) {
    transformedValue = 'repeat'
  } else {
    throw new Error(`Unexpected frequency value: ${JSON.stringify(queryItem.value)}`)
  }

  return {
    dimension: 'frequency',
    operator: OP_EQUAL,
    value: transformedValue,
  }
}
