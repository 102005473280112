import { DialogWrapper } from '@/components/dialogWrapper'
import { zodResolver } from '@hookform/resolvers/zod'
import { Autocomplete, Checkbox, Chip, FormControl, TextField } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { SegmentGroup } from 'gen/firestore'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  group: SegmentGroup
  handleClose: () => void
}

export const AddSegmentDialog: FC<Props> = ({ open, group, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const segmentGroupService = useGrpcClient(SegmentGroupService)

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data, loading } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const customerSegments = useMemo(() => {
    const segmentRefs = group.customerSegmentRefs || []
    return data?.filter((cs) => !segmentRefs.some((ref) => ref.id === cs.ref.id)) || []
  }, [data, group.customerSegmentRefs])

  const input = z.object({
    customerSegmentIds: z.array(z.string()).min(1, t('features.customerSegments.addSegmentDialog.messageValidationSegmentListMin')),
  })

  type InputSchema = z.infer<typeof input>

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(input),
    defaultValues: {
      customerSegmentIds: [],
    },
  })

  const addSegments = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.addSegments(
        {
          segmentGroupId: group.ref.id,
          customerSegmentIds: input.customerSegmentIds,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.addSegmentDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.addSegmentDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.addSegmentDialog.title')}
      mainContent={
        <Controller
          control={control}
          name='customerSegmentIds'
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl fullWidth>
                <Autocomplete
                  {...field}
                  multiple
                  disableCloseOnSelect
                  loading={loading}
                  limitTags={3}
                  disabled={!customerSegments}
                  options={customerSegments || []}
                  getOptionLabel={(cl) => cl.name}
                  onChange={(_, newValue) => {
                    setValue(
                      'customerSegmentIds',
                      newValue.map((item) => item.ref.id)
                    )
                  }}
                  value={customerSegments?.filter((cl) => field.value.includes(cl.ref.id)) || []}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ padding: '0px 8px' }}>
                      <Checkbox size='small' style={{ marginRight: '8px' }} checked={selected} />
                      <span style={{ fontSize: '14px' }}>{option.name}</span>
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    // eslint-disable-next-line react/jsx-key
                    value.map((option, index) => <Chip size='small' label={option.name} {...getTagProps({ index })} sx={{ borderRadius: '4px' }} />)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(error)}
                      helperText={error?.message}
                      label={t('features.customerSegments.addSegmentDialog.fieldCustomerSegments')}
                      size='small'
                    />
                  )}
                />
              </FormControl>
            )
          }}
        />
      }
      onSubmit={handleSubmit(addSegments)}
      loading={isSubmitting}
      cancelButtonText={t('features.customerSegments.addSegmentDialog.cancel')}
      submitButtonText={t('features.customerSegments.addSegmentDialog.submit')}
    />
  )
}
