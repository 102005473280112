// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/segment_group/segment_group.proto (package segment_group, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/segment_group/segment_group.proto.
 */
export const file_proto_segment_group_segment_group: GenFile = /*@__PURE__*/
  fileDesc("Cidwcm90by9zZWdtZW50X2dyb3VwL3NlZ21lbnRfZ3JvdXAucHJvdG8SDXNlZ21lbnRfZ3JvdXAiHQoNQ3JlYXRlUmVxdWVzdBIMCgRuYW1lGAEgASgJIigKDkNyZWF0ZVJlc3BvbnNlEhYKDnNlZ21lbnRHcm91cElkGAEgASgJIjUKDVVwZGF0ZVJlcXVlc3QSFgoOc2VnbWVudEdyb3VwSWQYASABKAkSDAoEbmFtZRgCIAEoCSIQCg5VcGRhdGVSZXNwb25zZSInCg1EZWxldGVSZXF1ZXN0EhYKDnNlZ21lbnRHcm91cElkGAEgASgJIhAKDkRlbGV0ZVJlc3BvbnNlIkgKEkFkZFNlZ21lbnRzUmVxdWVzdBIWCg5zZWdtZW50R3JvdXBJZBgBIAEoCRIaChJjdXN0b21lclNlZ21lbnRJZHMYAiADKAkiFQoTQWRkU2VnbWVudHNSZXNwb25zZSJLChVSZW1vdmVTZWdtZW50c1JlcXVlc3QSFgoOc2VnbWVudEdyb3VwSWQYASABKAkSGgoSY3VzdG9tZXJTZWdtZW50SWRzGAIgAygJIhgKFlJlbW92ZVNlZ21lbnRzUmVzcG9uc2UiVQodVXBkYXRlU2VnbWVudHNTZXF1ZW5jZVJlcXVlc3QSFgoOc2VnbWVudEdyb3VwSWQYASABKAkSHAoUc2VnbWVudElkc0luU2VxdWVuY2UYAiADKAkiIAoeVXBkYXRlU2VnbWVudHNTZXF1ZW5jZVJlc3BvbnNlMqIEChNTZWdtZW50R3JvdXBTZXJ2aWNlEkcKBkNyZWF0ZRIcLnNlZ21lbnRfZ3JvdXAuQ3JlYXRlUmVxdWVzdBodLnNlZ21lbnRfZ3JvdXAuQ3JlYXRlUmVzcG9uc2UiABJHCgZVcGRhdGUSHC5zZWdtZW50X2dyb3VwLlVwZGF0ZVJlcXVlc3QaHS5zZWdtZW50X2dyb3VwLlVwZGF0ZVJlc3BvbnNlIgASRwoGRGVsZXRlEhwuc2VnbWVudF9ncm91cC5EZWxldGVSZXF1ZXN0Gh0uc2VnbWVudF9ncm91cC5EZWxldGVSZXNwb25zZSIAElYKC0FkZFNlZ21lbnRzEiEuc2VnbWVudF9ncm91cC5BZGRTZWdtZW50c1JlcXVlc3QaIi5zZWdtZW50X2dyb3VwLkFkZFNlZ21lbnRzUmVzcG9uc2UiABJfCg5SZW1vdmVTZWdtZW50cxIkLnNlZ21lbnRfZ3JvdXAuUmVtb3ZlU2VnbWVudHNSZXF1ZXN0GiUuc2VnbWVudF9ncm91cC5SZW1vdmVTZWdtZW50c1Jlc3BvbnNlIgASdwoWVXBkYXRlU2VnbWVudHNTZXF1ZW5jZRIsLnNlZ21lbnRfZ3JvdXAuVXBkYXRlU2VnbWVudHNTZXF1ZW5jZVJlcXVlc3QaLS5zZWdtZW50X2dyb3VwLlVwZGF0ZVNlZ21lbnRzU2VxdWVuY2VSZXNwb25zZSIAQjxaOmVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9zZWdtZW50X2dyb3VwO3NlZ21lbnRfZ3JvdXBfcGJiBnByb3RvMw");

/**
 * Create
 *
 * @generated from message segment_group.CreateRequest
 */
export type CreateRequest = Message<"segment_group.CreateRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message segment_group.CreateRequest.
 * Use `create(CreateRequestSchema)` to create a new message.
 */
export const CreateRequestSchema: GenMessage<CreateRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 0);

/**
 * @generated from message segment_group.CreateResponse
 */
export type CreateResponse = Message<"segment_group.CreateResponse"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;
};

/**
 * Describes the message segment_group.CreateResponse.
 * Use `create(CreateResponseSchema)` to create a new message.
 */
export const CreateResponseSchema: GenMessage<CreateResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 1);

/**
 * Update
 *
 * @generated from message segment_group.UpdateRequest
 */
export type UpdateRequest = Message<"segment_group.UpdateRequest"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;

  /**
   * @generated from field: string name = 2;
   */
  name: string;
};

/**
 * Describes the message segment_group.UpdateRequest.
 * Use `create(UpdateRequestSchema)` to create a new message.
 */
export const UpdateRequestSchema: GenMessage<UpdateRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 2);

/**
 * @generated from message segment_group.UpdateResponse
 */
export type UpdateResponse = Message<"segment_group.UpdateResponse"> & {
};

/**
 * Describes the message segment_group.UpdateResponse.
 * Use `create(UpdateResponseSchema)` to create a new message.
 */
export const UpdateResponseSchema: GenMessage<UpdateResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 3);

/**
 * Delete
 *
 * @generated from message segment_group.DeleteRequest
 */
export type DeleteRequest = Message<"segment_group.DeleteRequest"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;
};

/**
 * Describes the message segment_group.DeleteRequest.
 * Use `create(DeleteRequestSchema)` to create a new message.
 */
export const DeleteRequestSchema: GenMessage<DeleteRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 4);

/**
 * @generated from message segment_group.DeleteResponse
 */
export type DeleteResponse = Message<"segment_group.DeleteResponse"> & {
};

/**
 * Describes the message segment_group.DeleteResponse.
 * Use `create(DeleteResponseSchema)` to create a new message.
 */
export const DeleteResponseSchema: GenMessage<DeleteResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 5);

/**
 * AddSegments
 *
 * @generated from message segment_group.AddSegmentsRequest
 */
export type AddSegmentsRequest = Message<"segment_group.AddSegmentsRequest"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;

  /**
   * @generated from field: repeated string customerSegmentIds = 2;
   */
  customerSegmentIds: string[];
};

/**
 * Describes the message segment_group.AddSegmentsRequest.
 * Use `create(AddSegmentsRequestSchema)` to create a new message.
 */
export const AddSegmentsRequestSchema: GenMessage<AddSegmentsRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 6);

/**
 * @generated from message segment_group.AddSegmentsResponse
 */
export type AddSegmentsResponse = Message<"segment_group.AddSegmentsResponse"> & {
};

/**
 * Describes the message segment_group.AddSegmentsResponse.
 * Use `create(AddSegmentsResponseSchema)` to create a new message.
 */
export const AddSegmentsResponseSchema: GenMessage<AddSegmentsResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 7);

/**
 * RemoveSegments
 *
 * @generated from message segment_group.RemoveSegmentsRequest
 */
export type RemoveSegmentsRequest = Message<"segment_group.RemoveSegmentsRequest"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;

  /**
   * @generated from field: repeated string customerSegmentIds = 2;
   */
  customerSegmentIds: string[];
};

/**
 * Describes the message segment_group.RemoveSegmentsRequest.
 * Use `create(RemoveSegmentsRequestSchema)` to create a new message.
 */
export const RemoveSegmentsRequestSchema: GenMessage<RemoveSegmentsRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 8);

/**
 * @generated from message segment_group.RemoveSegmentsResponse
 */
export type RemoveSegmentsResponse = Message<"segment_group.RemoveSegmentsResponse"> & {
};

/**
 * Describes the message segment_group.RemoveSegmentsResponse.
 * Use `create(RemoveSegmentsResponseSchema)` to create a new message.
 */
export const RemoveSegmentsResponseSchema: GenMessage<RemoveSegmentsResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 9);

/**
 * UpdateSegmentsSequence
 *
 * @generated from message segment_group.UpdateSegmentsSequenceRequest
 */
export type UpdateSegmentsSequenceRequest = Message<"segment_group.UpdateSegmentsSequenceRequest"> & {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId: string;

  /**
   * @generated from field: repeated string segmentIdsInSequence = 2;
   */
  segmentIdsInSequence: string[];
};

/**
 * Describes the message segment_group.UpdateSegmentsSequenceRequest.
 * Use `create(UpdateSegmentsSequenceRequestSchema)` to create a new message.
 */
export const UpdateSegmentsSequenceRequestSchema: GenMessage<UpdateSegmentsSequenceRequest> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 10);

/**
 * @generated from message segment_group.UpdateSegmentsSequenceResponse
 */
export type UpdateSegmentsSequenceResponse = Message<"segment_group.UpdateSegmentsSequenceResponse"> & {
};

/**
 * Describes the message segment_group.UpdateSegmentsSequenceResponse.
 * Use `create(UpdateSegmentsSequenceResponseSchema)` to create a new message.
 */
export const UpdateSegmentsSequenceResponseSchema: GenMessage<UpdateSegmentsSequenceResponse> = /*@__PURE__*/
  messageDesc(file_proto_segment_group_segment_group, 11);

/**
 * @generated from service segment_group.SegmentGroupService
 */
export const SegmentGroupService: GenService<{
  /**
   * @generated from rpc segment_group.SegmentGroupService.Create
   */
  create: {
    methodKind: "unary";
    input: typeof CreateRequestSchema;
    output: typeof CreateResponseSchema;
  },
  /**
   * @generated from rpc segment_group.SegmentGroupService.Update
   */
  update: {
    methodKind: "unary";
    input: typeof UpdateRequestSchema;
    output: typeof UpdateResponseSchema;
  },
  /**
   * @generated from rpc segment_group.SegmentGroupService.Delete
   */
  delete: {
    methodKind: "unary";
    input: typeof DeleteRequestSchema;
    output: typeof DeleteResponseSchema;
  },
  /**
   * @generated from rpc segment_group.SegmentGroupService.AddSegments
   */
  addSegments: {
    methodKind: "unary";
    input: typeof AddSegmentsRequestSchema;
    output: typeof AddSegmentsResponseSchema;
  },
  /**
   * @generated from rpc segment_group.SegmentGroupService.RemoveSegments
   */
  removeSegments: {
    methodKind: "unary";
    input: typeof RemoveSegmentsRequestSchema;
    output: typeof RemoveSegmentsResponseSchema;
  },
  /**
   * @generated from rpc segment_group.SegmentGroupService.UpdateSegmentsSequence
   */
  updateSegmentsSequence: {
    methodKind: "unary";
    input: typeof UpdateSegmentsSequenceRequestSchema;
    output: typeof UpdateSegmentsSequenceResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_segment_group_segment_group, 0);

