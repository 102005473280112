import { CustomerAnalysisService } from '@/gen/proto/customer_analysis/customer_analysis_pb'
import { InsightDimension } from '@/gen/firestore'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  querySetString: string
  dimension: InsightDimension
  fileName: string
  page: string
}

export const useInsightsDownload = ({ querySetString, dimension, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()
  const authUser = useAuthUser()
  const customerAnalysisService = useGrpcClient(CustomerAnalysisService)

  const [loading, setLoading] = useState(false)

  const headers = useMemo(
    () => [
      t('features.customerAnalysis.insights.name'),
      t('features.customerAnalysis.insights.customerCount'),
      t('features.customerAnalysis.insights.ratio'),
    ],
    [t]
  )

  const fetchInsights = useCallback(async () => {
    const token = await getIdToken(authUser!)
    const resp = await customerAnalysisService.getInsights({ querySet: querySetString, dimension }, { headers: { Authorization: `Bearer ${token}` } })
    return resp.insights.map((o) => ({
      name: o.name,
      customerCount: Number(o.customerCount),
      ratio: Number(o.ratio),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true)
      const insights = await fetchInsights()

      // Create CSV content
      const csvContent = [
        headers.join(','), // Add headers
        ...insights.map((insight) => [insight.name, insight.customerCount, insight.ratio].join(',')),
      ].join('\n')

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch('DownloadCSV', { name: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return {
    handleDownload,
    loading,
  }
}
