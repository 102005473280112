"use client"

import * as React from "react"
import { LucideIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button, ButtonProps } from "@/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface TooltipIconButtonProps extends Omit<ButtonProps, 'children'> {
  /**
   * The icon to display in the button
   */
  icon: LucideIcon
  /**
   * The content to display in the tooltip
   */
  tooltipContent: React.ReactNode
  /**
   * Optional className for the icon
   */
  iconClassName?: string
  /**
   * The side of the anchor to render the tooltip
   * @default "top"
   */
  tooltipSide?: "top" | "right" | "bottom" | "left"
  /**
   * The alignment along the anchor
   * @default "center"
   */
  tooltipAlign?: "start" | "center" | "end"
  /**
   * The amount of time in milliseconds to wait before showing the tooltip
   * @default 0
   */
  tooltipDelayDuration?: number
  /**
   * Optional className for the tooltip content
   */
  tooltipContentClassName?: string
}

const TooltipIconButton = React.forwardRef<HTMLButtonElement, TooltipIconButtonProps>(
  ({ 
    className,
    icon: Icon,
    tooltipContent,
    iconClassName,
    tooltipSide = "top",
    tooltipAlign = "center",
    tooltipDelayDuration = 0,
    tooltipContentClassName,
    ...props 
  }, ref) => {
    return (
      <TooltipProvider>
        <Tooltip delayDuration={tooltipDelayDuration}>
          <TooltipTrigger asChild>
            <Button
              ref={ref}
              size="icon"
              className={className}
              {...props}
            >
              <Icon className={iconClassName} />
            </Button>
          </TooltipTrigger>
          <TooltipContent 
            side={tooltipSide} 
            align={tooltipAlign}
            className={cn(tooltipContentClassName)}
          >
            {tooltipContent}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }
)
TooltipIconButton.displayName = "TooltipIconButton"

export { TooltipIconButton }
