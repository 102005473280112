import { Button } from '@/components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { useAccount } from '@/hooks/useAccount'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { Box } from '@mui/material'
import { CircleX, CopyPlus } from 'lucide-react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getDefaultValues } from './defaultValues'
import { QueryItem } from './queryItem/queryItem'
import { QuerySetFormState } from './schema/querySetFormSchema'

export const QuerySetForm = () => {
  const { shop } = useAccount()
  const { t } = useTranslation()
  const currentDate = useCurrentDate(shop.timezone)
  const { defaultAddQueryItem } = getDefaultValues(currentDate)
  const { control } = useFormContext<QuerySetFormState>()
  const { fields, append, remove } = useFieldArray<QuerySetFormState>({
    control: control,
    name: 'query_items',
  })

  return (
    <>
      <Box className='flex flex-col gap-4 mb-6'>
        {fields.map((field, queryItemIndex) => (
          <div key={field.id} className='flex flex-col gap-3'>
            <Controller
              control={control}
              name={`join_operator`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className={`max-w-[100px] hidden`}>
                    <Select onValueChange={field.onChange} defaultValue='and' value={field.value}>
                      <SelectTrigger className={`w-full ${error ? 'border-red-500' : ''}`}>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='and'>{t('features.orderAnalysis.querySet.joinOperator', { context: 'and' })}</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )
              }}
            />
            <div className='flex items-start gap-4'>
              <QueryItem queryItemPath={`query_items.${queryItemIndex}`} />

              {queryItemIndex === 0 ? (
                <TooltipIconButton
                  icon={CircleX}
                  tooltipContent={t('features.orderAnalysis.querySet.messageToolChipMustOrderDate')}
                  variant='ghost'
                  className='rounded-lg opacity-50 cursor-default hover:bg-background'
                />
              ) : (
                <Button variant='ghost' size='icon' className='rounded-lg disabled:opacity-50' onClick={() => remove(queryItemIndex)}>
                  <CircleX />
                </Button>
              )}
            </div>
          </div>
        ))}
      </Box>

      <Button
        variant='secondary'
        size='sm'
        className='flex items-center gap-2 disabled:opacity-50'
        disabled={fields.length >= 10}
        onClick={() => append(defaultAddQueryItem)}
      >
        <CopyPlus />
        {t('features.orderAnalysis.querySet.addQueryItem')}
      </Button>
    </>
  )
}
