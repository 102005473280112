import { ProductDimension } from '@/gen/firestore'
import { AUTHORIZED_ROUTE } from '@/routing'
import { createSearchParams } from 'react-router-dom'
import { OP_CONTAIN_ANY, OP_DATE_RANGE } from '@/features/orderAnalysis/querySetForm/schema/const'
import { convertFormToQuerySet } from '@/features/orderAnalysis/querySetForm/schema/converter'
import { QuerySetFormState } from '@/features/orderAnalysis/querySetForm/schema/querySetFormSchema'

export const generateOrderAnalysisPath = (dimension: ProductDimension, name: string, startDate: string, endDate: string) => {
  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_items: [
      {
        dimension: 'order_date',
        operator: OP_DATE_RANGE,
        value: startDate === '' ? { max_date: endDate } : { min_date: startDate, max_date: endDate },
      },
      {
        dimension: dimension === ProductDimension.product_type ? 'product_type' : 'product_name',
        operator: OP_CONTAIN_ANY,
        value: [name],
      },
    ],
  }

  const queryString = createSearchParams({
    querySet: encodeURIComponent(JSON.stringify(convertFormToQuerySet(querySet))),
  }).toString()

  return `${AUTHORIZED_ROUTE.ORDER_ANALYSIS}?${queryString}`
}
