import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { SidebarGroup } from '@/components/ui/sidebar'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { ShopBillingStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'

type Props = {
  shopBillingStatus: ShopBillingStatus
}

export const UpgradeCard: FC<Props> = ({ shopBillingStatus }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const { dispatch } = useActionTracker()

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const totalTrialDays = timestampToDayjs(shop.trialEndsAt).diff(timestampToDayjs(shop.createdAt), 'day')
  const currentDate = useCurrentDate(shop.timezone)
  const elapsedTrialDays = currentDate.diff(timestampToDayjs(shop.createdAt), 'day')
  const remainingTrialDays = totalTrialDays - elapsedTrialDays

  return (
    <>
      <SidebarGroup className='group-data-[collapsible=icon]:hidden'>
        <Card className='shadow-nonerounded-lg'>
          <CardHeader className='p-4 pb-0'>
            <CardTitle className='text-sm'>{t('layouts.dashboardLayout.upgradeTitle')}</CardTitle>
            {shopBillingStatus === 'trial' && (
              <CardDescription>
                {t('layouts.dashboardLayout.upgradeDescTrial', { date: formatDate(timestampToDayjs(shop.trialEndsAt!), i18n.language) })}
              </CardDescription>
            )}
            {shopBillingStatus === 'free' && <CardDescription>{t('layouts.dashboardLayout.upgradeDescFree')}</CardDescription>}
          </CardHeader>
          <CardContent className='grid gap-2.5 p-4'>
            {shopBillingStatus === 'trial' && (
              <div>
                <Progress value={(elapsedTrialDays / totalTrialDays) * 100} />
                <span className='text-xs text-muted-foreground'>
                  {t('layouts.dashboardLayout.upgradeProgress', { remaining: remainingTrialDays, total: totalTrialDays })}
                </span>
              </div>
            )}
            <Button
              className='w-full bg-sidebar-primary text-sidebar-primary-foreground shadow-none'
              size='sm'
              onClick={() => {
                setUpgradeDialogOpen(true)
                dispatch('OpenUpgradeDialog', { referrer: 'sidebar' })
              }}
            >
              {t('layouts.dashboardLayout.upgradeCTA')}
            </Button>
          </CardContent>
        </Card>
      </SidebarGroup>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
