import { DialogWrapper } from '@/components/dialogWrapper'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, FormControl, FormControlLabel, InputAdornment, TextField } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { z } from 'zod'

type Props = {
  open: boolean
  handleClose: () => void
  customerSegmentId: string
  customerSegmentName: string
}

const TAG_NAME_PREFIX = 'ecp-'
const convertToDefaultTagName = (customerSegmentName: string) => extractTextFromTextWithIcon(customerSegmentName).toLowerCase().replace(/\s+/g, '-')
const addPrefix = (tagName: string) => (tagName.startsWith(TAG_NAME_PREFIX) ? tagName : `${TAG_NAME_PREFIX}${tagName}`)

export const ActivateAutoSyncDialog: FC<Props> = ({ open, handleClose, customerSegmentId, customerSegmentName }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { shop } = useAccount()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const addTagInput = z.object({
    tagName: z
      .string()
      .min(1, { message: t('features.customerSegments.detail.activateAutoSyncDialog.messageValidationTagIsRequired') })
      .max(32, { message: t('features.customerSegments.detail.activateAutoSyncDialog.messageValidationMax') }),
    enableShopifySegmentCreation: z.boolean(),
  })

  type InputSchema = z.infer<typeof addTagInput>

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(addTagInput),
    defaultValues: {
      tagName: convertToDefaultTagName(customerSegmentName),
      enableShopifySegmentCreation: true,
    },
    mode: 'onChange',
  })

  const updateTagOperationSetting = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.updateTagOperationSetting(
        {
          customerSegmentId: customerSegmentId,
          isActive: true,
          tagName: addPrefix(input.tagName),
          enableShopifySegmentCreation: input.enableShopifySegmentCreation,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.detail.activateAutoSyncDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.detail.activateAutoSyncDialog.messageFailed'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.detail.activateAutoSyncDialog.title')}
      description={t('features.customerSegments.detail.activateAutoSyncDialog.description')}
      mainContent={
        <div className='flex flex-col gap-2'>
          <Controller
            control={control}
            name='tagName'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    size='small'
                    label={t('features.customerSegments.detail.activateAutoSyncDialog.fieldTagName')}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>{TAG_NAME_PREFIX}</InputAdornment>,
                    }}
                  />
                </FormControl>
              )
            }}
          />

          <Controller
            control={control}
            name='enableShopifySegmentCreation'
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} size='small' />}
                label={t('features.customerSegments.detail.activateAutoSyncDialog.fieldEnableShopifySegmentCreation')}
                sx={{ marginTop: '8px', '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
              />
            )}
          />
        </div>
      }
      onSubmit={handleSubmit(updateTagOperationSetting)}
      loading={isSubmitting}
      cancelButtonText={t('features.customerSegments.detail.activateAutoSyncDialog.cancel')}
      submitButtonText={t('features.customerSegments.detail.activateAutoSyncDialog.submit')}
      submitButtonDisabled={shop.isDemo}
    />
  )
}
