import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { MonthRangePicker } from '@/components/ui/monthrangepicker'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrency } from '@/hooks/useCurrency'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import { SkeletonTable } from 'components/skeletonTable'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { CalendarIcon, Download, LoaderCircle } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts'
import { formatDate, formatYearMonth } from 'utils/timeUtil'
import { MonthlyCohort } from '../types'
import dayjs from 'dayjs'

type Props = {
  monthlyCohort: MonthlyCohort | undefined
  loading: boolean
  startYearMonth: string
  endYearMonth: string
  setStartYearMonth: (startYearMonth: string) => void
  setEndYearMonth: (endYearMonth: string) => void
}

enum MonthlyCohortMetricsKey {
  LTV = 'ltv',
  AVG_ORDER_COUNT = 'avgOrderCount',
  REPURCHASE_RATE = 'repurchaseRate',
}

export const RepeatTrends: FC<Props> = ({ monthlyCohort, loading, startYearMonth, endYearMonth, setStartYearMonth, setEndYearMonth }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [metricsIndex, setMetricsIndex] = useState<MonthlyCohortMetricsKey>(MonthlyCohortMetricsKey.LTV)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const chartData = useMemo(() => {
    if (!monthlyCohort) return undefined
    return monthlyCohort.overallResult
      .filter((mr) => mr.step > -1)
      .map((mr) => ({
        step: mr.step,
        metric: mr[metricsIndex],
      }))
  }, [monthlyCohort, metricsIndex])

  const chartConfig = {
    metric: {
      label: t(`features.customerAnalysis.repeatTrends.${metricsIndex}`),
      color: 'hsl(var(--chart-7))',
    },
  }

  const getStepValue = (step: number): string => {
    return step === -1 ? t('features.customerAnalysis.repeatTrends.stepFirst') : t('features.customerAnalysis.repeatTrends.stepNth', { step: step })
  }

  const getMetricsValue = (step: number, metrics: number): string => {
    switch (metricsIndex) {
      case MonthlyCohortMetricsKey.LTV:
        return formatCurrency(metrics) || ''
      case MonthlyCohortMetricsKey.AVG_ORDER_COUNT:
        return metrics.toFixed(2)
      case MonthlyCohortMetricsKey.REPURCHASE_RATE:
        // Do not display the Fn repeat rate of the first purchase because it is always 0%
        if (step === -1) return '-'
        return `${(metrics * 100).toFixed(1)}%`
    }
  }

  const getOpacityClassName = (step: number, value: number): string => {
    if (step === -1 && metricsIndex !== MonthlyCohortMetricsKey.LTV) return 'bg-opacity-0'
    const maxMetricValue = monthlyCohort
      ? Math.max(...monthlyCohort.monthlyResult.flatMap((mr) => mr.stepAndMetricsList.map((sm) => sm[metricsIndex])))
      : 0
    const scale = value / maxMetricValue
    if (scale >= 0.8) return 'bg-opacity-100'
    if (scale >= 0.6) return 'bg-opacity-80'
    if (scale >= 0.4) return 'bg-opacity-60'
    if (scale >= 0.2) return 'bg-opacity-40'
    return 'bg-opacity-20'
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data:
      monthlyCohort &&
      monthlyCohort.monthlyResult.flatMap((mr) => {
        return mr.stepAndMetricsList.map((sm) => {
          return {
            firstOrderYearMonth: mr.firstOrderYearMonth,
            step: getStepValue(sm.step),
            ltv: sm.ltv,
            avgOrderCount: sm.avgOrderCount,
            repurchaseRate: sm.repurchaseRate,
          }
        })
      }),
    headers: [
      t('features.customerAnalysis.repeatTrends.firstOrderYearMonth'),
      t('features.customerAnalysis.repeatTrends.step'),
      t('features.customerAnalysis.repeatTrends.ltv'),
      t('features.customerAnalysis.repeatTrends.avgOrderCount'),
      t('features.customerAnalysis.repeatTrends.repurchaseRate'),
    ],
    fileName: t('features.customerAnalysis.repeatTrends.downloadFileName', { date: formatDate(currentDate, i18n.language) }),
    page: 'cohort_monthly',
  })

  return (
    <>
      <Card>
        <CardHeader>
          <div className='flex items-center justify-between'>
            <CardTitle>{t('features.customerAnalysis.repeatTrends.title')}</CardTitle>
            <div className='flex items-center gap-3'>
              <Tabs
                defaultValue={MonthlyCohortMetricsKey.LTV}
                onValueChange={(value) => {
                  setMetricsIndex(value as MonthlyCohortMetricsKey)
                  dispatch('SwitchCohortMetricsKey', { name: value })
                }}
              >
                <TabsList>
                  {Object.values(MonthlyCohortMetricsKey).map((metric) => (
                    <TabsTrigger key={metric} value={metric}>
                      {t(`features.customerAnalysis.repeatTrends.${metric}`)}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
              <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
                <PopoverTrigger asChild>
                  <Button variant='outline' className='justify-start text-left font-normal'>
                    <CalendarIcon className='mr-2 h-4 w-4' />
                    {`${formatYearMonth(dayjs(startYearMonth), i18n.language)} - ${formatYearMonth(dayjs(endYearMonth), i18n.language)}`}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0'>
                  <MonthRangePicker
                    onMonthRangeSelect={({ start, end }) => {
                      if (dayjs(start).isSame(dayjs(end))) return
                      setStartYearMonth(dayjs(start).format('YYYY-MM'))
                      setEndYearMonth(dayjs(end).format('YYYY-MM'))
                      dispatch('ChangeCohortPeriod', { name: `${startYearMonth}/${endYearMonth}` }) // Send mixpanel event
                      setDatePickerOpen(false)
                    }}
                    selectedMonthRange={{ start: dayjs(startYearMonth).toDate(), end: dayjs(endYearMonth).toDate() }}
                    showQuickSelectors={false}
                    minDate={currentDate.subtract(5, 'year').startOf('year').toDate()}
                    maxDate={currentDate.toDate()}
                    locale={i18n.language}
                  />
                </PopoverContent>
              </Popover>
              <div>
                <TooltipIconButton
                  icon={downloadLoading ? LoaderCircle : Download}
                  className={downloadLoading ? 'animate-spin' : ''}
                  tooltipContent={t('features.customerAnalysis.repeatTrends.download')}
                  variant='ghost'
                  disabled={downloadLoading || !monthlyCohort || monthlyCohort.overallResult.length === 0}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeDialogOpen(true)
                      dispatch('OpenUpgradeDialog', { referrer: 'customerAnalysis_repeatTrends_csv' })
                      return
                    }
                    handleDownload()
                  }}
                />
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {loading || !monthlyCohort ? (
            <div className='flex justify-center items-center h-[320px]'>
              <LoaderCircle className='animate-spin' />
            </div>
          ) : (
            <ChartContainer config={chartConfig} className='h-[320px] w-full'>
              <AreaChart accessibilityLayer data={chartData}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey='step' tickLine={false} axisLine={false} interval='preserveStartEnd' tickFormatter={(tick) => `M${tick}`} />
                <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='line' />} />
                <Area dataKey='metric' type='natural' fill='url(#colorMetric)' stroke='var(--color-metric)' />
                <defs>
                  <linearGradient id='colorMetric' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='0%' stopColor='var(--color-metric)' stopOpacity={0.8} />
                    <stop offset='100%' stopColor='var(--color-metric)' stopOpacity={0.2} />
                  </linearGradient>
                </defs>
              </AreaChart>
            </ChartContainer>
          )}

          {monthlyCohort && monthlyCohort.overallResult.length > 0 && <Separator className='my-8' />}

          {loading || !monthlyCohort ? (
            <SkeletonTable rowCount={15} columnCount={14} />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell className='min-w-[120px]'>{t('features.customerAnalysis.repeatTrends.firstOrderYearMonth')}</TableCell>
                  <TableCell className='min-w-[80px] text-center'>{t('features.customerAnalysis.repeatTrends.customerCount')}</TableCell>
                  {monthlyCohort.overallResult.map((ar) => (
                    <TableCell key={`monthlyCohort-${ar.step}`} className='text-center'>
                      {getStepValue(ar.step)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {monthlyCohort.monthlyResult.map((mr) => (
                  <TableRow key={`monthlyCohort-${mr.firstOrderYearMonth}`}>
                    <TableCell>{mr.firstOrderYearMonth}</TableCell>
                    <TableCell className='text-center'>{mr.stepAndMetricsList[0].customerCount}</TableCell>
                    {mr.stepAndMetricsList.map((sm) => (
                      <TableCell
                        key={`monthlyCohort-${mr.firstOrderYearMonth}-${sm.step}`}
                        className={`text-center bg-indigo-200 ${getOpacityClassName(sm.step, sm[metricsIndex])}`}
                      >
                        {getMetricsValue(sm.step, sm[metricsIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow className='bg-gray-50'>
                  <TableCell>{t('features.customerAnalysis.repeatTrends.all')}</TableCell>
                  <TableCell className='text-center'>{monthlyCohort.overallResult[0].customerCount}</TableCell>
                  {monthlyCohort.overallResult.map((ar) => (
                    <TableCell
                      key={`monthlyCohort-avg-${ar.step}`}
                      className={`text-center bg-indigo-300 ${getOpacityClassName(ar.step, ar[metricsIndex])}`}
                    >
                      {getMetricsValue(ar.step, ar[metricsIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
