import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OP_CONTAIN_ANY, OP_NOT_CONTAIN } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { DimensionOptionsContext } from '../../context/dimensionOptionsContext'
import { AutoCompleteValue } from '../values/autoCompleteValue'
import { QueryItemProps } from './type'

export const CouponQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { data } = useContext(DimensionOptionsContext)
  const options = data.coupon

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[140px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.orderAnalysis.querySet.op', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_CONTAIN_ANY}>{t('features.orderAnalysis.querySet.op', { context: OP_CONTAIN_ANY })}</SelectItem>
                    <SelectItem value={OP_NOT_CONTAIN}>{t('features.orderAnalysis.querySet.op', { context: OP_NOT_CONTAIN })}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      <AutoCompleteValue queryItemValuePath={`${queryItemPath}.value`} options={options} />
    </>
  )
}
