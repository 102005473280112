import { cn } from '@/lib/utils'

import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { buildErrorMessage, QuerySetFormState } from '../schema/querySetFormSchema'
import { useTranslation } from 'react-i18next'
import { CustomInput } from '@/components/ui/input-custom'

type Props = {
  queryItemValuePath: `query_subsets.${number}.query_items.${number}.value`
  inputAdornmentString: string
  inputPropsMaxValue: number
  inputPropsMinValue: number
}

export const IntValue: FC<Props> = ({ queryItemValuePath, inputAdornmentString, inputPropsMaxValue, inputPropsMinValue }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()

  // todo: add code for F0
  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className='min-w-[120px] mr-2 flex flex-col gap-1'>
            <CustomInput
              type='number'
              value={typeof field.value === 'number' ? field.value : ''}
              onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
              className={cn(error && 'border-red-500')}
              min={inputPropsMinValue}
              max={inputPropsMaxValue}
              endAdornment={inputAdornmentString}
            />
            {error && error.message && <div className='text-red-500 text-xs'>{buildErrorMessage(t, error.message) as string}</div>}
          </div>
        )
      }}
    />
  )
}
