import { CustomerAnalysisService } from '@/gen/proto/customer_analysis/customer_analysis_pb'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerState } from '../types'

export const useCustomers = (querySetString: string, limit: number) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerAnalysisService = useGrpcClient(CustomerAnalysisService)
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  const [customers, setCustomers] = useState<CustomerState[]>()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerAnalysisService.getCustomers(
          { querySet: querySetString, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const customers = resp.customers.map((c) => ({
          id: c.id,
          email: c.email,
          name: t('common.customer.fullName', { firstName: c.firstName, lastName: c.lastName }),
          recency: c.recency !== undefined ? Number(c.recency) : undefined,
          frequency: Number(c.frequency),
          monetary: Number(c.monetary),
        }))
        setCustomers(customers)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return { customers, loading }
}
