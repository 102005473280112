import { OrderAnalysisService } from '@/gen/proto/order_analysis/order_analysis_pb'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { getIdToken } from '@firebase/auth'
import dayjs from 'dayjs'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderState } from '../types'

export const useOrders = (querySetString: string, limit: number) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const orderAnalysisService = useGrpcClient(OrderAnalysisService)
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  const [orders, setOrders] = useState<OrderState[]>()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await orderAnalysisService.getOrders(
          { querySet: querySetString, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const orders = resp.orders.map((order) => {
          return {
            id: order.id,
            name: order.name,
            amount: Number(order.amount),
            orderAt: dayjs(order.orderAt),
            referrer: order.referrer,
            channel: order.channel,
            coupon: order.coupon,
          }
        })
        setOrders(orders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString])

  return { orders, loading }
}
