import { SkeletonTable } from '@/components/skeletonTable'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus } from '@/config/plan'
import { CustomerDrawer } from '@/features/customers/customerDrawer'
import { ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useCurrency } from '@/hooks/useCurrency'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useActionTracker } from '@/hooks/useMixpanel'
import { EmptyState } from 'components/emptyState'
import { Download, Info, LoaderCircle, UserRoundSearch } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { useCustomersDownload } from '../hooks/useCustomersDownload'
import { CustomerMetrics, CustomerState } from '../types'

type Props = {
  querySetString: string
  metrics: CustomerMetrics | undefined
  customers: CustomerState[] | undefined
  metricsLoading: boolean
  customersLoading: boolean
}

type CustomerDrawerState = {
  open: boolean
  customer:
    | {
        id: string
        name: string
      }
    | undefined
}

const defaultCustomerDrawerState: CustomerDrawerState = { open: false, customer: undefined }

export const Overview: FC<Props> = ({ querySetString, metrics, customers, metricsLoading, customersLoading }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>(defaultCustomerDrawerState)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const metricsItems = [
    { key: 'count', value: metrics?.count.toLocaleString() },
    { key: 'repeaterRate', value: metrics && `${(metrics.repeaterRate * 100).toFixed(1)}%` },
    { key: 'ltv', value: metrics && formatCurrency(metrics.ltv) },
    { key: 'avgOrderValue', value: metrics && formatCurrency(metrics.avgOrderValue) },
    { key: 'avgOrderCount', value: metrics?.avgOrderCount.toFixed(2) },
    { key: 'avgDaysBetweenOrders', value: metrics?.avgDaysBetweenOrders.toFixed(1) },
  ]

  const { handleDownload: handleDownloadCustomers, loading: downloadCustomersLoading } = useCustomersDownload({
    querySetString,
    fileName: t('features.customerAnalysis.overview.downloadFileName_customers', { date: formatDate(currentDate, i18n.language) }),
    page: 'customerAnalysis_customers',
  })

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-3 xl:grid-cols-6 gap-3'>
          {metricsItems.map((item, index) => (
            <div className='col-span-1' key={`${item.key}-${index}`}>
              <Card>
                <CardHeader>
                  <div className='flex items-center gap-2'>
                    <CardTitle className='text-sm'>{t('features.customerAnalysis.customerMetrics.title', { context: item.key })}</CardTitle>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Info className='w-[13px] h-[13px]' />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{t('features.customerAnalysis.customerMetrics.desc', { context: item.key })}</p>
                        {(item.key === 'ltv' || item.key === 'avgOrderValue') && (
                          <p>
                            {t('features.customerAnalysis.customerMetrics.orderPricingRule')}
                            {t('features.settings.workspace.orderPricingRule', { context: shop.orderPricingRule })}
                          </p>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </CardHeader>
                <CardContent>
                  {!metricsLoading && item.value ? <p className='text-xl'>{item.value}</p> : <Skeleton className='h-4 w-full' />}
                </CardContent>
              </Card>
            </div>
          ))}
        </div>

        <Card>
          <CardHeader>
            <div className='flex items-center justify-between'>
              <CardTitle>{t('features.customerAnalysis.overview.title_customers')}</CardTitle>
              <div className='flex items-center gap-2'>
                <TooltipIconButton
                  icon={downloadCustomersLoading ? LoaderCircle : Download}
                  className={downloadCustomersLoading ? 'animate-spin' : ''}
                  tooltipContent={t('features.customerAnalysis.download')}
                  variant='ghost'
                  disabled={!customers || customers.length === 0 || downloadCustomersLoading}
                  onClick={() => {
                    if (shopBillingStatus === ShopBillingStatus.free) {
                      setUpgradeDialogOpen(true)
                      dispatch('OpenUpgradeDialog', { referrer: 'customerAnalysis_overview_customers_csv' })
                      return
                    }
                    handleDownloadCustomers()
                  }}
                />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {customersLoading || !customers ? (
              <SkeletonTable columnCount={10} rowCount={10} />
            ) : customers.length === 0 ? (
              <div className='flex justify-center items-center h-[320px]'>
                <EmptyState title={t('features.customerAnalysis.overview.empty_customers')} />
              </div>
            ) : (
              <Table>
                {customers.length === 100 && <TableCaption>{t('features.customerAnalysis.overview.alert_customers')}</TableCaption>}
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.customers.name')}</TableHead>
                    <TableHead>{t('features.customers.email')}</TableHead>
                    <TableHead className='text-center'>{t('features.customers.recency')}</TableHead>
                    <TableHead className='text-center'>{t('features.customers.frequency')}</TableHead>
                    <TableHead className='text-center'>{t('features.customers.monetary')}</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.id}>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell className='text-center'>{customer.recency == null ? '-' : customer.recency}</TableCell>
                      <TableCell className='text-center'>{customer.frequency}</TableCell>
                      <TableCell className='text-center'>{formatCurrency(customer.monetary) || <Skeleton className='w-12 h-4' />}</TableCell>
                      <TableCell className='text-right'>
                        <TooltipIconButton
                          icon={UserRoundSearch}
                          tooltipContent={t('features.customerAnalysis.overview.viewCustomer')}
                          variant='ghost'
                          onClick={() => {
                            setCustomerDrawerState({ open: true, customer: { id: customer.id, name: customer.name } })
                            dispatch('OpenCustomerDrawer', { name: customer.id })
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </div>

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState(defaultCustomerDrawerState)}
        customer={customerDrawerState.customer}
      />

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
