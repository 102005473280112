import { DialogWrapper } from '@/components/dialogWrapper'
import { Badge } from '@/components/ui/badge'
import { Textarea } from '@/components/ui/textarea'
import { ShopService } from '@/gen/proto/shop/shop_pb'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier } from 'gen/firestore'
import { BillingService } from 'gen/proto/billing/billing_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { ArrowRight, Check } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
}

enum Step {
  Limitations = 'limitations',
  Reason = 'reason',
  Confirm = 'confirm',
}

export const DowngradeDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const authUser = useAuthUser()
  const billingService = useGrpcClient(BillingService)
  const shopService = useGrpcClient(ShopService)

  const [step, setStep] = useState<Step>(Step.Limitations)
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  const isSubmitButtonDisabled = useMemo(() => step === Step.Reason && reason.trim() === '', [step, reason])

  const handleSubmit = async () => {
    switch (step) {
      case Step.Limitations:
        setStep(Step.Reason)
        break
      case Step.Reason:
        if (reason.trim() !== '') {
          submitDowngradeReason()
          setStep(Step.Confirm)
        }
        break
      case Step.Confirm:
        await downgradeToFree()
        break
    }
  }

  const submitDowngradeReason = async () => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.submitDowngradeReason({ reason }, { headers: { Authorization: `Bearer ${token}` } })
    } catch (err) {
      notifySentry(err)
    }
  }

  const downgradeToFree = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await billingService.startChangePlanFlow({ plan: BillingPlanIdentifier.free }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.plan.downgradeDialog.messageSuccess'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.downgradeDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.settings.plan.downgradeDialog.title')}
      description={step === Step.Limitations ? t('features.settings.plan.downgradeDialog.description_limitations') : undefined}
      mainContent={
        <>
          {step === Step.Limitations && (
            <div className='flex flex-col gap-2'>
              <div className='flex flex-wrap gap-2'>
                {[...Array(6).keys()].map((num) => (
                  <div key={num} className='flex items-center gap-2'>
                    <Check className='w-4 h-4' />
                    <p className='text-sm'>{t(`common.upgradeDialog.feature_${num}_pro`)}</p>
                    <ArrowRight className='w-4 h-4' />
                    <Badge variant='secondary' className='font-normal'>
                      {t(`common.upgradeDialog.feature_${num}_free`)}
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          )}
          {step === Step.Reason && (
            <Textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder={t('features.settings.plan.downgradeDialog.reasonPlaceholder')}
              rows={6}
              className='resize-none'
            />
          )}
          {step === Step.Confirm && <div>{t('features.settings.plan.downgradeDialog.description_confirm')}</div>}
        </>
      }
      onSubmit={handleSubmit}
      loading={loading}
      cancelButtonText={t('features.settings.plan.downgradeDialog.cancel')}
      submitButtonText={step === Step.Confirm ? t('features.settings.plan.downgradeDialog.submit') : t('features.settings.plan.downgradeDialog.next')}
      submitButtonVariant='secondary'
      submitButtonDisabled={isSubmitButtonDisabled}
    />
  )
}
