import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils'
import { X, ChevronDown } from 'lucide-react'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  queryItemValuePath: `query_subsets.${number}.query_items.${number}.value`
  options: string[]
}

export const AutoCompleteValue: FC<Props> = ({ queryItemValuePath, options }) => {
  const { t } = useTranslation()
  const { setValue, control, watch } = useFormContext<QuerySetFormState>()
  const selectedValues = watch(queryItemValuePath) as string[]
  const [search, setSearch] = useState('')

  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <div className='flex flex-col gap-1'>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn(
                      'w-full min-w-[540px] max-w-[540px] h-full text-left flex items-center justify-between',
                      selectedValues.length > 0 ? 'p-1.5' : 'p-[9px]'
                    )}
                  >
                    <div className='flex flex-wrap gap-1 max-w-full'>
                      {selectedValues.length > 0 &&
                        selectedValues.map((value) => (
                          <Badge key={value} variant='outline' className='flex items-center gap-1 px-1 text-xs font-normal'>
                            {value}
                            <button
                              onClick={(e) => {
                                e.stopPropagation()
                                setValue(
                                  field.name,
                                  selectedValues.filter((v) => v !== value)
                                )
                              }}
                              className='text-muted-foreground'
                            >
                              <X />
                            </button>
                          </Badge>
                        ))}
                    </div>
                    <div className='flex items-center gap-1'>
                      {selectedValues.length > 0 && (
                        <Button
                          variant='ghost'
                          className='p-0 pointer h-[20px]'
                          onClick={(e) => {
                            e.stopPropagation()
                            setValue(field.name, [])
                          }}
                        >
                          <X />
                        </Button>
                      )}
                      <ChevronDown className='ml-auto' />
                    </div>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-full p-2'>
                  <Input
                    placeholder={t('features.customerAnalysis.querySet.search')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='mb-2'
                  />
                  <ScrollArea className='max-h-60 overflow-y-auto'>
                    {options
                      .filter((opt) => opt.toLowerCase().includes(search.toLowerCase()))
                      .map((option) => (
                        <div
                          key={option}
                          className={cn('flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-accent', {
                            'bg-accent': selectedValues.includes(option),
                          })}
                          onClick={() => {
                            const newValue = selectedValues.includes(option)
                              ? selectedValues.filter((v) => v !== option)
                              : [...selectedValues, option]
                            setValue(field.name, newValue)
                          }}
                        >
                          <Checkbox checked={selectedValues.includes(option)} className='mr-2' />
                          {option}
                        </div>
                      ))}
                  </ScrollArea>
                </PopoverContent>
              </Popover>
              {error && <div className='text-red-500 text-xs'>{t(error.message as string)}</div>}
            </div>
          </>
        )
      }}
    />
  )
}
