import { DataSyncDialog } from '@/components/dataSyncDialog'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus, CUSTOMER_SEGMENT_LIMIT_FREE } from '@/config/plan'
import { DatasetEtlStatus, InsightDimension, ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { useActionTracker } from '@/hooks/useMixpanel'
import { zodResolver } from '@hookform/resolvers/zod'
import { orderBy, query } from 'firebase/firestore'
import { BookUser, FoldVertical, RefreshCw, Save, UnfoldVertical } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CreateDialog } from '../customerSegments/components/createDialog'
import { Insights } from './components/insights'
import { Overview } from './components/overview'
import { RepeatTrends } from './components/repeatTrends'
import { DimensionOptionsProvider } from './context/dimensionOptionsContext'
import { useCustomers } from './hooks/useCustomers'
import { useInsights } from './hooks/useInsights'
import { useMetrics } from './hooks/useMetrics'
import { useMonthlyCohort } from './hooks/useMonthlyCohort'
import { useSegmentTemplates } from './hooks/useSegmentTemplates'
import { defaultQuerySet } from './querySetForm/defalutValues'
import { convertFormToQuerySet, convertQuerySetToForm, convertStringToApiQuerySetState } from './querySetForm/schema/converter'
import { QuerySetFormState, querySet as querySetSchema } from './querySetForm/schema/querySetFormSchema'
import { useQuerySetForm } from './querySetForm/useQuerySetForm'

enum TabIndex {
  overview = 'overview',
  repeatTrends = 'repeatTrends',
  insights = 'insights',
}

export const CustomerAnalysis = () => {
  const { t } = useTranslation()
  const { account, shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { dispatch } = useActionTracker()

  const [querySetString, setQuerySetString] = useState<string>('')
  const [insightDimension, setInsightDimension] = useState<InsightDimension>(InsightDimension.product)

  const queryParams = new URLSearchParams(location.search)
  const querySetParam = queryParams.get('querySet')
  const defaultQuerySetFromParam = querySetParam && convertQuerySetToForm(convertStringToApiQuerySetState(decodeURIComponent(querySetParam)))

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.overview)
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(!!querySetParam)
  const [createSegmentDialogOpen, setCreateSegmentDialogOpen] = useState<boolean>(false)
  const [isQuerySetValid, setIsQuerySetValid] = useState<boolean>(false)

  const [monthlyCohortStartYearMonth, setMonthlyCohortStartYearMonth] = useState<string>(currentDate.subtract(11, 'month').format('YYYY-MM')) // 11 months ago
  const [monthlyCohortEndYearMonth, setMonthlyCohortEndYearMonth] = useState<string>(currentDate.format('YYYY-MM')) // current month

  const [dataSyncDialogOpen, setDataSyncDialogOpen] = useState<boolean>(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const { metrics, loading: metricsLoading } = useMetrics(querySetString)
  const { customers, loading: customersLoading } = useCustomers(querySetString, 100)
  const { monthlyCohort, loading: monthlyCohortLoading } = useMonthlyCohort(querySetString, monthlyCohortStartYearMonth, monthlyCohortEndYearMonth)
  const { insights, loading: insightsLoading } = useInsights(querySetString, insightDimension, 100)

  const { data: templates } = useSegmentTemplates()

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const isSaveButtonDisabled = useMemo(() => {
    if (!isFilterOpen || !isQuerySetValid) return true
    if (!customerSegments) return false
    return customerSegments.some((segment) => segment.querySet === querySetString)
  }, [customerSegments, querySetString, isFilterOpen, isQuerySetValid])

  const { QuerySetForm, querySetFormMethods } = useQuerySetForm({
    mode: 'onChange',
    defaultValues: defaultQuerySetFromParam || defaultQuerySet,
    resolver: zodResolver(querySetSchema),
  })

  const querySetFormValue = useWatch({
    control: querySetFormMethods.control,
  }) as QuerySetFormState

  useEffect(() => {
    const handleQuerySetChange = async () => {
      const validate = querySetSchema.safeParse(querySetFormValue)
      if (validate.success) {
        const querySetString = JSON.stringify(convertFormToQuerySet(querySetFormValue))
        setIsQuerySetValid(true) // Update the query set validity
        setQuerySetString(querySetString) // Trigger re-render
        dispatch('FilterCustomerQuery', { querySet: querySetString }) // Send mixpanel event
      } else {
        setIsQuerySetValid(false)
      }
    }
    handleQuerySetChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetFormValue])

  useEffect(() => {
    if (isQuerySetValid) {
      const url = new URL(window.location.href)
      url.searchParams.set('querySet', encodeURIComponent(querySetString))
      window.history.replaceState({}, '', url.toString()) // Update the URL query params without navigating
    }
  }, [isQuerySetValid, querySetString])

  const handleCreateDialogOpen = async () => {
    if (shopBillingStatus === ShopBillingStatus.free && customerSegments && customerSegments.length >= CUSTOMER_SEGMENT_LIMIT_FREE) {
      setUpgradeDialogOpen(true)
      dispatch('OpenUpgradeDialog', { referrer: 'customerAnalysis_create' })
      return
    }
    const ok = await querySetFormMethods.trigger()
    if (ok) {
      setCreateSegmentDialogOpen(true)
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.customerAnalysis.title')}
        menuComponent={
          shopBillingStatus === ShopBillingStatus.free && (
            <TooltipIconButton
              icon={shop?.datasetEtlStatus === DatasetEtlStatus.COMPLETED ? RefreshCw : RefreshCw}
              className={shop?.datasetEtlStatus !== DatasetEtlStatus.COMPLETED ? 'animate-spin' : ''}
              tooltipContent={t('features.customerAnalysis.sync')}
              variant='ghost'
              onClick={() => setDataSyncDialogOpen(true)}
            />
          )
        }
      />
      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <Card>
            <CardHeader>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <CardTitle>{t('features.customerAnalysis.title_filter')}</CardTitle>
                  <TooltipIconButton
                    icon={isFilterOpen ? FoldVertical : UnfoldVertical}
                    tooltipContent={isFilterOpen ? t('features.customerAnalysis.hide') : t('features.customerAnalysis.show')}
                    variant='ghost'
                    size='sm'
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                  />
                </div>
                <div className='flex items-center gap-2'>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant='outline' size='sm'>
                        <BookUser />
                        {t('features.customerAnalysis.templates_title')}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      {templates.map((template) => (
                        <DropdownMenuItem
                          key={template.name}
                          className='cursor-pointer'
                          onClick={() => {
                            dispatch('ClickCustomerTemplate', { name: template.name })
                            querySetFormMethods.reset(template.querySet)
                          }}
                        >
                          <div className='flex flex-col gap-1'>
                            <p className='text-sm font-medium'>{template.name}</p>
                            <p className='text-xs text-muted-foreground'>{template.description}</p>
                          </div>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button variant='default' size='sm' disabled={isSaveButtonDisabled} onClick={handleCreateDialogOpen}>
                    <Save />
                    {t('features.customerAnalysis.saveFilter')}
                  </Button>
                </div>
              </div>
            </CardHeader>
            <div className={isFilterOpen ? '' : 'hidden'}>
              <Separator />
              <CardContent className='pt-6'>
                <DimensionOptionsProvider>
                  <QuerySetForm />
                </DimensionOptionsProvider>
              </CardContent>
            </div>
          </Card>

          <Tabs
            defaultValue={TabIndex.overview}
            onValueChange={(value) => {
              setTabIndex(value as TabIndex)
              dispatch('SwitchCustomerTab', { name: value })
            }}
            className='w-[400px]'
          >
            <TabsList>
              <TabsTrigger value={TabIndex.overview}>{t('features.customerAnalysis.tabIndexOverview')}</TabsTrigger>
              <TabsTrigger value={TabIndex.repeatTrends}>{t('features.customerAnalysis.tabIndexRepeatTrends')}</TabsTrigger>
              <TabsTrigger value={TabIndex.insights}>{t('features.customerAnalysis.tabIndexInsights')}</TabsTrigger>
            </TabsList>
          </Tabs>

          {tabIndex === TabIndex.overview && (
            <Overview
              querySetString={querySetString}
              metrics={metrics}
              customers={customers}
              metricsLoading={metricsLoading}
              customersLoading={customersLoading}
            />
          )}

          {tabIndex === TabIndex.repeatTrends && (
            <RepeatTrends
              monthlyCohort={monthlyCohort}
              loading={monthlyCohortLoading}
              startYearMonth={monthlyCohortStartYearMonth}
              endYearMonth={monthlyCohortEndYearMonth}
              setStartYearMonth={setMonthlyCohortStartYearMonth}
              setEndYearMonth={setMonthlyCohortEndYearMonth}
            />
          )}

          {tabIndex === TabIndex.insights && (
            <Insights
              querySetString={querySetString}
              insights={insights}
              insightsLoading={insightsLoading}
              insightDimension={insightDimension}
              setInsightDimension={setInsightDimension}
            />
          )}
        </div>
      </PageContentWrapper>

      <CreateDialog open={createSegmentDialogOpen} handleClose={() => setCreateSegmentDialogOpen(false)} querySetFormMethods={querySetFormMethods} />

      <DataSyncDialog open={dataSyncDialogOpen} handleClose={() => setDataSyncDialogOpen(false)} />

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
