import { CustomerAnalysisService, GetMonthlyCohortResponse_StepAndMetrics } from '@/gen/proto/customer_analysis/customer_analysis_pb'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { formatYearMonth } from '@/utils/timeUtil'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MonthlyCohort, MonthlyStepAndMetrics } from '../types'
import dayjs from 'dayjs'

export const useMonthlyCohort = (querySetString: string, startYearMonth: string, endYearMonth: string) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerAnalysisService = useGrpcClient(CustomerAnalysisService)
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  const [monthlyCohort, setMonthlyCohort] = useState<MonthlyCohort>()

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerAnalysisService.getMonthlyCohort(
          { querySet: querySetString, startYearMonth, endYearMonth },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const monthlyCohortData: MonthlyCohort = {
          monthlyResult: resp.monthlyResult.map((mr) => {
            return {
              firstOrderYearMonth: formatYearMonth(dayjs(mr.firstOrderYearMonth), i18n.language),
              stepAndMetricsList: convertStepAndMetricsList(mr.stepAndMetricsList),
            }
          }),
          overallResult: convertStepAndMetricsList(resp.overallResult),
        }
        setMonthlyCohort(monthlyCohortData)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetString, startYearMonth, endYearMonth])

  return { monthlyCohort, loading }
}

const convertStepAndMetricsList = (smList: GetMonthlyCohortResponse_StepAndMetrics[]): MonthlyStepAndMetrics[] => {
  return smList.map((sm) => {
    return {
      step: Number(sm.step),
      customerCount: Number(sm.customerCount),
      ltv: Number(sm.ltv),
      avgOrderCount: Number(sm.avgOrderCount),
      repurchaseRate: Number(sm.repurchaseRate),
    }
  })
}
