import { DataSyncDialog } from '@/components/dataSyncDialog'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { billingStatus } from '@/config/plan'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { formatDateTime, timestampToDayjs } from '@/utils/timeUtil'
import { DatasetEtlStatus, Shop, ShopBillingStatus } from 'gen/firestore'
import { Pencil, RefreshCw } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateOrderPricingRuleDialog } from './components/updateOrderPricingRuleDialog'

type Props = {
  shop: Shop | undefined
}

export const Workspace: FC<Props> = ({ shop }) => {
  const { t, i18n } = useTranslation()
  const currentDate = useCurrentDate(shop!.timezone)
  const shopBillingStatus = billingStatus(shop!, currentDate)

  const [dataSyncDialogOpen, setDataSyncDialogOpen] = useState(false)
  const [updateOrderPricingRuleDialogOpen, setUpdateOrderPricingRuleDialogOpen] = useState(false)

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t('features.settings.workspace.general')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.name')}</TableCell>
                <TableCell>{shop?.displayName || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.shopName')}</TableCell>
                <TableCell>{shop?.shopName || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.currency')}</TableCell>
                <TableCell>{shop?.currency || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.timezone')}</TableCell>
                <TableCell>{shop?.timezone || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <Separator />

        <CardHeader>
          <CardTitle>{t('features.settings.workspace.dataSync')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.syncMode')}</TableCell>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    {!shop ? (
                      <Skeleton className='w-[120px]' />
                    ) : shopBillingStatus === ShopBillingStatus.free ? (
                      t('features.settings.workspace.manualSync')
                    ) : (
                      t('features.settings.workspace.dailySync')
                    )}
                    {shop && shop.datasetEtlStatus !== DatasetEtlStatus.RUNNING && (
                      <Tooltip>
                        <TooltipTrigger>
                          <RefreshCw className='w-3 h-3 cursor-pointer hover:text-muted-foreground' onClick={() => setDataSyncDialogOpen(true)} />
                        </TooltipTrigger>
                        <TooltipContent>{t('features.settings.workspace.sync')}</TooltipContent>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.status')}</TableCell>
                <TableCell>
                  {shop ? t(`features.settings.workspace.status_${shop.datasetEtlStatus.toLowerCase()}`) : <Skeleton className='w-[120px]' />}
                </TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.lastSyncedAt')}</TableCell>
                <TableCell>
                  {shop ? (
                    shop.lastDatasetEtlCompletedAt ? (
                      formatDateTime(timestampToDayjs(shop.lastDatasetEtlCompletedAt), i18n.language)
                    ) : (
                      '-'
                    )
                  ) : (
                    <Skeleton className='w-[120px]' />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <Separator />

        <CardHeader>
          <CardTitle>{t('features.settings.workspace.orderPricing')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.rule')}</TableCell>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    {shop ? (
                      t('features.settings.workspace.orderPricingRule', { context: shop.orderPricingRule })
                    ) : (
                      <Skeleton className='w-[120px]' />
                    )}
                    <Tooltip>
                      <TooltipTrigger>
                        <Pencil
                          className='w-3 h-3 cursor-pointer hover:text-muted-foreground'
                          onClick={() => setUpdateOrderPricingRuleDialogOpen(true)}
                        />
                      </TooltipTrigger>
                      <TooltipContent>{t('features.settings.edit')}</TooltipContent>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {shop && (
        <UpdateOrderPricingRuleDialog
          open={updateOrderPricingRuleDialogOpen}
          shop={shop}
          handleClose={() => setUpdateOrderPricingRuleDialogOpen(false)}
        />
      )}

      <DataSyncDialog open={dataSyncDialogOpen} handleClose={() => setDataSyncDialogOpen(false)} />
    </>
  )
}
