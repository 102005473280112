import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useCurrency } from '@/hooks/useCurrency'
import { EmptyState } from 'components/emptyState'
import dayjs from 'dayjs'
import { Info, LoaderCircle } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, XAxis } from 'recharts'
import { formatDate } from 'utils/timeUtil'
import { DailyOrderSummary, OrderMetricsKey } from '../../types'
import { useAccount } from '@/hooks/useAccount'

type Props = {
  metricsKey: OrderMetricsKey
  dailySummaries: DailyOrderSummary[] | undefined
  loading: boolean
}

export const MetricsChartCard: FC<Props> = ({ metricsKey, dailySummaries, loading }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const { formatCurrency } = useCurrency()

  const chartData = useMemo(() => {
    if (!dailySummaries) return undefined
    return dailySummaries.map((item) => ({
      date: formatDate(dayjs(item.date), i18n.language),
      metric: item[metricsKey],
    }))
  }, [dailySummaries, metricsKey, i18n.language])

  const formatMetricVal = (metricsKey: OrderMetricsKey, value: number): string => {
    if (metricsKey === 'orderAmount') return formatCurrency(value) || value.toLocaleString()
    if (metricsKey === 'orderCount') return value.toLocaleString()
    if (metricsKey === 'avgOrderValue') return formatCurrency(value) || value.toLocaleString()
    return value.toLocaleString()
  }

  const summaryValue = useMemo(() => {
    if (!chartData || chartData.length === 0) return '-'
    if (metricsKey === 'orderAmount' || metricsKey === 'orderCount') {
      const total = chartData.reduce((sum, item) => sum + item.metric, 0)
      return formatMetricVal(metricsKey, total)
    }
    if (metricsKey === 'avgOrderValue') {
      const total = chartData.reduce((sum, item) => sum + item.metric, 0)
      const average = total / chartData.length
      return formatMetricVal(metricsKey, average)
    }
    return '-'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  const chartConfig = {
    metric: {
      label: t(`features.orderAnalysis.${metricsKey}`),
      color: 'hsl(var(--chart-5))',
    },
  }

  return (
    <Card>
      <CardHeader>
        <div className='flex flex-col gap-1'>
          <div className='flex items-center gap-2'>
            <div className='text-sm font-bold'>{t(`features.orderAnalysis.${metricsKey}`)}</div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className='w-[14px] h-[14px]' />
              </TooltipTrigger>
              <TooltipContent>
                <p>{t(`features.orderAnalysis.${metricsKey}_desc`)}</p>
                {metricsKey === 'orderAmount' && (
                  <p>
                    {t('features.orderAnalysis.orderPricingRule')}
                    {t(`features.settings.workspace.orderPricingRule_${shop.orderPricingRule}`)}
                  </p>
                )}
              </TooltipContent>
            </Tooltip>
          </div>
          <div className='text-xl font-bold'>{loading ? <Skeleton className='h-3 w-24' /> : summaryValue}</div>
        </div>
      </CardHeader>
      <CardContent>
        {loading || !chartData ? (
          <div className='flex justify-center items-center h-[160px]'>
            <LoaderCircle className='animate-spin' />
          </div>
        ) : chartData.length === 0 ? (
          <EmptyState title={t('features.orderAnalysis.overview.empty_metrics')} />
        ) : (
          <ChartContainer config={chartConfig}>
            <AreaChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey='date'
                tickLine={false}
                axisLine={false}
                interval='preserveStartEnd'
                ticks={[chartData[0].date, chartData[chartData.length - 1].date]}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='line' />} />
              <Area dataKey='metric' type='natural' fill='url(#colorMetric)' stroke='var(--color-metric)' />
              <defs>
                <linearGradient id='colorMetric' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='0%' stopColor='var(--color-metric)' stopOpacity={0.8} />
                  <stop offset='100%' stopColor='var(--color-metric)' stopOpacity={0.2} />
                </linearGradient>
              </defs>
            </AreaChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}
