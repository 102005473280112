import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { buildErrorMessage, QuerySetFormState } from '../schema/querySetFormSchema'
import { CustomInput } from '@/components/ui/input-custom'

type Props = {
  minValuePath:
    | `query_subsets.${number}.query_items.${number}.value.min`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min_sequence`
  maxValuePath:
    | `query_subsets.${number}.query_items.${number}.value.max`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max_sequence`
  inputAdornmentString: string
  inputPropsMaxValue: number
}

export const RelativeDateRangeValue: FC<Props> = ({ minValuePath, maxValuePath, inputAdornmentString, inputPropsMaxValue }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <div className='flex items-start gap-2'>
      <Controller
        control={control}
        name={maxValuePath}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='flex flex-col min-w-[120px] gap-1'>
              <CustomInput
                type='number'
                value={field.value ?? ''}
                onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
                className={cn(error && 'border-red-500')}
                min={1}
                max={inputPropsMaxValue}
                endAdornment={inputAdornmentString}
              />

              {error && error.message && <div className='text-red-500 text-xs'>{buildErrorMessage(t, error.message) as string}</div>}
            </div>
          )
        }}
      />
      <div className='py-2'>〜</div>
      <Controller
        control={control}
        name={minValuePath}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='flex flex-col min-w-[120px] gap-1'>
              <CustomInput
                type='number'
                value={field.value ?? ''}
                onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
                className={cn(error && 'border-red-500')}
                min={1}
                max={inputPropsMaxValue}
                endAdornment={inputAdornmentString}
              />

              {error && error.message && <div className='text-red-500 text-xs'>{buildErrorMessage(t, error.message) as string}</div>}
            </div>
          )
        }}
      />
    </div>
  )
}
