// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file proto/product_analysis/product_analysis.proto (package product_analysis, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file proto/product_analysis/product_analysis.proto.
 */
export const file_proto_product_analysis_product_analysis: GenFile = /*@__PURE__*/
  fileDesc("Ci1wcm90by9wcm9kdWN0X2FuYWx5c2lzL3Byb2R1Y3RfYW5hbHlzaXMucHJvdG8SEHByb2R1Y3RfYW5hbHlzaXMigAEKGkdldEFmZmluaXR5UHJvZHVjdHNSZXF1ZXN0EhEKCWRpbWVuc2lvbhgBIAEoCRINCgV2YWx1ZRgCIAEoCRIRCglzdGFydERhdGUYAyABKAkSDwoHZW5kRGF0ZRgEIAEoCRISCgVsaW1pdBgFIAEoA0gAiAEBQggKBl9saW1pdCKrAQobR2V0QWZmaW5pdHlQcm9kdWN0c1Jlc3BvbnNlEkMKBnZhbHVlcxgBIAMoCzIzLnByb2R1Y3RfYW5hbHlzaXMuR2V0QWZmaW5pdHlQcm9kdWN0c1Jlc3BvbnNlLlZhbHVlGkcKBVZhbHVlEgwKBG5hbWUYASABKAkSEgoKb3JkZXJDb3VudBgCIAEoAxIcChRhdmdEYXlzQmV0d2Vlbk9yZGVycxgDIAEoAzKaAwoWUHJvZHVjdEFuYWx5c2lzU2VydmljZRJ9ChxHZXRBZmZpbml0eVByb2R1Y3RzU2FtZU9yZGVyEiwucHJvZHVjdF9hbmFseXNpcy5HZXRBZmZpbml0eVByb2R1Y3RzUmVxdWVzdBotLnByb2R1Y3RfYW5hbHlzaXMuR2V0QWZmaW5pdHlQcm9kdWN0c1Jlc3BvbnNlIgASgQEKIEdldEFmZmluaXR5UHJvZHVjdHNQcmV2aW91c09yZGVyEiwucHJvZHVjdF9hbmFseXNpcy5HZXRBZmZpbml0eVByb2R1Y3RzUmVxdWVzdBotLnByb2R1Y3RfYW5hbHlzaXMuR2V0QWZmaW5pdHlQcm9kdWN0c1Jlc3BvbnNlIgASfQocR2V0QWZmaW5pdHlQcm9kdWN0c05leHRPcmRlchIsLnByb2R1Y3RfYW5hbHlzaXMuR2V0QWZmaW5pdHlQcm9kdWN0c1JlcXVlc3QaLS5wcm9kdWN0X2FuYWx5c2lzLkdldEFmZmluaXR5UHJvZHVjdHNSZXNwb25zZSIAQkJaQGVjcG93ZXIuaW8vYXBpLXNlcnZlci9wcm90by9wcm9kdWN0X2FuYWx5c2lzO3Byb2R1Y3RfYW5hbHlzaXNfcGJiBnByb3RvMw");

/**
 * AffinityProductsRequest
 *
 * @generated from message product_analysis.GetAffinityProductsRequest
 */
export type GetAffinityProductsRequest = Message<"product_analysis.GetAffinityProductsRequest"> & {
  /**
   * @generated from field: string dimension = 1;
   */
  dimension: string;

  /**
   * @generated from field: string value = 2;
   */
  value: string;

  /**
   * format: YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate: string;

  /**
   * format: YYYY-MM-DD 
   *
   * @generated from field: string endDate = 4;
   */
  endDate: string;

  /**
   * @generated from field: optional int64 limit = 5;
   */
  limit?: bigint;
};

/**
 * Describes the message product_analysis.GetAffinityProductsRequest.
 * Use `create(GetAffinityProductsRequestSchema)` to create a new message.
 */
export const GetAffinityProductsRequestSchema: GenMessage<GetAffinityProductsRequest> = /*@__PURE__*/
  messageDesc(file_proto_product_analysis_product_analysis, 0);

/**
 * GetAffinityProductsResponse
 *
 * @generated from message product_analysis.GetAffinityProductsResponse
 */
export type GetAffinityProductsResponse = Message<"product_analysis.GetAffinityProductsResponse"> & {
  /**
   * @generated from field: repeated product_analysis.GetAffinityProductsResponse.Value values = 1;
   */
  values: GetAffinityProductsResponse_Value[];
};

/**
 * Describes the message product_analysis.GetAffinityProductsResponse.
 * Use `create(GetAffinityProductsResponseSchema)` to create a new message.
 */
export const GetAffinityProductsResponseSchema: GenMessage<GetAffinityProductsResponse> = /*@__PURE__*/
  messageDesc(file_proto_product_analysis_product_analysis, 1);

/**
 * @generated from message product_analysis.GetAffinityProductsResponse.Value
 */
export type GetAffinityProductsResponse_Value = Message<"product_analysis.GetAffinityProductsResponse.Value"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: int64 orderCount = 2;
   */
  orderCount: bigint;

  /**
   * @generated from field: int64 avgDaysBetweenOrders = 3;
   */
  avgDaysBetweenOrders: bigint;
};

/**
 * Describes the message product_analysis.GetAffinityProductsResponse.Value.
 * Use `create(GetAffinityProductsResponse_ValueSchema)` to create a new message.
 */
export const GetAffinityProductsResponse_ValueSchema: GenMessage<GetAffinityProductsResponse_Value> = /*@__PURE__*/
  messageDesc(file_proto_product_analysis_product_analysis, 1, 0);

/**
 * @generated from service product_analysis.ProductAnalysisService
 */
export const ProductAnalysisService: GenService<{
  /**
   * @generated from rpc product_analysis.ProductAnalysisService.GetAffinityProductsSameOrder
   */
  getAffinityProductsSameOrder: {
    methodKind: "unary";
    input: typeof GetAffinityProductsRequestSchema;
    output: typeof GetAffinityProductsResponseSchema;
  },
  /**
   * @generated from rpc product_analysis.ProductAnalysisService.GetAffinityProductsPreviousOrder
   */
  getAffinityProductsPreviousOrder: {
    methodKind: "unary";
    input: typeof GetAffinityProductsRequestSchema;
    output: typeof GetAffinityProductsResponseSchema;
  },
  /**
   * @generated from rpc product_analysis.ProductAnalysisService.GetAffinityProductsNextOrder
   */
  getAffinityProductsNextOrder: {
    methodKind: "unary";
    input: typeof GetAffinityProductsRequestSchema;
    output: typeof GetAffinityProductsResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_proto_product_analysis_product_analysis, 0);

