import { DialogWrapper } from '@/components/dialogWrapper'
import { getIdToken } from 'firebase/auth'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
}

export const DeleteGroupDialog: FC<Props> = ({ open, handleClose, id, name }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const segmentGroupService = useGrpcClient(SegmentGroupService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.delete({ segmentGroupId: id }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.deleteGroupDialog.messageSaved'), { severity: 'success' })
      handleClose()
      navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.deleteGroupDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DialogWrapper
      open={open}
      handleClose={handleClose}
      title={t('features.customerSegments.deleteGroupDialog.title')}
      mainContent={<p className='text-sm '>{t('features.customerSegments.deleteGroupDialog.description', { name: name })}</p>}
      onSubmit={handleSubmit}
      loading={isLoading}
      cancelButtonText={t('features.customerSegments.deleteGroupDialog.cancel')}
      submitButtonText={t('features.customerSegments.deleteGroupDialog.submit')}
    />
  )
}
