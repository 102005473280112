// contain operator
export const OP_CONTAIN_ANY = 'contain_any'
export const OP_NOT_CONTAIN = 'not_contain'
// range operator
export const OP_DATE_RANGE = 'date_range'
export const OP_INT_RANGE = 'int_range'
// numeric operator
export const OP_EQUAL = 'eq'
export const OP_NOT_EQUAL = 'not_eq'
// other operator
export const OP_CUSTOM = 'custom'
