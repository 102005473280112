import { AFFINITY_PRODUCT_LIMIT_FREE, billingStatus } from '@/config/plan'
import { ProductDimension, ShopBillingStatus } from '@/gen/firestore'
import {
  GetAffinityProductsResponse,
  GetAffinityProductsResponse_Value,
  ProductAnalysisService,
} from '@/gen/proto/product_analysis/product_analysis_pb'
import { useAccount } from '@/hooks/useAccount'
import { useConcurrentRequest } from '@/hooks/useConcurrentRequest'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { getIdToken } from '@firebase/auth'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AffinityProduct } from '../types'

type Props = {
  dimension: ProductDimension
  value: string
  startDate: string
  endDate: string
  limit: number
  isInputValid: boolean
}

export const useAffinityProducts = ({ dimension, value, startDate, endDate, limit, isInputValid }: Props) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)

  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const productAnalysisService = useGrpcClient(ProductAnalysisService)

  const [productsSameOrder, setProductsSameOrder] = useState<AffinityProduct[]>()
  const [productsPreviousOrder, setProductsPreviousOrder] = useState<AffinityProduct[]>()
  const [productsNextOrder, setProductsNextOrder] = useState<AffinityProduct[]>()
  const { anyLoading: loading, executeRequest } = useConcurrentRequest()

  // If startDate is empty, set it to 10 years ago
  if (startDate === '') {
    startDate = currentDate.subtract(10, 'year').format('YYYY-MM-DD')
  }

  useEffect(() => {
    if (!isInputValid) return

    const handleFetch = async () => {
      try {
        const token = await getIdToken(authUser!)

        const fetchSameOrder = productAnalysisService.getAffinityProductsSameOrder(
          { dimension, value, startDate, endDate, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )

        const fetchPreviousOrder = productAnalysisService.getAffinityProductsPreviousOrder(
          { dimension, value, startDate, endDate, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )

        const fetchNextOrder = productAnalysisService.getAffinityProductsNextOrder(
          { dimension, value, startDate, endDate, limit: BigInt(limit) },
          { headers: { Authorization: `Bearer ${token}` } }
        )

        const [respSameOrder, respPreviousOrder, respNextOrder] = await Promise.all([fetchSameOrder, fetchPreviousOrder, fetchNextOrder])

        const processResponse = (resp: GetAffinityProductsResponse) =>
          resp.values.map((product: GetAffinityProductsResponse_Value, index: number) => ({
            name: product.name,
            orderCount: Number(product.orderCount),
            avgDaysBetweenOrders: Number(product.avgDaysBetweenOrders),
            visible: shopBillingStatus !== ShopBillingStatus.free || index + 1 <= AFFINITY_PRODUCT_LIMIT_FREE,
          }))

        setProductsSameOrder(processResponse(respSameOrder))
        setProductsPreviousOrder(processResponse(respPreviousOrder))
        setProductsNextOrder(processResponse(respNextOrder))
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }

    executeRequest(handleFetch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, value, startDate, endDate, isInputValid])

  return { productsSameOrder, productsPreviousOrder, productsNextOrder, loading }
}
