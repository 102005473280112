import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { formatDate } from '@/utils/timeUtil'
import { format } from 'date-fns'
import { enUS, ja } from 'date-fns/locale'
import dayjs from 'dayjs'
import { Calendar as CalendarIcon } from 'lucide-react'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  minDateValuePath:
    | `query_subsets.${number}.query_items.${number}.value.min_date`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min_date`
  maxDateValuePath:
    | `query_subsets.${number}.query_items.${number}.value.max_date`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max_date`
}

export const DateRangeValue: FC<Props> = ({ minDateValuePath, maxDateValuePath }) => {
  const { t, i18n } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const [openMinDate, setOpenMinDate] = useState(false)
  const [openMaxDate, setOpenMaxDate] = useState(false)

  return (
    <div className='flex items-start gap-2'>
      <Controller
        control={control}
        name={minDateValuePath}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='min-w-[120px] flex flex-col gap-1'>
              <Popover open={openMinDate} onOpenChange={setOpenMinDate}>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn('w-full flex gap-6 justify-start', error && 'border-red-500')}
                    onClick={() => setOpenMinDate(true)}
                  >
                    <span>
                      {field.value ? formatDate(dayjs(field.value), i18n.language) : t('features.customerAnalysis.querySet.orderDateDefault')}
                    </span>
                    <CalendarIcon />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='p-0 w-auto'>
                  <Calendar
                    mode='single'
                    // Convert to date type since the selected property only accepts date type.
                    selected={field.value ? new Date(field.value) : undefined}
                    onSelect={(date) => {
                      field.onChange(date ? format(date, 'yyyy-MM-dd') : undefined)
                      setOpenMinDate(false)
                    }}
                    locale={i18n.language === 'ja' ? ja : enUS}
                  />
                </PopoverContent>
              </Popover>
              {error && <div className='text-red-500 text-xs'>{t(error.message as string)}</div>}
            </div>
          )
        }}
      />
      <div className='py-2'>〜</div>
      <Controller
        control={control}
        name={maxDateValuePath}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='min-w-[120px] flex flex-col gap-1'>
              <Popover open={openMaxDate} onOpenChange={setOpenMaxDate}>
                <PopoverTrigger asChild>
                  <Button
                    variant='outline'
                    className={cn('w-full flex gap-6 justify-start', error && 'border-red-500')}
                    onClick={() => setOpenMaxDate(true)}
                  >
                    <span>
                      {field.value ? formatDate(dayjs(field.value), i18n.language) : t('features.customerAnalysis.querySet.orderDateDefault')}
                    </span>
                    <CalendarIcon />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='p-0 w-auto'>
                  <Calendar
                    mode='single'
                    // Convert to date type since the selected property only accepts date type.
                    selected={field.value ? new Date(field.value) : undefined}
                    onSelect={(date) => {
                      field.onChange(date ? format(date, 'yyyy-MM-dd') : undefined)
                      setOpenMaxDate(false)
                    }}
                    locale={i18n.language === 'ja' ? ja : enUS}
                  />
                </PopoverContent>
              </Popover>
              {error && <div className='text-red-500 text-xs'>{t(error.message as string)}</div>}
            </div>
          )
        }}
      />
    </div>
  )
}
