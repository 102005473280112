import dayjs from 'dayjs'
import { QueryItemFormState, QuerySetFormState } from './querySetForm/schema/querySetFormSchema'
import { OrderInsightDimension } from '@/gen/firestore'
import { OP_CONTAIN_ANY } from './querySetForm/schema/const'

export type DailyOrderSummary = {
  date: string // format: YYYY-MM-DD
  orderAmount: number
  orderCount: number
  avgOrderValue: number
}

export enum OrderMetricsKey {
  orderAmount = 'orderAmount',
  orderCount = 'orderCount',
  avgOrderValue = 'avgOrderValue',
}

export type OrderState = {
  id: string
  name: string
  amount: number
  orderAt: dayjs.Dayjs
  referrer: string
  channel: string
  coupon: string
}

export type OrderInsightValue = {
  name: string
  orderAmount: number
  orderCount: number
  customerCount: number
  avgOrderValue: number
  visible: boolean // for free plan
}

export const generateQuerySetByInsight = (
  originalQuerySet: QuerySetFormState,
  insightDimension: OrderInsightDimension,
  value: string
): QuerySetFormState | undefined => {
  let queryItem: QueryItemFormState

  switch (insightDimension) {
    case OrderInsightDimension.product:
      queryItem = {
        dimension: 'product_name',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.productType:
      queryItem = {
        dimension: 'product_type',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.productVendor:
      queryItem = {
        dimension: 'product_vendor',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.productTag:
      queryItem = {
        dimension: 'product_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.referrer:
      queryItem = {
        dimension: 'referrer',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.utmSource:
      queryItem = {
        dimension: 'utm_source',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.utmMedium:
      queryItem = {
        dimension: 'utm_medium',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.utmCampaign:
      queryItem = {
        dimension: 'utm_campaign',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.channel:
      queryItem = {
        dimension: 'channel',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.orderTag:
      queryItem = {
        dimension: 'order_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.coupon:
      queryItem = {
        dimension: 'coupon',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.country:
      queryItem = {
        dimension: 'country',
        operator: OP_CONTAIN_ANY,
        value: [value],
      }
      break
    case OrderInsightDimension.newRepeat:
      queryItem = {
        dimension: 'frequency',
        operator: 'eq',
        value: value === 'new' ? 'new' : 'repeat',
      }
      break
    default:
      return undefined
  }

  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_items: [queryItem],
  }

  if (originalQuerySet) {
    querySet.query_items = [...originalQuerySet.query_items, ...querySet.query_items]
  }

  return querySet
}
