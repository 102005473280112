import { Dialog } from '@mui/material'
import { Crown, X } from 'lucide-react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import ChannelTalkClient from 'utils/channelTalk'
import { Badge } from './ui/badge'
import { Button } from './ui/button'
import { useActionTracker } from '@/hooks/useMixpanel'

type Props = {
  open: boolean
  handleClose: () => void
}

const IMAGE_PATH = '/images/plan/upgrade.svg'

export const UpgradeDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  // Preload the image to avoid delay on first open
  useEffect(() => {
    const img = new Image()
    img.src = IMAGE_PATH
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      className='relative'
      slotProps={{
        paper: {
          sx: { borderRadius: '18px' },
        },
      }}
    >
      <div className='flex items-stretch relative'>
        <div className='flex-1 flex flex-col gap-10 px-8 py-16'>
          <div className='flex flex-col gap-2'>
            <h2 className='text-xl font-bold'>{t('common.upgradeDialog.title')}</h2>
            <p className='text-gray-500'>{t('common.upgradeDialog.desc')}</p>
          </div>
          <div className='flex flex-col gap-4'>
            {[...Array(6).keys()].map((num) => (
              <div key={num} className='flex items-center gap-2'>
                <Crown className='text-yellow-600 w-4 h-4' />
                <div className='font-bold'>{t(`common.upgradeDialog.feature_${num}_pro`)}</div>
                <Badge variant='secondary' className='font-normal'>
                  {t(`common.upgradeDialog.feature_${num}_free`)}
                </Badge>
              </div>
            ))}
          </div>
          <div className='flex gap-2 w-full'>
            <Button
              variant='outline'
              size='lg'
              onClick={() => {
                dispatch('ClickContactSupport', { referrer: 'upgradeDialog' })
                ChannelTalkClient.openChat()
              }}
              className='flex-1'
            >
              {t('common.upgradeDialog.support')}
            </Button>
            <Button
              type='submit'
              size='lg'
              onClick={() => {
                dispatch('ClickCheckPlan', { referrer: 'upgradeDialog' })
                handleClose()
                navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
              }}
              className='flex-1'
            >
              {t('common.upgradeDialog.submit')}
            </Button>
          </div>
        </div>
        <div className='flex-1 relative overflow-hidden'>
          <img src={IMAGE_PATH} alt='Upgrade' className='absolute inset-0 w-full h-full object-cover' />
        </div>
      </div>

      <div className='absolute top-4 right-4'>
        <Button variant='ghost' size='icon' onClick={handleClose}>
          <X className='w-4 h-4' />
        </Button>
      </div>
    </Dialog>
  )
}
