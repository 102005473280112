import { DataSyncDialog } from '@/components/dataSyncDialog'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { TooltipIconButton } from '@/components/ui/tooltip-icon-button'
import { UpgradeDialog } from '@/components/upgradeDialog'
import { billingStatus, ORDER_FILTER_LIMIT_FREE } from '@/config/plan'
import { CreateDialog } from '@/features/orderFilters/components/createDialog'
import { DatasetEtlStatus, OrderInsightDimension, ShopBillingStatus } from '@/gen/firestore'
import { useAccount } from '@/hooks/useAccount'
import { useSubCollectionRef } from '@/hooks/useCollectionRef'
import { useCurrentDate } from '@/hooks/useCurrentDate'
import { useCollectionSubscription } from '@/hooks/useFirestoreData'
import { useActionTracker } from '@/hooks/useMixpanel'
import { zodResolver } from '@hookform/resolvers/zod'
import { query } from 'firebase/firestore'
import { FoldVertical, RefreshCw, Save, UnfoldVertical } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Insights } from './components/insights'
import { Overview } from './components/overview'
import { DimensionOptionsProvider } from './context/dimensionOptionsContext'
import { useDailyMetrics } from './hooks/useDailyMetrics'
import { useInsights } from './hooks/useInsights'
import { useOrders } from './hooks/useOrders'
import { getDefaultValues } from './querySetForm/defaultValues'
import { convertFormToQuerySet, convertQuerySetToForm, convertStringToApiQuerySetState } from './querySetForm/schema/converter'
import { QuerySetFormState, querySet as querySetSchema } from './querySetForm/schema/querySetFormSchema'
import { useQuerySetForm } from './querySetForm/useQuerySetForm'

enum TabIndex {
  overview = 'overview',
  insights = 'insights',
}

export const OrderAnalysis = () => {
  const { t } = useTranslation()
  const { shop, account } = useAccount()
  const currentDate = useCurrentDate(shop.timezone)
  const shopBillingStatus = billingStatus(shop, currentDate)
  const { dispatch } = useActionTracker()

  const params = new URLSearchParams(location.search)
  const querySetParam = params.get('querySet')
  const defaultQuerySetFromParam = querySetParam && convertQuerySetToForm(convertStringToApiQuerySetState(decodeURIComponent(querySetParam)))

  const { defaultQuerySet } = getDefaultValues(currentDate)

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.overview)
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true)
  const [insightDimension, setInsightDimension] = useState<OrderInsightDimension>(OrderInsightDimension.product)
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
  const [isQuerySetValid, setIsQuerySetValid] = useState<boolean>(false)

  const [dataSyncDialogOpen, setDataSyncDialogOpen] = useState<boolean>(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false)

  const { QuerySetForm, querySetFormMethods } = useQuerySetForm({
    mode: 'onChange',
    defaultValues: defaultQuerySetFromParam || defaultQuerySet,
    resolver: zodResolver(querySetSchema),
  })

  const querySetFormValue = useWatch({
    control: querySetFormMethods.control,
  }) as QuerySetFormState

  const [querySet, setQuerySet] = useState<QuerySetFormState>(querySetFormValue)

  const querySetJsonString = useMemo(() => {
    return JSON.stringify(convertFormToQuerySet(querySet))
  }, [querySet])

  const { dailyMetrics, loading: dailyMetricsLoading } = useDailyMetrics(querySetJsonString)
  const { orders, loading: ordersLoading } = useOrders(querySetJsonString, 100)
  const { insights, loading: insightsLoading } = useInsights(querySetJsonString, insightDimension, 100)

  useEffect(() => {
    const updateQuerySet = () => {
      const validate = querySetSchema.safeParse(querySetFormValue)
      const orderDateQueryItemExists = querySetFormValue?.query_items?.some((item) => item.dimension === 'order_date')
      if (validate.success && orderDateQueryItemExists) {
        setQuerySet(querySetFormValue)
        setIsQuerySetValid(true)
        dispatch('FilterOrderQuery', { querySet: querySetJsonString }) // Send mixpanel event
      } else {
        setIsQuerySetValid(false)
      }
    }
    updateQuerySet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySetFormValue])

  useEffect(() => {
    if (isQuerySetValid) {
      const url = new URL(window.location.href)
      url.searchParams.set('querySet', encodeURIComponent(querySetJsonString))
      window.history.replaceState({}, '', url.toString()) // Update the URL query params without navigating
    }
  }, [isQuerySetValid, querySetJsonString])

  const { orderFiltersRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: orderFilters } = useCollectionSubscription(query(orderFiltersRef))

  const isSaveButtonDisabled = useMemo(() => {
    if (!isFilterOpen || !isQuerySetValid) return true
    if (!orderFilters) return false

    return orderFilters.some((filter) => filter.querySet === querySetJsonString)
  }, [orderFilters, querySetJsonString, isFilterOpen, isQuerySetValid])

  const handleCreateDialogOpen = async () => {
    if (shopBillingStatus === ShopBillingStatus.free && orderFilters && orderFilters.length >= ORDER_FILTER_LIMIT_FREE) {
      setUpgradeDialogOpen(true)
      dispatch('OpenUpgradeDialog', { referrer: 'orderAnalysis_create' })
      return
    }
    const ok = await querySetFormMethods.trigger()
    if (ok) {
      setCreateDialogOpen(true)
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.orderAnalysis.title')}
        menuComponent={
          shopBillingStatus === ShopBillingStatus.free && (
            <TooltipIconButton
              icon={shop?.datasetEtlStatus === DatasetEtlStatus.COMPLETED ? RefreshCw : RefreshCw}
              className={shop?.datasetEtlStatus === DatasetEtlStatus.COMPLETED ? '' : 'animate-spin'}
              tooltipContent={t('features.orderAnalysis.sync')}
              variant='ghost'
              onClick={() => setDataSyncDialogOpen(true)}
            />
          )
        }
      />
      <PageContentWrapper>
        <div className='flex flex-col gap-6'>
          <Card>
            <CardHeader>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <CardTitle>{t('features.orderAnalysis.querySet.title')}</CardTitle>
                  <TooltipIconButton
                    icon={isFilterOpen ? FoldVertical : UnfoldVertical}
                    variant='ghost'
                    tooltipContent={isFilterOpen ? t('features.orderAnalysis.hideFilter') : t('features.orderAnalysis.openFilter')}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                  />
                </div>
                <div className='flex items-center gap-2'>
                  <Button variant='default' size='sm' disabled={isSaveButtonDisabled} onClick={handleCreateDialogOpen}>
                    <Save />
                    {t('features.orderAnalysis.saveFilter')}
                  </Button>
                </div>
              </div>
            </CardHeader>
            <div className={isFilterOpen ? '' : 'hidden'}>
              <Separator />
              <CardContent className='pt-6'>
                <DimensionOptionsProvider>
                  <QuerySetForm />
                </DimensionOptionsProvider>
              </CardContent>
            </div>
          </Card>

          <Tabs
            defaultValue={TabIndex.overview}
            onValueChange={(value) => {
              setTabIndex(value as TabIndex)
              dispatch('SwitchOrderTab', { name: value })
            }}
            className='w-[400px]'
          >
            <TabsList>
              <TabsTrigger value={TabIndex.overview}>{t('features.orderAnalysis.tabIndexOverview')}</TabsTrigger>
              <TabsTrigger value={TabIndex.insights}>{t('features.orderAnalysis.tabIndexInsights')}</TabsTrigger>
            </TabsList>
          </Tabs>

          {tabIndex === TabIndex.overview && (
            <Overview
              querySetString={querySetJsonString}
              dailyMetrics={dailyMetrics}
              orders={orders}
              dailyMetricsLoading={dailyMetricsLoading}
              ordersLoading={ordersLoading}
            />
          )}

          {tabIndex === TabIndex.insights && (
            <Insights
              querySetString={querySetJsonString}
              insights={insights}
              insightsLoading={insightsLoading}
              insightDimension={insightDimension}
              setInsightDimension={setInsightDimension}
            />
          )}
        </div>
      </PageContentWrapper>

      <CreateDialog open={createDialogOpen} handleClose={() => setCreateDialogOpen(false)} querySetFormMethods={querySetFormMethods} />

      <DataSyncDialog open={dataSyncDialogOpen} handleClose={() => setDataSyncDialogOpen(false)} />

      <UpgradeDialog open={upgradeDialogOpen} handleClose={() => setUpgradeDialogOpen(false)} />
    </>
  )
}
