import { OP_CONTAIN_ANY, OP_DATE_RANGE } from '@/features/customerAnalysis/querySetForm/schema/const'
import { convertFormToQuerySet } from '@/features/customerAnalysis/querySetForm/schema/converter'
import { QuerySetFormState } from '@/features/customerAnalysis/querySetForm/schema/querySetFormSchema'
import { ProductDimension } from '@/gen/firestore'
import { AUTHORIZED_ROUTE } from '@/routing'
import { createSearchParams } from 'react-router-dom'

export const generateCustomerAnalysisPath = (dimension: ProductDimension, name: string, startDate: string, endDate: string) => {
  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: [
          {
            dimension: dimension === ProductDimension.product_type ? 'product_type' : 'product',
            operator: OP_CONTAIN_ANY,
            value: [name],
            filters:
              startDate === '' ? [] : [{ filter_type: 'order_date', operator: OP_DATE_RANGE, value: { min_date: startDate, max_date: endDate } }],
          },
        ],
      },
    ],
  }

  const queryString = createSearchParams({
    querySet: encodeURIComponent(JSON.stringify(convertFormToQuerySet(querySet))),
  }).toString()

  return `${AUTHORIZED_ROUTE.CUSTOMER_ANALYSIS}?${queryString}`
}
